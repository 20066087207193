import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import ServerURL from '../../../ServerURL';

import OpenExchangeURL from '../../../OpenExchangeURL';
import OpenExchangeAPI from '../../../OpenExchangeAPI';

import * as auth from '../../modulesAdmin/Auth/_redux/authRedux'

// Primereact components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber'
import { MultiSelect } from 'primereact/multiselect'
import { FileUpload } from 'primereact/fileupload';
import { TabView, TabPanel } from 'primereact/tabview';


// Utileria
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { classNames } from 'primereact/utils';
import './index.css'

const url = ServerURL();
const urlOE = OpenExchangeURL();
const apiOE = OpenExchangeAPI();

let emptyExpense = {
    status: 0,
    payment_date: '',
    payment_date_conv: '',
    payment_date_show: '',
    fk_id_provider: null,
    fk_id_category: null,
    fk_id_subcategory: null,
    paid_by: null,
    fk_id_user: null,
    fk_id_company: null,
    fk_id_office: null,
    amount: null,
    rate: null,
    fa_amount: null,
    fa_amount_mxn: null,
    fa_currency: null,
    receipt_url: '',
    factura: null,
    factura_url: '',
    memo: null,
    approval_date: '',
    approval_date_conv: '',
    approval_date_show: '',
    approval_by: null,
    nota: '',
    alias_tarjeta: null,
    api: '',
    created_by: null,
    updated_by: null,
    gasto_NoReembolsado: null,
    gasto_corporativo: null,
    grupos: '',
}

let emptyProvider = {
    id: null,
    legal_name: ''
}

let emptyCategory = {
    id: null,
    description: ''
}

let emptySubCategory = {
    id: null,
    descriptionSub: ''
}

let emptyCompany = {
    id: null,
    commercial_name: ''
}

let emptyOffice = {
    id: null,
    office_name: ''
}

const ExpensesDataTable = () => {
    const status = [
        'Pagada',
        'Por aprobar',
        'Pendiente'
    ]

    const paid_by_options = [
        { id: 1, by: 'Corporativo' },
        { id: 2, by: 'Oficina' },
        { id: 3, by: 'Empleado' }
    ]

    const columns = [
        // {field: 'approval_date', header: 'Approval date'},
        { field: 'fk_id_provider', header: 'Provider' },
        { field: 'description', header: 'Category' },
        { field: 'descriptionSub', header: 'Subcategory' },
        { field: 'paid_by', header: 'Paid by' },
        { field: 'company_commercial_name', header: 'Company' },
        { field: 'office_name', header: 'Office' },
        { field: 'receipt', header: 'Receipt' },
        { field: 'factura_url', header: 'Factura' },
        { field: 'nota', header: 'nota' },
        { field: 'create_by', header: 'Created by' },
        { field: 'approval_by', header: 'Approval by' },
        { field: 'updated_by', header: 'Updated by' },

    ]

    const [expense, setExpense] = useState(emptyExpense);
    const [expenses, setExpenses] = useState(null);

    const [provider, setProvider] = useState(emptyProvider)
    const [providers, setProviders] = useState(null)

    const [categories, setCategories] = useState(null)
    const [category, setCategory] = useState(emptyCategory)

    const [subCategories, setSubCategories] = useState(null)
    const [subCategory, setSubCategory] = useState(null)

    const [company, setCompany] = useState(emptyCompany)
    const [companies, setCompanies] = useState(null)

    const [office, setOffice] = useState(emptyOffice)
    const [offices, setOffices] = useState(null)

    const [totalExpenses, setTotalExpenses] = useState(null)

    const [selectedStatus, setSelectedStatus] = useState(null)
    const [selectedColumns, setSelectedColumns] = useState(columns)

    const [activeIndex, setActiveIndex] = useState(0)

    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState()

    const [paidBy, setPaidBy] = useState(null);
    const [filters, setFilters] = useState({})
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [loading, setLoading] = useState(true)
    const [submitted, setSubmitted] = useState(false)


    const toast = useRef()
    const fileFacturaRef = useRef(null)
    const fileReceiptRef = useRef(null)


    // Dialogs
    const [expenseDialog, setExpenseDialog] = useState(false)
    const [deleteExpenseDialog, setDeleteExpenseDialog] = useState(false)

    // Peticiones a la API
    // Expenses
    const getExpenses = async () => {
        try {
            const connection = await axios.get(`${url}/api/auth/expenses/expenses`);
            setExpenses(connection.data)
            totalExpense(connection.data)
            // console.log('CONNECTION: ', connection.data)
        } catch (error) {
            console.log('ERROR: ', error);

        }
    }

    // Providers
    const getProviders = async () => {
        try {
            const providers = await axios.get(`${url}/api/auth/expenses/providers`)
            setProviders(providers.data)
            console.log('PROVIDERS: ', providers.data);

        } catch (error) {
            console.log("ERROR: ", error);
        }
    }

    // Companies
    const getCompanies = async () => {
        try {
            const res = await axios.get(`${url}/api/auth/expenses/companies`)
            setCompanies(res.data)
        } catch (error) {

        }
    }

    // Offices
    const getOffices = async () => {
        try {
            const offices = await axios.get(`${url}/api/auth/expenses/offices`)
            setOffices(offices.data)
            console.log('Offices: ', offices);

        } catch (error) {
            console.log("ERROR: ", error);
        }
    }

    // Categories
    const getCategories = async () => {
        try {
            const res = await axios.get(`${url}/api/auth/expenses/categories`)
            setCategories(res.data)
            console.log('CATEGORIES: ', categories);
        } catch (error) {
            console.log('ERROR: ', error);
        }
    }

    // Sub categories
    const getSubCategories = async () => {
        try {
            await axios.get(`${url}/api/auth/expenses/sub_categories`)
                .then(res => {
                    setSubCategories(res.data)
                });
        } catch (error) {
            console.log('ERROR: ', error);
        }
    }

    // Office by Provider
    const getOfficeByProvider = async (e) => {
        try {
            await axios.get(`${url}/api/auth/expenses/office_by_id/${e}`)
                .then(res => {
                    setOffices(res.data)
                })
        } catch (error) {

        }
    }

    // Category by Provider
    const getCategoryByProvider = async (e) => {
        console.log('E 221: ', e);

        try {
            await axios.get(`${url}/api/auth/expenses/category_by_id/${e}`)
                .then(res => {
                    const _categorie = res.data
                    _categorie.value = _categorie[0]
                    console.log('CATEGORIE: ', _categorie);

                    onCategoryChange(_categorie)
                })
        } catch (error) {

        }
    }

    // Subcategory by Provider
    const getSubCategoryByProvider = async (e) => {
        try {
            await axios.get(`${url}/api/auth/expenses/sub_category_by_provider/${e}`)
                .then(res => {
                    const _sub_categorie = res.data
                    _sub_categorie.value = _sub_categorie[0]
                    console.log('SUBCATEGORY: ', _sub_categorie);


                    onSubCategoryChange(_sub_categorie)

                })
        } catch (error) {

        }
    }

    const getSubCategoryByCategory = async (e) => {
        try {
            await axios.get(`${url}/api/auth/expenses/sub_category_by_id/${e}`)
                .then(res => {
                    // let _subCategories = res.data
                    setSubCategories(res.data)
                    console.log('E 124: ', res.data);

                })
        } catch (error) {

        }
    }

    useEffect(async () => {
        getExpenses();
        getProviders();
        getCompanies();
        getOffices();
        getCategories();
        getSubCategories()
    }, [])

    // Save expense
    const saveExpense = async (event) => {
        setSubmitted(true)

        let _expense = { ...expense }
        console.log("SAVE EXPENSE: ", _expense);
        const formData = new FormData();

        const fileFactura = fileFacturaRef.current.getFiles()
        const fileReceipt = fileReceiptRef.current.getFiles()

        console.log('filefacturaref: ', fileFactura);
        console.log('fileReceiptoRef: ', fileReceipt);


        if (fileFactura && fileFactura.length > 0) {
            formData.append('factura', fileFactura[0]);
            console.log("FILE: ", fileFactura[0]);
            // Aquí podrías realizar la petición a la API para enviar formData...
        } else {
            console.error('No se encontraron archivos para transferir');
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Hubo un error al subir el archivo.',
            });
            return;
        }

        if (fileReceipt && fileReceipt.length > 0) {
            formData.append('factura', fileReceipt[0]);
            console.log("FILE: ", fileReceipt[0]);
            // Aquí podrías realizar la petición a la API para enviar formData...
        } else {
            console.error('No se encontraron archivos para transferir');
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Hubo un error al subir el archivo.',
            });
            return;
        }




        formData.append('payment_date', _expense.payment_date)
        formData.append('payment_date_conv', _expense.payment_date_conv)
        formData.append('approval_date', _expense.approval_date)
        formData.append('approval_date_conv', _expense.approval_date_conv)
        formData.append('fk_id_provider', _expense.fk_id_provider)
        formData.append('fk_id_category', _expense.fk_id_category)
        formData.append('fk_id_subcategory', _expense.fk_id_subcategory)
        formData.append('paid_by', _expense.paid_by)
        formData.append('status', _expense.status)
        formData.append('memo', _expense.memo)
        formData.append('nota', _expense.nota)
        formData.append('fk_id_company', _expense.fk_id_company)
        formData.append('fk_id_office', _expense.fk_id_office)
        formData.append('amount', _expense.amount)
        formData.append('rate', _expense.rate)
        formData.append('fa_amount', _expense.fa_amount)
        formData.append('fa_amount_mxn', _expense.fa_amount_mxn)
        // formData.append('receipt_url', _expense.receipt_url)
        // formData.append('factura_url', _expense.factura_url)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        console.log('FORM DATA: ', formData);

        // if (_expense.id) {
        //     console.log('EDITAR REGISTRO: ');

        //     await axios.put(`${url}/api/auth/expenses/expense/${_expense.id}`, formData, config)
        //         .then(res => {
        //             console.log('RES: ', res.data);

        //             setExpense(emptyExpense)
        //             setProvider(emptyProvider)
        //             setCategory(emptyCategory)
        //             setSubCategory(emptySubCategory)
        //             setSubmitted(false)
        //             setExpenseDialog(false)

        //             getExpenses()
        //             getProviders()
        //             getCategories()
        //             getSubCategories()
        //         })
        // } else {
        console.log('CREANDO REGISTRO');

        await axios.post(`${url}/api/auth/expenses/expense`, formData, config)
            .then(res => {
                console.log('RES: ', res.data);

                // setExpense(emptyExpense)
                // setProvider(emptyProvider)
                // setCategory(emptyCategory)
                // setSubCategory(emptySubCategory)
                // setSubmitted(false)
                // setExpenseDialog(false)

                // getExpenses()
                // getProviders()
                // getCategories()
                // getSubCategories()
            })
            .catch((error) => {
                console.log(error);

            });
        // }

        console.log('SAVE EXPENSE: ', _expense);

    }

    // Delete expense
    const deleteExpense = async () => {
        let _expense = { ...expense }

        try {
            await axios.delete(`${url}/api/auth/expenses/expense/${_expense.id}`)
                .then(res => {
                    console.log('RES: ', res.data);
                    setExpense(emptyExpense)
                    setProvider(emptyProvider)
                    setCategory(emptyCategory)
                    setSubCategory(emptySubCategory)
                    setDeleteExpenseDialog(false)

                    getExpenses()
                    getProviders()
                    getCategories()
                    getSubCategories()
                })
        } catch (error) {

        }
    }

    // Funciones de pagina dinamica
    const openNew = () => {
        setExpense(emptyExpense)
        setSubmitted(false)
        setExpenseDialog(true)
    }

    const editExpense = (editExpense) => {
        console.log('Edit expense: ', editExpense);

        let lengthProviders = Object.keys(providers).length
        let lengthCompanies = Object.keys(companies).length
        let lengthOffices = Object.keys(offices).length
        let lengthCategories = Object.keys(categories).length
        let lengthSubCategories = Object.keys(subCategories).length

        for (let i = 0; i < lengthProviders; i++) {
            if (editExpense.fk_id_provider === providers[i].commercial_name) {
                let _providers = providers[i]
                console.log("PROVIDERS: ", providers[i]);

                setProvider(_providers)
                onEditProviderChange(_providers)
            }
        }

        for (let i = 0; i < lengthCategories; i++) {
            if (editExpense.fk_id_category === categories[i].id) {
                let _categories = categories[i]
                setProvider(_categories)
                onEditCategorieChange(_categories)
            }
        }

        for (let i = 0; i < lengthSubCategories; i++) {
            if (editExpense.fk_id_subcategory === subCategories[i].id) {
                let _subCategories = subCategories[i]
                setProvider(_subCategories)
                onEditSubCategorieChange(_subCategories)
            }
        }

        for (let i = 0; i < lengthCompanies; i++) {
            if (editExpense.fk_id_company === companies[i].id) {
                let _companies = companies[i]
                setProvider(_companies)
                onEditCompanyChange(_companies)
            }
        }

        for (let i = 0; i < lengthOffices; i++) {
            if (editExpense.fk_id_office === offices[i].id) {
                let _offices = offices[i]
                setProvider(_offices)
                onEditOfficeChange(_offices)
            }
        }




        setExpense(editExpense);
        setExpenseDialog(true);
        onEditDateChange(editExpense.payment_date, editExpense.approval_date, editExpense)
        // onEditApprovalChange(editExpense.approval_date)

    }

    const confirmDeleteExpense = (deleteExpense) => {
        console.log('EXPENSE: ', deleteExpense);

        setExpense(deleteExpense)
        setDeleteExpenseDialog(true)
    }

    const hideDialog = () => {
        setExpense(emptyExpense)
        setCompany(emptyCompany)
        setOffice(emptyOffice)
        setProvider(emptyProvider)
        setCategory(emptyCategory)
        setSubCategory(emptySubCategory)
        setSubmitted(false)
        setExpenseDialog(false)
    }

    const hideDeleteDialog = () => {
        setSubmitted(false)
        setExpense(emptyExpense)
        setProvider(emptyProvider)
        setCategory(emptyCategory)
        setSubCategory(emptySubCategory)
        setDeleteExpenseDialog(false)
    }

    const onStatusChange = (e) => {
        let _expense = { ...expense }
        console.log('E: ', e.value);

        switch (e.value) {
            case 'Pagada':
                e.value = 2
                break;
            case 'Por aprobar':
                e.value = 1
                break;
            default:
                e.value = 0
                break;
        }
        _expense['status'] = e.value
        console.log('_EXPENSE: ', _expense);

        setExpense(_expense)
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _expense = { ...expense }
        _expense[`${name}`] = val;

        setExpense(_expense)
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _expense = { ...expense }
        _expense[`${name}`] = val;

        setExpense(_expense)
    }

    const onRateChange = (e, name) => {
        const val = e.value || 0
        let _expense = { ...expense }
        _expense[`${name}`] = val

        _expense.fa_amount = _expense.amount * _expense.rate

        setExpense(_expense)
    }

    const onDateChange = (e) => {
        console.log('E: ', e);

        const val = e.value || ''
        let _expense = { ...expense }
        _expense['payment_date_show'] = val
        onApprovalDateChange(e)
        setExpense(_expense)
        paymentDate(_expense['payment_date_show'])
    }

    const paymentDate = (e) => {
        console.log('E 495: ', e);
        let _expense = { ...expense }

        if (!e) {

        } else {
            let year = e.getFullYear()
            let month = e.getMonth() + 1
            let day = e.getDate()

            if (day < 10) {
                day = '0' + day
            }
            if (month < 10) {
                month = '0' + month
            }

            let new_date = `${year}/${month}/${day}`
            let new_date_conv = `${day}/${month}/${year}`

            _expense.payment_date = new_date
            _expense.payment_date_conv = new_date_conv
            _expense.payment_date_show2 = new_date_conv
            console.log("_EXPENSE: ", _expense);

            setExpense(_expense)
        }
    }

    const onEditDateChange = (paymentDate, approvalDate, editExpense) => {
        let payment_date = new Date(paymentDate)
        payment_date.setMinutes(payment_date.getMinutes() + payment_date.getTimezoneOffset())

        let approval_date = new Date(approvalDate)
        approval_date.setMinutes(approval_date.getMinutes() + approval_date.getTimezoneOffset())


        if (approvalDate === null) {
            console.log('CONDICION');

            approval_date = payment_date
            approval_date.setMinutes(approval_date.getMinutes() + approval_date.getTimezoneOffset())

            console.log("APPROVAL: ", approval_date);

        }

        // console.log('E: ', date);
        // let editExpense = { ...expense }
        editExpense.id = editExpense.id
        console.log('_EXPENSE: ', editExpense);


        const val = payment_date || ''
        const val2 = approval_date || ''

        editExpense['payment_date_show'] = val
        editExpense['approval_date_show'] = val2

        let year = payment_date.getFullYear()
        let month = payment_date.getMonth() + 1
        let day = payment_date.getDate()

        let year2 = approval_date.getFullYear()
        let month2 = approval_date.getMonth() + 1
        let day2 = approval_date.getDate()

        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }

        if (day2 < 10) {
            day2 = '0' + day2
        }
        if (month2 < 10) {
            month2 = '0' + month2
        }

        let new_paymentDate = `${year}/${month}/${day}`
        let new_paymentDate_conv = `${day}/${month}/${year}`

        let new_approvalDate = `${year2}/${month2}/${day2}`
        let new_approvalDate_conv = `${day2}/${month2}/${year2}`

        editExpense.payment_date = new_paymentDate
        editExpense.payment_date_conv = new_paymentDate_conv

        editExpense.approval_date = new_approvalDate
        editExpense.approval_date_conv = new_approvalDate_conv

        setExpense(editExpense)
    }


    const onApprovalDateChange = (e) => {
        let _expense = { ...expense }

        const val = e.value || _expense.payment_date_show
        _expense['approval_date_show'] = val

        setExpense(_expense)
        approvalDate(_expense['approval_date_show'])

    }

    const approvalDate = (e) => {
        let _expense = { ...expense }
        console.log('E: ', e);


        let year = e.getFullYear();
        let month = e.getMonth() + 1;
        let day = e.getDate()

        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }

        let new_date = `${year}/${month}/${day}`
        let new_date_conv = `${day}/${month}/${year}`

        _expense.approval_date = new_date
        _expense.approval_date_conv = new_date_conv

        setExpense(_expense)
    }

    const onEditApprovalChange = (e) => {
        let date = new Date(e)

        const val = date || ''
        let _expense = { ...expense }
        _expense['approval_date_show'] = val

        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()

        if (day < 10) {
            day = '0' + day
        }
        if (month < 10) {
            month = '0' + month
        }

        let new_date = `${year}/${month}/${day}`
        let new_date_conv = `${day}/${month}/${year}`

        _expense.approval_date = new_date
        _expense.approval_date_conv = new_date_conv

        setExpense(_expense)
    }

    const onProviderCahnge = (e) => {
        console.log('E: ', e.value);
        expense.fk_id_provider = e.value.id

        setProvider(e.value)
        getCategoryByProvider(e.value.fk_id_category)
        getSubCategoryByProvider(e.value.fk_id_subcategory)
    }

    const onCategoryChange = (e) => {
        console.log('E: ', e.value);
        expense.fk_id_category = e.value.id

        setCategory(e.value)
        getSubCategoryByCategory(e.value.id)
    }

    const onSubCategoryChange = (e) => {
        console.log('E: ', e.value)
        expense.fk_id_subcategory = e.value.id

        setSubCategory(e.value)
    }

    const onPaidByChange = (e) => {
        console.log('E 527: ', e.value.by);

        let _expense = { ...expense }
        expense.paid_by = e.value.by

        switch (e.value.id) {
            case 1:
                _expense.value = 'Por aprobar'
                onStatusChange(_expense)
                break;
            case 2:
                _expense.value = 'Pagada'
                onStatusChange(_expense)
                break;
            default:
                _expense.value = 'Pendiente'
                onStatusChange(_expense)
                break;
        }

        setPaidBy(e.value)

    }

    const onCompanyChange = (e) => {
        console.log('E: ', e.value);
        expense.fk_id_company = e.value.id

        setCompany(e.value)
        getOfficeByProvider(e.value.id)
    }

    const onOfficeChange = (e) => {
        console.log('E: ', e.value);
        expense.fk_id_office = e.value.id

        setOffice(e.value)
    }

    const onEditProviderChange = (e) => {
        setProvider(e)
        // getSubCategoryBy
    }

    const onEditCategorieChange = (e) => {
        console.log('E: ', e);

        setCategory(e)
        // getSubCategoryBy
    }

    const onEditSubCategorieChange = (e) => {
        setSubCategory(e)
        // getSubCategoryBy
    }

    const onEditCompanyChange = (e) => {
        setCompany(e)
        // getSubCategoryBy
    }

    const onEditOfficeChange = (e) => {
        setOffice(e)
        // getSubCategoryBy
    }

    const onColumnToggle = (event) => {
        let selectedColumns = event.value
        let orderedSelectedColumns = columns.filter(
            col => selectedColumns.some(sCol => sCol.field === col.field)
        )
        setSelectedColumns(orderedSelectedColumns)
    }

    const onBasicUpload = () => {
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'Archivo subido correctamente' });
    }

    const onIndexChange = (e) => {
        console.log('E: ', e);
        
        if (e.index === 0) {
            setActiveIndex(0)
            getExpenses()
        } else if ( e.index === 1 ){
            setActiveIndex(1)
            getExpenses()
        } else if ( e.index === 2 ) {
            setActiveIndex(2)
            getExpenses()
        }

    }

    // Total expense
    const totalExpense = (value) => {
        let totalExpense = 0
        let totalExpenseFormat

        for (let i in value) {
            totalExpense += value[i].amount

            if (parseInt(i) === value.length - 1) {
                totalExpenseFormat = totalExpense.toLocaleString(
                    'en-US',
                    { style: 'currency', currency: 'USD' }
                )
                console.log('TOTAL EXPENSES: ', totalExpense);

                setTotalExpenses(totalExpenseFormat)
            }
        }
    }

    const formatStatus = (value) => {
        switch (value) {
            case 1:
                value = 'por-aprobar'
                return <span className={`expense-badge status-${value}`}>Por aprobar</span>
                break;
            case 'Por Aprobar':
                value = 'por-aprobar'
                return <span className={`expense-badge status-${value}`}>Por aprobar</span>
                break;
            case 2:
                value = 'pagada'
                return <span className={`expense-badge status-${value}`}>Pagada</span>
                break;
            case 'Pagada':
                value = 'pagada'
                return <span className={`expense-badge status-${value}`}>Pagada</span>
                break;
            default:
                value = 'pendiente'
                return <span className={`expense-badge status-${value}`}>Pendiente</span>
                break;
        }
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    // const formatCurrency = (value) => {
    //     return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
    // }




    // Templates
    const header = (
        <div>
            <MultiSelect value={selectedColumns} options={columns} optionLabel='header' onChange={onColumnToggle}
                style={{ width: '20em' }} display='chip' />
        </div>
    )

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="New expense" icon="pi pi-plus" className='p-button-outlined mr-2' onClick={openNew} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon='pi pi-pencil' className='p-button-rounded p-button-outlined mr-2' onClick={() => editExpense(rowData)} />
                <Button icon='pi pi-trash' className='p-button-outlined p-button-rounded p-button-danger' onClick={() => confirmDeleteExpense(rowData)} />
            </React.Fragment>
        )
    }

    const statusBodyTempalte = (rowData) => {
        // return formatStatus(rowData.status)
        return formatStatus(rowData.status)

    }

    const paymentDateTemplate = (rowData) => {
        // console.log('ROW DATA: ', rowData);
        const date = new Date(rowData.payment_date)
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
        // console.log("ROW DATA PAYMENT DATE: ", date);

        return date.toLocaleDateString('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    }

    const approvalDateTemplate = (rowData) => {

        if (!rowData.approval_date) {
            // console.log('ROW DATA: ', rowData.payment_date);

            const date = new Date(rowData.payment_date)
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())

            return date.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });

        } else {
            const date = new Date(rowData.approval_date)
            // console.log("DATE: ", date);

            date.setMinutes(date.getMinutes() + date.getTimezoneOffset())

            return date.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        }
    }

    const statusRowFilterTemplate = (options) => {
        return <Dropdown value={status.value} options={status} onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder='Selected a status' className='p-column-filter' showClear />
    }


    // const amountBodyTemplate = (rowData) => {
    //     return formatCurrency(rowData.amount)
    // }

    // const rateBodyTemplate = (rowData) => {
    //     return formatCurrency(rowData.rate)
    // }

    // const faAmuntBodyTemplate = (rowData) => {
    //     return formatCurrency(rowData.fa_amount)
    // }

    // const faAmountMxnBodyTemplate = (rowData) => {
    //     return formatCurrency(rowData.fa_amount_mxn)
    // }

    // const faCurrencyBodyTemplate = (rowData) => {
    //     return formatCurrency(rowData.fa_currency)
    // }

    // Filtros
    const columnComponents = selectedColumns.map(col => {
        return <Column key={col.field} field={col.field} header={col.header} />
    })

    //Footers
    const expensesDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label='Cancel' icon='pi pi-tunes' className='p-button-text' onClick={hideDialog} />
                <Button label='Save' icon='pi pi-check' className='p-button-text' onClick={saveExpense} />
            </React.Fragment>
        )
    }

    const deleteExpenseDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDeleteDialog} />
                <Button label='Confirm' icon='pi pi-check' className='p-button-text' onClick={deleteExpense} />
            </React.Fragment>
        )
    }

        // Tablas
        const tablePendingExpenses = (
            // let showPendingExpenses = []
            // showPendingExpenses.push(
            <>
                <Toolbar className='mb-4' left={leftToolbarTemplate} />
    
                <DataTable
                    value={expenses}
                    paginator
                    className="p-datatable-customers"
                    header={header}
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    rowHover
                    // selection={selectedCustomers} 
                    // onSelectionChange={e => setSelectedCustomers(e.value)}
                    // filters={filters} 
                    // filterDisplay="menu" 
                    // loading={loading} 
                    responsiveLayout="scroll"
                    // globalFilterFields={['name', 'country.name', 'representative.name', 'balance', 'status']} 
                    emptyMessage="No customers found."
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                    <Column selectionMode="multiple" selectionAriaLabel="name" headerStyle={{ width: '3em' }}></Column>
                    <Column field="id" header="Id" sortable style={{ minWidth: '3rem' }} />
                    <Column field="memo" header="Memo" sortable style={{ minWidth: '10rem' }} />
                    <Column field="status" header="Status" sortable style={{ minWidth: '14rem' }}
                        body={statusBodyTempalte} filter filterElement={statusRowFilterTemplate} />
                    <Column field='payment_date' header="Payment date" sortable style={{ minWidth: '14rem' }} body={paymentDateTemplate} />
                    <Column field="approval_date" header="Approval date" sortable style={{ minWidth: '10rem' }} body={approvalDateTemplate} />
                    {columnComponents}
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} />
                </DataTable>
            </>
        )

    return (
        <div className='datatable-doc-demo'>
            <div className="flex justify-content-center">
                <div>
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-black-alpha-90 text-5xl font-semibold text-center font-medium mb-3">Total expenses</span>
                            <div><h1 className="text-green-400 font-medium text-center text-8xl">{totalExpenses}</h1> </div>
                        </div>
                    </div>
                </div>
            </div>
            <TabView activeIndex={activeIndex} onTabChange={onIndexChange} >
                <TabPanel header='Pendientes'>
                    {tablePendingExpenses}
                </TabPanel>

                <TabPanel header='Por aprobar'>

                </TabPanel>

                <TabPanel header='Pagados'>

                </TabPanel>
            </TabView>

            {/* <div className='card'>
                <Toolbar className='mb-4' left={leftToolbarTemplate} />
                <DataTable
                    value={expenses}
                    paginator
                    className="p-datatable-customers"
                    header={header}
                    rows={10}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                    dataKey="id"
                    rowHover
                    // selection={selectedCustomers} 
                    // onSelectionChange={e => setSelectedCustomers(e.value)}
                    // filters={filters} 
                    // filterDisplay="menu" 
                    // loading={loading} 
                    responsiveLayout="scroll"
                    // globalFilterFields={['name', 'country.name', 'representative.name', 'balance', 'status']} 
                    emptyMessage="No customers found."
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                    <Column selectionMode="multiple" selectionAriaLabel="name" headerStyle={{ width: '3em' }}></Column>
                    <Column field="id" header="Id" sortable style={{ minWidth: '3rem' }} />
                    <Column field="memo" header="Memo" sortable style={{ minWidth: '10rem' }} />
                    <Column field="status" header="Status" sortable style={{ minWidth: '14rem' }}
                        body={statusBodyTempalte} filter filterElement={statusRowFilterTemplate} />
                    <Column field='payment_date' header="Payment date" sortable style={{ minWidth: '14rem' }} body={paymentDateTemplate} />
                    <Column field="approval_date" header="Approval date" sortable style={{ minWidth: '10rem' }} body={approvalDateTemplate} />
                    {columnComponents}
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} />
                </DataTable>
            </div> */}

            <Dialog visible={expenseDialog} style={{ width: '750px' }} header='Expenses details'
                modal className='p-fluid' onHide={hideDialog} footer={expensesDialogFooter} >
                {/* RECEIPT */}
                <div className='formgrid grid' style={{ marginTop: '15px' }}>
                    <div className='fiel col-6 md:col-4'>
                        <FileUpload
                            ref={fileFacturaRef}
                            mode='basic'
                            name='factura'
                            id='factura'
                            // customUpload
                            auto={false}
                            // onUpload={saveExpense}
                            chooseOptions={{
                                icon: 'pi pi-fw pi-file',
                                className: 'p-button-help p-button-outlined p-button-rounded'
                            }}
                            maxFileSize={5000000}
                            chooseLabel='Factura'
                        />
                    </div>

                    <div className='fiel col-6 md:col-4'>
                        <FileUpload
                            ref={fileReceiptRef}
                            mode='basic'
                            name='receipt'
                            id='receipt'
                            // customUpload
                            auto={false}
                            // onUpload={saveExpense}
                            chooseOptions={{
                                icon: 'pi pi-fw pi-file',
                                className: 'p-button-help p-button-outlined p-button-rounded'
                            }}
                            maxFileSize={5000000}
                            chooseLabel='Receipt'
                        />
                    </div>
                </div>

                {/* PAYMENT DATE */}
                <div className='formgrid grid' style={{ marginTop: '15px' }}>
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <Calendar dateFormat="dd/mm/yy" id='payment_date' value={expense.payment_date_show} onChange={onDateChange} />
                            <label htmlFor='payment_date'>Payment date</label>
                        </span>
                    </div>

                    {/* APPROVAL DATE */}
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <Calendar dateFormat="dd/mm/yy" id='approval_date' value={expense.approval_date_show} onChange={onApprovalDateChange} />
                            <label htmlFor='approval_date'>Approval date</label>
                        </span>
                    </div>
                </div>

                {/* PROVIDER */}
                <div className='formgrid grid mt-5'>
                    {/* Provider */}
                    <div className='field col-4 md:col-3 '>
                        <span className='p-float-label'>
                            <Dropdown value={provider} options={providers} optionLabel='commercial_name' onChange={onProviderCahnge} />
                            <label htmlFor='fk_id_provider'>Provider</label>
                        </span>
                    </div>

                    {/* Category */}
                    <div className='field col-4 md:col-3'>
                        <span className='p-float-label'>
                            <Dropdown value={category} options={categories} onChange={onCategoryChange} optionLabel='description' />
                            <label htmlFor='fk_id_category'>Category</label>
                        </span>
                    </div>

                    {/* Subcategory */}
                    <div className='field col-4 md:col-3'>
                        <span className='p-float-label'>
                            <Dropdown value={subCategory} options={subCategories}
                                onChange={onSubCategoryChange} optionLabel='descriptionSub' />
                            <label htmlFor='fk_id_subcategory'>Sub category</label>
                        </span>
                    </div>
                </div>

                {/* CATEGORY & SUB CATEGORY */}
                {/* <div className='formgrid grid'>
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <Dropdown value={category} options={categories} onChange={onCategoryChange} optionLabel='description' />
                            <label htmlFor='fk_id_category'>Category</label>
                        </span>
                    </div>


                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <Dropdown value={subCategory} options={subCategories}
                                onChange={onSubCategoryChange} optionLabel='descriptionSub' />
                            <label htmlFor='fk_id_subcategory'>Sub category</label>
                        </span>
                    </div>
                </div> */}

                {/* PAID BY */}
                <div className='formgrid grid'>
                    <div className='field col-12 md:col-6'>
                        <span className='p-float-label'>
                            <Dropdown value={paidBy} options={paid_by_options}
                                onChange={onPaidByChange} optionLabel='by' />
                            <label htmlFor='paid_by'>Paid by</label>
                        </span>
                    </div>
                </div>

                {/* STATUS */}
                <div className='formgrid grid'>
                    <div className="field col-12 md:col-6">
                        <label className="mb-3">Status</label>
                        <div className="formgrid grid">
                            <div className="field-radiobutton col-4 md:col-2">
                                <RadioButton inputId="reimbursed1" name="status" value="Pendiente" onChange={onStatusChange} checked={expense.status === 0} />
                                <label htmlFor="reimbursed1">Pendiente</label>
                            </div>
                            <div className="field-radiobutton col-4 md:col-2">
                                <RadioButton inputId="reimbursed2" name="status" value="Por aprobar" onChange={onStatusChange} checked={expense.status === 1} />
                                <label htmlFor="reimbursed2">Por aprobar</label>
                            </div>
                            <div className='field-radiobutton col-4 md:col-2'>
                                <RadioButton inputId='reimbursed3' name='status' value='Pagada' onChange={onStatusChange} checked={expense.status === 2} />
                                <label htmlFor='reimbused3'>Pagado</label>
                            </div>

                        </div>
                    </div>
                </div>

                {/* PAID BY & COMPANY & OFFICE */}
                <div className='formgrid grid'>
                    {/* <div className='field col-4 md:col-2'>
                        <span className='p-float-label'>
                            <Dropdown options={paid_by_options} optionLabel='by' />
                            <label htmlFor='fk_id_user'>Paid by</label>
                        </span>
                    </div> */}

                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <Dropdown value={company} options={companies}
                                onChange={onCompanyChange} optionLabel='company_commercial_name' />
                            <label htmlFor='fk_id_company'>Company</label>
                        </span>
                    </div>

                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <Dropdown value={office} options={offices}
                                onChange={onOfficeChange} optionLabel='office_name' />
                            <label htmlFor='fk_id_office'>Office</label>
                        </span>
                    </div>
                </div>

                {/* ALL AMOUNTS */}
                <div className="formgrid grid">
                    <div className='field col-4 md:col-2'>
                        <span className='p-float-label'>
                            <InputNumber id="amount" value={expense.amount} onValueChange={(e) => onInputNumberChange(e, 'amount')} mode="currency" currency="USD" locale="en-US" />
                            <label htmlFor="amount">Amount</label>

                        </span>
                    </div>

                    <div className='field col-4 md:col-2'>
                        <span className='p-float-label'>
                            <InputNumber id="rate" value={expense.rate} onValueChange={(e) => onRateChange(e, 'rate')} mode="currency" currency="USD" locale="en-US" />
                            <label htmlFor="amount">Rate</label>

                        </span>
                    </div>

                    <div className='field col-4 md:col-2'>
                        <span className='p-float-label'>
                            <InputNumber id="fa_amount" value={expense.fa_amount} onValueChange={(e) => onInputNumberChange(e, 'fa_amount')} mode='currency' currency='USD' locale='en-US' />
                            <label htmlFor="fa_amount">FA Amount</label>
                        </span>
                    </div>

                    {/* <div className='field col-3 md:col-1'>
                        <span className='p-float-label'>
                            <InputNumber id="fa_amount_mxn" value={expense.fa_amount_mxn} onValueChange={(e) => onInputNumberChange(e, 'fa_amount_mxn')} mode='currency' currency='USD' locale='en-US' />
                            <label htmlFor="fa_amount_mxn">FA Amount MXN</label>
                        </span>
                    </div> */}
                </div>

                {/* MEMO AND NOTA */}
                <div className='formgrid grid' style={{ marginBottom: '10px' }}>
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <InputText id="memo" value={expense.memo} onChange={(e) => onInputChange(e, 'memo')} required autoFocus className={classNames({ 'p-invalid': submitted && !expense.memo })} />
                            {submitted && !expense.memo && <small className="p-error">Memo is required.</small>}
                            <label htmlFor="memo">Memo</label>
                        </span>
                    </div>

                    <div className="field col-6 md:col-4">
                        <span className='p-float-label'>
                            <InputText id="nota" value={expense.nota} onChange={(e) => onInputChange(e, 'nota')} required autofocus className={classNames({ 'p-invalid': submitted && !expense.nota })} />
                            {submitted && !expense.nota && <small className='p-error'>Nota is required</small>}
                            <label htmlFor='nota'>Nota</label>
                        </span>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={deleteExpenseDialog} style={{ width: '450px' }} header='Confirm' modal footer={deleteExpenseDialogFooter}
                onHide={hideDeleteDialog} >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {provider && <span>Are you sure you want to delete the Expense with id: <b>{expense.id}</b>?</span>}
                </div>
            </Dialog>
        </div>
    )
}

export default ExpensesDataTable