import React from 'react'
import { useSubheader } from '../../_metronic/_core'
import ProvidersTable from '../moduleExpenses/Providers/ProvidersTable'

export const ProvidersPage = () => {
    const subheader = useSubheader();
    subheader.setTitle('Providers')

    return (
        <>
            <ProvidersTable />
        </>
    )
}