// ExchangeTable - Accounting
import React, { Component } from 'react'
//import classNames from 'classnames';
//import { withRouter } from "react-router";
import axios from 'axios'
import ServerURL from '../../../ServerURL'
import OpenExchangeURL from '../../../OpenExchangeURL'
import OpenExchangeAPI from '../../../OpenExchangeAPI'

import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { connect } from 'react-redux';
import { InputNumber } from 'primereact/inputnumber';

import 'primeflex/primeflex.css';


import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";

const url = ServerURL();
const urlOE = OpenExchangeURL();
const apiOE = OpenExchangeAPI();


class ExchangesTable extends Component {

  emptyExchange = {
    fk_id_currency: null,
    rate: null,
  }

  emptyCurrency = {
    code: null,
    symbol: null,
  }

  emptyEnterprise = {
    fk_id_currencies: '',
    additionalPercentage: '',
  }

  constructor(props) {
    super(props)


    this.state = {

      originalFk_id_baseCurrency: this.props.user.fk_id_baseCurrency,
      originalCodeCurrency: this.props.user.codeCurrency,
      originalFK_id_currencies: this.props.user.fk_id_currencies,


      exchange: this.emptyExchange,
      currency: this.emptyCurrency,
      enterprise: this.emptyEnterprise,

      exchanges: [],
      currencies: [],
      currenciesShow: [],
      enterprises: [],

      submitted: false,
      currencyDialog: false,
      resetDateExchange: null,

      selectedBaseCurrency: null,
      globalFilter: null,
      selectedUsers: null,
      saveDialog: false,

      disabledConfig: true,
      user: {
        id: this.props.user.id,
        fk_id_baseCurrency: this.props.user.fk_id_baseCurrency,
        codeCurrency: this.props.user.codeCurrency,
        fk_id_currencies: this.props.user.fk_id_currencies
      },


      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate()


    }
    this.onCurrencyChange = this.onCurrencyChange.bind(this);
    this.hideBaseCurrencyDialog = this.hideBaseCurrencyDialog.bind(this);
    this.saveCurrencies = this.saveCurrencies.bind(this);
    this.editEnterprise = this.editEnterprise.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.onInputChange = this.onInputChange.bind(this);




  }

  componentDidMount() {
    this.getInitialData()
  }

  async saveUser(e) {
    let code = await this.getCode(e)
    let data = { ...this.state.user }

    data['fk_id_baseCurrency'] = e.value
    data['codeCurrency'] = code


    try {
      axios.put(`${url}/api/auth/base_currency/${data.id}`, data)
        .then(res => {
          this.toast.show({ severity: 'success', summary: 'Moneda base ha sido actualizada', life: 6000 });
          this.setState({
            originalFk_id_baseCurrency: data.fk_id_baseCurrency,
            originalCodeCurrency: data.codeCurrency,
            disabledConfig: !this.state.disabledConfig,
          })
          const user = {
            ...this.props.user,
            fk_id_baseCurrency: data.fk_id_baseCurrency,
            codeCurrency: data.codeCurrency,
          }
          this.props.updatedUser(user)
          this.getInitialData()
        })
    } catch (error) { }

  }

  getCode = async (e) => {
    let code
    try {
      await axios.get(`${url}/api/auth/currency/${e.value}`)
        .then(res => {
          code = res.data.code;
        });
    } catch (error) {
    }
    return code
  }



  getInitialData = async () => {

    let requestOne = axios.get(`${url}/api/auth/currencies`);
    let requestTwo = axios.get(`${url}/api/auth/enterprise/${this.props.user.fk_id_enterprise}`);
    let requestThree = axios.get(`${urlOE}latest.json?app_id=${apiOE}&base=${this.props.user.codeCurrency}`);

    axios
      .all([requestOne, requestTwo, requestThree])
      .then(
        axios.spread((...responses) => {
          const currencies = responses[0].data;
          const enterprises = responses[1].data;
          const exchanges = responses[2].data;

          // console.log("EXCHANGEs: ", exchanges);
          this.Date(exchanges)
          this.codeSymbol(currencies)

          for (let i = 0; i < Object.keys(currencies).length; i++) {

            currencies[i].rate = (1 / exchanges.rates[`${currencies[i].code}`])
            // console.log('currencies[i].rate: ', currencies[i].rate);
            if (currencies[i].rate !== 1) {
              console.log('currencies[i].rate: ', currencies[i].rate);
              console.log('currencies[i].rateConvert: ', this.props.user.additionalPercentage);
              // console.log('currencies[i].rateConvert: ', currencies[i].rate * (this.props.user.additionalPercentage/100));

              currencies[i].rate = (currencies[i].rate + (currencies[i].rate * (this.props.user.additionalPercentage / 100))).toFixed(2)
              // console.log('currencies[i].rate: ', currencies[i].rate);
            }
            currencies[i].date = exchanges.date
            currencies[i].fk_id_baseCurrency = this.props.user.fk_id_baseCurrency

          }
          console.log("Currencies INITIAL DATA 186: ", currencies);
          console.log("Enterprises INITIAL DATA 186: ", enterprises);
          console.log("Exchanges INITIAL DATA 186: ", exchanges);

          this.saveExchangeRates(currencies, enterprises)
          this.setState({ currencies, enterprises, exchanges, resetDateExchange: 'Fecha de tipo de cambio' })
        })
      )
      .catch(errors => {
        console.error(errors);
      });
  }

  saveExchangeRates = (rates, enterprises) => {
    console.log('ENTERPRISES 200: ', enterprises.additionalPercentage);
    let length = Object.keys(rates).length

    for (let i = 0; i < length; i++) {
      rates[i].additionalPercentage = enterprises.additionalPercentage
    }

    console.log("RATES: ", rates);
    // let data = {rates, enterprises}
    try {
      axios.post(`${url}/api/auth/exchangeRates`, rates)
        .then(res => {
          this.getExchangeRates()
        })
    } catch (error) {
    }
    this.setState(rates);
  }

  getExchangeRates = async (e) => {
    console.log("E: ", e);
    try {
      let currenciesShow = [];
      let aux = 0;
      let res
      let data = {
        date: null
      }

      if (e) {
        console.log("ENTRO A IF E");
        data.date = this.DateToOE(e.value)
        console.log("DATA DATE: ", data.date);
        res = axios.post(`${url}/api/auth/getExchangeRates`, data)
        console.log("RES IF E: ", data);

      } else {
        console.log("ENTRO A IF");
        data.date = this.Today()
        console.log("DATA DATE: ", data.date);
        res = axios.post(`${url}/api/auth/getExchangeRates`, data)
        console.log("RES IF: ", data);
      }

      console.log('FK ID CURRENCIES 249: ', this.props.user);
      res.then(res => {
        const exchangeRates = res.data;
        this.codeSymbolExchange(exchangeRates)

        if (typeof this.props.user.fk_id_currencies === 'string') {
          console.log("ENTRO A IF FK_ID_CURRENCIES");
          this.props.user.fk_id_currencies = this.props.user.fk_id_currencies.split(',')
          this.props.user.fk_id_currencies = this.props.user.fk_id_currencies.map(Number);
        }

        // console.log(exchangeRates);
        // console.log(this.props.user.fk_id_currencies);

        try {
          for (let i = 0; i < Object.keys(exchangeRates).length; i++) {
            for (let j = 1; j < Object.keys(this.props.user.fk_id_currencies).length; j++) {
              if (exchangeRates[i].fk_id_currency === this.props.user.fk_id_currencies[j]) {
                currenciesShow[aux] = exchangeRates[i]
                aux++
              }
            }
          }
        } catch (error) {
          console.log("ERROR: ", error);
        }
        this.setState({ exchangeRates, currenciesShow })
        console.log(res.data);
      });
      // console.log(res.data)
    } catch (error) {
    }
  }

  codeSymbolExchange(e) {
    e.map((exchangeRates) => {
      exchangeRates.codeSymbol = exchangeRates.code + ' ' + exchangeRates.symbol;
      return exchangeRates

    });
  }


  Date(exchange) {

    let ts = exchange.timestamp;

    // convert unix timestamp to milliseconds
    let ts_ms = ts * 1000;

    // initialize new Date object
    let date_ob = new Date(ts_ms);

    // year as 4 digits (YYYY)
    let year = date_ob.getFullYear();

    // month as 2 digits (MM)
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // date as 2 digits (DD)
    var date = ("0" + date_ob.getDate()).slice(-2);

    let new_date = date + '/' + month + '/' + year

    exchange.date = new_date


    return exchange

  }

  Today() {
    let date = new Date()
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    if (d < 10) {
      d = '0' + d;
    }
    if (m < 10) {
      m = '0' + m;
    }
    let today = d + '/' + m + '/' + y

    console.log("TODAY: ", today);

    return today
  }

  DateToOE(e) {

    let year = e.getFullYear();
    let month = e.getMonth() + 1;
    let day = e.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = day + '/' + month + '/' + year

    return new_date

  }



  saveCurrencies() {
    let state = { submitted: true };
    let data = { ...this.state.enterprise };
    console.log("DATA EN SAVE:", data)
    if (this.props.user.fk_id_enterprise) {

      try {
        let res = axios.put(`${url}/api/auth/enterprise_currencies/${this.props.user.fk_id_enterprise}`, data)
          .then(res => {
            this.getInitialData()
            const user = {
              ...this.props.user,
              additionalPercentage: data.additionalPercentage,
              fk_id_currencies: data.fk_id_currencies

            }
            this.props.updatedUser(user)
          })
        if (!res.error) {
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Exchange Rate Updated', life: 3000 });
        }
      } catch (error) {
      }

    }
    state = {
      ...state,
      data,
      currencyDialog: false,
      enterprise: this.emptyEnterprise,
    };


    this.setState(state);
  }



  editEnterprise() {
    let enterprise = { ...this.state.enterprises }

    this.setState({
      currencyDialog: true,
      enterprise,
    })
    this.getInitialData()
  }


  onCurrencyChange(e,) {
    let enterprise = { ...this.state.enterprise };


    if (typeof enterprise.fk_id_currencies === 'string') {

      enterprise.fk_id_currencies = enterprise.fk_id_currencies.split(',')
      enterprise.fk_id_currencies = enterprise.fk_id_currencies.map(Number);

      if (e.checked) {

        enterprise['fk_id_currencies'].push(e.value);
        console.log("HOLA", enterprise.fk_id_currencies)
        console.log("FINAL", enterprise)

      } else {
        for (let i = 0; i < enterprise.fk_id_currencies.length + 1; i++) {
          if (enterprise.fk_id_currencies[i] === e.value) {
            console.log(enterprise.fk_id_currencies[i], e.value)
            enterprise.fk_id_currencies.splice(i, 1);
            break;
          }
        }
      }
      this.setState({ enterprise });
      console.log("ENTERPRISE:", enterprise)


    } else {
      if (e.checked) {

        enterprise['fk_id_currencies'].push(e.value);
        console.log("HOLA", enterprise.fk_id_currencies)
        console.log("FINAL", enterprise)

      } else {
        for (let i = 0; i < enterprise.fk_id_currencies.length + 1; i++) {


          if (enterprise.fk_id_currencies[i] === e.value) {
            console.log(enterprise.fk_id_currencies[i], e.value)

            enterprise.fk_id_currencies.splice(i, 1);
            break;
          }
        }
      }
      this.setState({ enterprise });
      console.log("ENTERPRISE:", enterprise)
    }
  }


  // Error
  Check(val) {
    let a = { ...this.state.enterprise };
    console.log("A:", a)

    if (typeof a.fk_id_currencies === 'string') {
      a.fk_id_currencies = a.fk_id_currencies.split(',')
      a.fk_id_currencies = a.fk_id_currencies.map(Number);

      return a.fk_id_currencies.some(arrVal => val === arrVal);

    } else {

      return a.fk_id_currencies.some(arrVal => val === arrVal);

    }
  }

  onInputChange(e) {
    const val = (e.target && e.target.value) || '';
    let enterprise = { ...this.state.enterprise }
    enterprise['additionalPercentage'] = val;
    this.setState({ enterprise });
  }



  codeSymbol(e) {
    e.map((currency) => {
      //console.log("E:",agreement.updated_at)
      currency.codeSymbol = currency.code + ' ' + currency.symbol;
      return currency

    });
  }


  hideBaseCurrencyDialog() {
    this.setState({
      submitted: false,
      currencyDialog: false,
    })
  }


  actionBodyTemplate(rowData) {
    return (
      <>
        <Button icon="pi pi-pencil" color='primary' style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.editAgreement(rowData)} />
        <Button icon="pi pi-trash" className="p-button-danger" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.confirmDeleteAgreement(rowData)} />
      </>
    )
  }


  additionalPercentage = () => {
    let enterprise = { ...this.state.enterprises }
    let show = []

    if (this.props.user.fk_id_role === 58) {

      show.push(
        <>
          <div className="form-group row">
            <div className="col-lg-6">
              <label className='text-4xl text-900 pl-3'>{this.state.campusText.AdditionalPercentage}: {enterprise.additionalPercentage}  %</label>
            </div>
          </div>
        </>
      )
    }
    return show;
  }

  render() {
    const baseCurrencyDialogFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideBaseCurrencyDialog} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveCurrencies} />}
        </FormattedMessage>
      </>
    );

    return (

      <div>
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />
          <h3 className="card-label" >
            {<FormattedMessage id="ACCOUNTING_EXCHANGE_RATE.TITLE" />}
            <span className="d-block text-muted pt-2 font-size-sm">
              {<FormattedMessage id="ACCOUNTING_EXCHANGE_RATE.DESCRIPTION" />}
            </span>

          </h3>
          <br></br>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {/*TOOL BAR */}



            <div style={{ marginRight: 10 }}>
              <FormattedMessage id="GENERAL.EXPORT">
                {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
              </FormattedMessage>
            </div>


            <div style={{ marginRight: 10 }}>
              <FormattedMessage id="GENERAL.DEFAULT_EXCHANGE_RATES">
                {(message) => <Button icon="pi pi-user" className="p-button-help" label={message} onClick={this.editEnterprise} variant='contained' color='primary' />}
              </FormattedMessage>
            </div>


            <div style={{ marginRight: 10 }}>
              <p> <span className="p-input-icon-left">
                <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
              </span></p>
            </div>

            <div style={{ marginRight: 10 }}>
              <Dropdown style={{ width: 'flex', height: 33 }} id="baseCurrency" variant="outlined"
                margin="dense"
                placeholder={`${this.state.campusText.BaseExchangeRates} ${this.props.user.codeCurrency}`}
                value={this.state.selectedBaseCurrency || "code"}
                options={this.state.currencies}
                onChange={this.saveUser}
                optionLabel="code"
                optionValue="id"
              />
            </div>

            <div style={{ marginRight: 10 }}>
              <Calendar id="icon" value={this.state.currenciesShow.date}
                placeholder={this.state.resetDateExchange}
                dateFormat="dd/mm/yy"
                readOnlyInput={true}
                onChange={this.getExchangeRates}
                showIcon />
            </div>
          </div>
        </div>

        <div>
          {/*SHOW EXCHANGES*/}
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <DataTable
              ef={(el) => this.dt = el} value={this.state.currenciesShow} paginator paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate={this.state.paginatorText} rows={10} rowsPerPageOptions={[10, 20, 50]} ref={(el) => { this.dt = el; }}
              globalFilter={this.state.globalFilter} emptyMessage={<FormattedMessage id="ACCOUNTING_EXCHANGE.NO_DATA" />} loading={this.state.loading}
              dataKey="id">
              <Column field="createDate" header={this.state.campusText.Date} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Date} filterMatchMode="contains"></Column>
              <Column field="codeSymbol" header={this.state.campusText.Currency} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Currency} filterMatchMode="contains"></Column>
              <Column field="rate" header={this.state.campusText.Rate} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Rate} filterMatchMode="contains"></Column>
              <Column field='additionalPercentage' header='Additional percentage' sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder='Additional percentage' filterMatchMode='contains'></Column>
            </DataTable>
          </div>
        </div>


        {/*UPDATE AGREEMENT & CREATE AGREEMENT*/}
        <Dialog visible={this.state.currencyDialog} contentStyle={{ maxHeight: "800x", width: "800px", height: "800px", overflow: "auto" }} header={this.state.campusText.ExchangeRates} modal footer={baseCurrencyDialogFooter} onHide={this.hideBaseCurrencyDialog}>
          <br></br>
          {/* Additional percentage */}
          {this.additionalPercentage()}

          <div className='card'>
          <div className='col-8 text-900 text-2xl w-2 md:w-8 md:flex-order-0'> Rates to upload:</div>
            <div className="formgroup-inline py-5 px-5">
              {/* CURRENCIES */}
              {
                this.state.currencies.map((currency) => {
                  return (
                    <div key={currency.id} className="field-checkbox col-2">
                      <Checkbox
                        style={{ width: 35, height: 35 }}
                        variant="outlined"
                        margin="dense"
                        inputid="id"
                        name="currency"
                        value={currency.id}
                        onChange={this.onCurrencyChange}
                        checked={this.Check(currency.id)}
                      />
                      <div style={{ width: 65, height: 35 }}>
                        <div inputid="id">{currency.codeSymbol}</div>
                      </div>
                    </div>
                  )
                })
              }

            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(mapStateToProps, auth.actions)(ExchangesTable);


