import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import ServerURL from '../../../ServerURL';

import OpenExchangeURL from '../../../OpenExchangeURL';
import OpenExchangeAPI from '../../../OpenExchangeAPI';

import * as auth from '../../modulesAdmin/Auth/_redux/authRedux';

// Primereact components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber'
import { Checkbox } from 'primereact/checkbox';

// Utileria
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { classNames } from 'primereact/utils';
import './index.css'



const url = ServerURL();
const urlOE = OpenExchangeURL();
const apiOE = OpenExchangeAPI();

let emptyCategory = {
    description: '',
    active: 0,
    active_text: 'Not active',
};

const CategoriesTable = () => {
    const [category, setCategory] = useState(emptyCategory);
    const [categories, setCategories] = useState(null);
    
    const [submitted, setSubmitted] = useState(false);
    const [checked, setChecked] = useState(false);

    const [categoryDialog, setCategoryDialog] = useState(false);
    const [deleteCategoryDialog, setDeleteCategoryDialog] = useState(false)

    // Peticiones con AXIOS
    // Get categories
    const getCategories = async () => {
        try {
            const categories = await axios.get(`${url}/api/auth/expenses/categories`)
                .then(res => {
                    let _categories = res.data
                    setCategories(_categories)
                    // activeCategory(_categories)
                })
        } catch (error) {
            console.log('ERROR: ', error);

        }
    }



    useEffect(async () => {
        getCategories();
        // console.log('CATEGORIES: ', categories);
    }, [])

    // // Formatos
    // const activeCategory = (e) => {
    //     console.log('E: ', e);
        
    //     let length = Object.keys(e).length;
    //     for (let i = 0; i < length; i++) {
    //         if (e[i].active === 1) {
    //             e[i].active_text = 'Active'
    //             setCategory(e)
    //         } else {
    //             e[i].active_text = 'Not active'
    //             setCategory(e)
    //         }
    //     }

    //     return e
    // }

    // Save Category
    const saveCategory = async () => {
        setSubmitted(true)

        let _category = { ...category }

        const formData = new FormData()

        console.log('CATEGORIE: ', _category);

        formData.append('description', _category.description)
        formData.append('active', _category.active)

        console.log("CATEGORIE: ", _category);
        

        const config = {
            header: {
                'Content-Type': 'multipart/form-data'
            }
        }

        if (_category.id) {
            console.log('EDITAR CATEGORY');

            await axios.put(`${url}/api/auth/expenses/category/${_category.id}`, formData, config)
                .then(res => {
                    setCategory(emptyCategory)
                    setChecked(false)
                    setCategoryDialog(false)
                    getCategories()
                })
        } else {
            console.log('NUEVO REGISTRO');

            await axios.post(`${url}/api/auth/expenses/category`, formData, config)
                .then(res => {
                    console.log('RES: ', res);
                    setCategory(emptyCategory)
                    setChecked(false)
                    setCategoryDialog(false)
                    getCategories()
                })
        }
    }

    // Delete category
    const deleteCategory = async () => {
        let _category = { ...category }

        try {
            await axios.delete(`${url}/api/auth/expenses/category/${_category.id}`)
                .then(res => {
                    setCategory(emptyCategory)
                    setChecked(false)
                    setDeleteCategoryDialog(false)
                    getCategories()
                })
        } catch (error) {
            console.log('ERROR: ', error);
        }
    }

    // Funciones pagina dinamica
    const openNew = () => {
        setCategory(emptyCategory)
        setSubmitted(false)
        setCategoryDialog(true);
    };

    const editCategory = (categoryEdit) => {
        console.log('CATEGORY: ', categoryEdit);
        // setCategory(categoryEdit)
        setCategoryDialog(true)

        if (categoryEdit.acive === 1) {
            categoryEdit.active_text = 'Active';
            setChecked(true)
        } else {
            categoryEdit.active_text = 'Not active'
            setChecked(false)
        }

        setCategory(categoryEdit)
    }

    const confirmDeleteCategory = (deleteCategory) => {
        console.log('DELETE CATEGORY: ', deleteCategory);
        setCategory(deleteCategory)
        setDeleteCategoryDialog(true)
    }

    const hideDialog = () => {
        setCategory(emptyCategory)
        setChecked(false)
        setSubmitted(false)
        setCategoryDialog(false)
    };

    const deleteHideDialog = () => {
        setSubmitted(false)
        setCategory(emptyCategory)
        setDeleteCategoryDialog(false)
    }

    const onInputChange = (e, name) => {
        const val = (e.target.value && e.target.value || '');
        let _category = { ...category };
        _category[`${name}`] = val

        setCategory(_category)
    };

    const onChecked = (e) => {

        if (e.checked) {
            category.active = 1
            category.active_text = 'Active'
            setChecked(true)
        } else {
            category.active = 0
            category.active_text = 'Not active'
            setChecked(false)
        }


        console.log('CHECKED: ', category);
    }

    // Formatos
    const formatActive = (value) => {
        if (value === 1) {
            value = 'active'
            return <span className={`categories-badge active-${value}`}>Active</span>
        } else {
            value = 'not-active'
            return <span className={`categories-badge active-${value}`}>Not active</span>

        }
    }

    // Templates
    const activeBodyTemplate = (rowData) => {
        return formatActive(rowData.active)
    }
    
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button icon='pi pi-plus' className='p-button-rounded p-button-outlined mr-2' onClick={openNew} />
            </React.Fragment>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon='pi pi-pencil' className='p-button-rounded p-button-outlined mr-2' onClick={() => editCategory(rowData)} />
                <Button icon='pi pi-trash' className='p-button-rounded p-button-outlined p-button-danger mr-2' onClick={() => confirmDeleteCategory(rowData)} />
            </React.Fragment>
        )
    }

    // Footers
    const categoryDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDialog} />
                <Button label='Save' icon='pi pi-check' className='p-button-text' onClick={saveCategory} />
            </React.Fragment>
        );
    };

    const deleteCategoryDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={deleteHideDialog} />
                <Button label='Confirm' icon='pi pi-check' className='p-button-text' onClick={deleteCategory} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <Toolbar left={leftToolbarTemplate} />
            <DataTable value={categories} sortable dataKey='id' paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                currentPageReportTemplate='Showing {first} to {last} of {totalRecords} categories'
                responsiveLayout='scroll' >
                <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} exportable={false} />
                <Column field='description' header='Description' sortable style={{ minWidth: '16rem' }} />
                <Column field='active' header='Active' sortable style={{ minWidth: '12rem' }} body={activeBodyTemplate}  />
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} />
            </DataTable>

            <Dialog visible={categoryDialog} onHide={hideDialog} style={{ width: '750px' }} header='New category' modal
                className='p-fluid' footer={categoryDialogFooter}>
                <div className='formgrid grid' style={{ marginTop: '20px' }} >
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <InputText id='description' value={category.description} onChange={(e) => onInputChange(e, 'description')}
                                required className={classNames({ 'p-invalid': submitted && !category.description })} />
                            {submitted && !category.description && <small className='p-erro'>Description is required</small>}
                            <label htmlFor='description'>Description</label>
                        </span>
                    </div>

                    <div className='field col-6 md:col-4'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <Checkbox checked={checked} onChange={onChecked} value={category.active} />
                            </span>
                            <InputText disabled placeholder={category.active_text} />
                        </div>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={deleteCategoryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCategoryDialogFooter} onHide={deleteHideDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {category && <span>Are you sure you want to delete <b>{category.descriptionSub}</b>?</span>}
                </div>
            </Dialog>
        </div>
    );
}

export default CategoriesTable;