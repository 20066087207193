import React from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

const PowerBiComponent = (props) => {
//   const reportId = props.reportId;
//   console.log("report id:", reportId);

//   const embedUrl = props.embedUrl;
//   console.log("embed url:", embedUrl);

  return (
    <div>
        <h1>Expenses Analytics</h1>

      {/* <PowerBIEmbed
        embedConfig={{
          type: "report",
          id: '<Report Id>',
          embedUrl: '<Embed Url>',
          accessToken: "<Acces Token>",
          tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false,
              },
            },
            background: models.BackgroundType.Transparent,
          },
        }}
        eventHandlers={
          new Map([
            [
              "loaded",
              function () {
                console.log("Report loaded");
              },
            ],
            [
              "rendered",
              function () {
                console.log("Report id: ");
              },
            ],
            [
              "error",
              function (event) {
                console.log(event.detail);
              },
            ]
          ])
        }
        cssClassName={"report-style-class"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      /> */}
    </div>
  );
};

export default PowerBiComponent;