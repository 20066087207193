import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/_core";
import { ContentRoute } from "../_metronic/layoutAgency";

import { DataUserPage } from "./pages/DataUserPage";
import { TutorialsSectionPage } from "./pages/TutorialsSectionPage";
import { MainBillingPage } from "./pages/MainBillingPage";
import { ReportPaymentPage } from "./pages/ReportPaymentPage";

import { BuilderPage } from "./pagesAgency/BuilderPage";
import { MyPage } from "./pagesAgency/MyPage";
import { InicioPage } from "./pagesAgency/InicioPage";
import { DashboardPage } from "./pagesAgency/DashboardPage";
import { RankingPage } from "./pagesAgency/RankingPage";
import { DashboardPageDefault } from "./pagesAgency/DashboardPageDefault";

import { GetTasksPage } from './pagesAgency/GetTasksPage';
import { AddTaskPage } from './pagesAgency/AddTaskPage';

import { AgentPage } from "./pagesAgency/AgentPage";
import { TeamPage } from "./pagesAgency/TeamPage";
import { ReportsPage } from "./pagesAgency/ReportsPage";
import { LessonPage } from './pagesAgency/LessonPage';
import { EvaluationLessonEditPage } from './pagesAgency/EvaluationLessonEditPage';
import { EvaluationLessonPage } from './pagesAgency/EvaluationLessonPage';
import { CourseAssignmentPage } from "./pagesAgency/CourseAssignmentPage";
import { CoursePage } from './pagesAgency/CoursePage';

import { CoursesPage } from "./pagesAgency/CoursesPage";
import { DataAgencyPage } from "./pagesAgency/DataAgencyPage";
import { DataOfficePage } from "./pagesAgency/DataOfficePage";
import { ScriptEdvisorPage } from './pagesAccounting/ScriptEdvisorPage';
import { InvoicesPage } from './pagesAccounting/InvoicesPage';
import { InvoicesManagementPage } from "./pagesAccounting/InvoicesManagementPage";
import { EditInvoicePage } from './pagesAccounting/EditInvoicePage';
import { ViewOptionsPage } from './pagesAccounting/ViewOptionsPage';
import { ExchangesPage } from './pagesAccounting/ExchangesPage';
import { ExchangesRatesPublicPage } from './pagesAccounting/ExchangesRatesPublicPage';
import { AccountsPage } from './pagesAccounting/AccountsPage';
import { ExchangeOfficesPage } from './pagesAccounting/ExchangeOfficesPage';
import { AgentPaymentsPage } from './pagesAccounting/AgentPaymentsPage';
import { AdminPaymentsPage } from './pagesAccounting/AdminPaymentsPage';
import { TransfersPage } from './pagesAccounting/TransfersPage';
import { PaymentTransfersPage } from './pagesAccounting/PaymentTransfersPage';
import { CommissionsPage } from './pagesAccounting/CommissionsPage';
import { AnalyticsPage } from './pagesAccounting/AnalyticsPage';
import { AgencyCommissionsPage } from './pagesAccounting/AgencyCommissionsPage';
import { AgencyProfitAgencyPage } from "./pagesAccounting/AgencyProfitPage";

// WIYU COMPONENTS
import { StudentsPage } from "./pagesWiyu/StudentsPage";
import { EventsPage } from "./pagesWiyu/EventsPage";
import { GroupStudentsPage } from "./pagesWiyu/GroupStudentsPage";
import { CustomerSuccessPage } from "./pagesWiyu/CustomerSuccessPage"
import { LProgramsPage } from "./pagesWiyu/ProgramsPage";
import { ProgramPage } from "./pagesWiyu/ProgramPage";
import { ProgramsAssignmentPage } from "./pagesWiyu/ProgramsAssignmentPage";
import { DocumentsPage } from "./pagesWiyu/DocumentsPage";
import { NewStudentsPage } from "./pagesWiyu/NewStudentsPage";
import { AssignStudentsGroupsPage } from "./pagesWiyu/AssignStudentsGroupsPage";
import { CustomerSuccessTablePage } from "./pagesWiyu/CustomerSuccessTablePage";
import { CreateNotificationPage } from "./pagesWiyu/CreateNotificationPage";
import { SendNotificationPage } from "./pagesWiyu/SendNotificationPage";
import { NewEventsPage } from "./pagesWiyu/NewEventsPage";
import { EditEventPage } from "./pagesWiyu/EditEventPage";
import { AnalyticsRatesPage } from "./pagesWiyu/AnalyticsRatesPage";

// EXPENSES COMPONENTS
import { ExpensesPage } from "./pagesExpenses/ExpensesPage";
import { CategoriesPage } from "./pagesExpenses/CategoriesPage";
import { SubCategoriesPage } from "./pagesExpenses/SubCategoriesPage";
import { ProvidersPage } from "./pagesExpenses/ProvidersPage";
import { ExpensesAnalyticsPage } from "./pagesExpenses/ExpensesAnalyticsPage";


import { AgencyDataPage } from "./pagesSettings/AgencyDataPage";

import { CustomerSuccessItemsPage } from "./pagesWiyu/CustomerSuccessItemsPage";
import { AssignmentStudentPage } from "./pagesWiyu/AssignStudentsPage";

// import { ReportsPage1 } from "./pagesWiyu/ReportsPage"

import { LegacyInvoicesPage } from "./pagesAccounting/LegacyInvoicesPage";
import { ScriptEdvisorLegacyPage } from "./pagesAccounting/ScriptEdvisorLegacyPage";




import { useSelector } from "react-redux";
import { MovementsAndInvoicesBillingPage } from "./pages/MovementsAndInvoicesBillingPage";
import { ToPayBillingPage } from "./pages/ToPayBillingPage";
import { UpgradeBillingPage } from "./pages/UpgradeBillingPage";


const GoogleMaterialPage = lazy(() =>
  import("./modulesAdmin/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modulesAdmin/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modulesAdmin/ECommerce/pages/eCommercePage")

);

export default function BasePageAgency() {
  const { user } = useSelector(state => state.auth);
  const id_agente = 64;
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const renderSwitchRedirect = (user) => {
    switch (user.role) {
      case 'Director':
        // if(user.billing === 2){
        //   return <Redirect exact from="/" to="/billing"/>;
        // }else{
        // if (user.tutorials === 0) {
          return <Redirect exact from="/" to="/inicio" />;
        // } else {
          // return <Redirect exact from="/" to="/tutorial" />;
        // }
      // }
      case 'Gerente':
        // if(user.billing === 2){
        //   return <Redirect exact from="/" to="/billing"/>;
        // }else{
        // if (user.tutorials === 0) {
          return <Redirect exact from="/" to="/inicio" />;
        // } else {
        //   return <Redirect exact from="/" to="/tutorial" />;
        // }
      // }
      case 'Coordinador':
        // if(user.billing === 2){
        //   return <Redirect exact from="/" to="/billing"/>;
        // }else{
        // if (user.tutorials === 0) {
          return <Redirect exact from="/" to="/inicio" />;
        // } else {
        //   return <Redirect exact from="/" to="/tutorial" />;
        // }
      // }

      default:
        // if(user.billing === 2){
        //   return <Redirect exact from="/" to="/billing"/>;
        // }else{
        // if (user.tutorials === 0) {
          return <Redirect exact from="/" to="/inicio" />;
        // } else {
          // return <Redirect exact from="/" to="/tutorial" />;
        // }
      // }
    }
  };

  const renderSwitchRoute = (user) => {
    if (user.owner) {
      return (
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            // user.billing === 2 ? <Redirect exact from="/" to="/billing" /> : 
            // user.tutorials === 0 ? 
            <Redirect exact from="/" to="/inicio" /> 
            // : <Redirect exact from="/" to="/tutorial" />
          }
          <ContentRoute path="/inicio" component={InicioPage} />
          <ContentRoute path="/analytics" component={DashboardPage} />
          <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage} />
          <ContentRoute path="/tutorial/course/lesson" component={LessonPage} />
          <ContentRoute path="/tutorial/course" component={CoursePage} />
          <ContentRoute path="/tutorial" component={TutorialsSectionPage} />
          {user.billing === 1 && <ContentRoute path="/billing" component={MainBillingPage} />}
          {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage} />}
          {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage} />}
          {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage} />}
          {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage} />}
          {/* {user.tutorials && <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>} */}
          {user.learning && <ContentRoute path="/get-ranking" component={RankingPage} />}
          {user.learning && <ContentRoute path="/builder" component={BuilderPage} />}
          {user.learning && <ContentRoute path="/my-page" component={MyPage} />}
          {user.learning && <ContentRoute path="/get-invoices-management" component={InvoicesPage} />}
          {user.learning && <ContentRoute path="/get-invoices" component={InvoicesManagementPage} />}

          {/* WIYU ROUTES */}
          {user.wiyu && <ContentRoute path="/wiyu-students" component={StudentsPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-events" component={EventsPage} />}
          {/* üser. */}
          {user.wiyu && <ContentRoute path="/wiyu-groups" component={GroupStudentsPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-customer-success" component={CustomerSuccessPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-programs" component={LProgramsPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-program" component={ProgramPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-programs-assignment" component={ProgramsAssignmentPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-documents" component={DocumentsPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-students-new" component={NewStudentsPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-groups-assigns" component={AssignStudentsGroupsPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-customer-assign" component={CustomerSuccessTablePage} />}
          {user.wiyu && <ContentRoute path="/wiyu-create-notifications" component={CreateNotificationPage} />}
          {user.wiyu && <ContentRoute path="/wiyu-send-notifications" component={SendNotificationPage} />}
          {user.wiyu && <ContentRoute path='/wiyu-events-new' component={NewEventsPage} />}
          {user.wiyu && <ContentRoute path='/wiyu-events-edit' component={EditEventPage} />}
          {user.wiyu && <ContentRoute path='/wiyu-analytics-rates' component={AnalyticsRatesPage} />}

          {/* EXPENSES ROUTES */}
          {/* {user.expenses} */}
          <ContentRoute path='/get-expenses' component={ExpensesPage} />
          <ContentRoute path='/get-categories' component={CategoriesPage} />
          <ContentRoute path='/get-subcategories' component={SubCategoriesPage} />
          <ContentRoute path='/get-providers' component={ProvidersPage} />
          <ContentRoute path='/get-analytics-expenses' component={ExpensesAnalyticsPage} />


          {user.learning && <ContentRoute path="/wiyu-customer-items" component={CustomerSuccessItemsPage} />}
          {user.learning && <ContentRoute path="/wiyu-customer-students" component={AssignmentStudentPage} />}

          {user.learning && <ContentRoute path="/get-task" component={GetTasksPage} />}
          {user.learning && <ContentRoute path="/add-task" component={AddTaskPage} />}

          {/* {user.learning && <ContentRoute path="/get-agents" component={AgentPage}/>} */}
          {user.learning && <ContentRoute path="/get-team" component={TeamPage} />}
          {
            (user.fk_id_role !== id_agente && user.learning) && (
              <ContentRoute path="/get-reports" component={ReportsPage} />
            )
          }
          {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation-edit" component={EvaluationLessonEditPage} />}
          {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage} />}
          {user.learning && <ContentRoute path="/get-courses/course/lesson" component={LessonPage} />}
          {user.learning && <ContentRoute path="/get-courses/course" component={CoursePage} />}
          {user.learning && <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage} />}
          {user.learning && <ContentRoute path="/get-courses" component={CoursesPage} />}
          {user.learning && <ContentRoute path="/get-agency" component={DataAgencyPage} />}
          {user.learning && <ContentRoute path="/get-offices" component={DataOfficePage} />}


          {user.accounting && <ContentRoute path="/get-script" component={ScriptEdvisorPage} />}
          {user.accounting && <ContentRoute path="/get-invoices-management" component={InvoicesPage} />}
          {user.accounting && <ContentRoute path='/get-invoices' component={InvoicesManagementPage} />}
          {user.accounting && <ContentRoute path="/get-exchanges" component={ExchangesPage} />}

          {/* {user.accounting && <ContentRoute path='/get-invoices-legacy' component={LegacyInvoicesPage} />} */}
          {/* {user.accounting && <ContentRoute path='/get-script-legacy' component={ScriptEdvisorLegacyPage} />} */}
          {user.accounting && <ContentRoute path='/get-invoices-legacy' component={ScriptEdvisorLegacyPage} />}


          {user.accounting && <ContentRoute path="/get-exchangesPublic" component={ExchangesRatesPublicPage} />}

          {user.accounting && <ContentRoute path="/get-view-options" component={ViewOptionsPage} />}
          {user.accounting && <ContentRoute path="/get-exchanges" component={ExchangesPage} />}
          {user.accounting && <ContentRoute path="/get-accounts" component={AccountsPage} />}
          {user.accounting && <ContentRoute path="/get-exchangeOffices" component={ExchangeOfficesPage} />}
          {user.accounting && <ContentRoute path="/get-agent-payments" component={AgentPaymentsPage} />}
          {user.accounting && <ContentRoute path="/get-admin-payments" component={AdminPaymentsPage} />}
          {user.accounting && <ContentRoute path="/get-transfers" component={TransfersPage} />}
          {user.accounting && <ContentRoute path="/get-payment-transfers" component={PaymentTransfersPage} />}
          {user.accounting && <ContentRoute path="/get-commissions" component={CommissionsPage} />}
          {user.accounting && <ContentRoute path="/get-analytics" component={AnalyticsPage} />}
          {user.accounting && <ContentRoute path="/get-exchangeOffices" component={ExchangeOfficesPage} />}
          {user.accounting && <ContentRoute path="/get-agencyCommissions" component={AgencyCommissionsPage} />}
          {user.accounting && <ContentRoute path='/get-agency-profit' component={AgencyProfitAgencyPage} />
          }

          {/* Wiyu */}
          {/* {user.wiyu && <ContentRoute path="/wiyu-students" component={StudentsPage}/>}
            {user.wiyu && <ContentRoute path="/wiyu-events" component={EventsPage}/>}
            {user.wiyu && <ContentRoute path="/wiyu-groups" component={GroupStudentsPage}/>}
            {user.wiyu && <ContentRoute path="/wiyu-notifications" component={NotificationPage}/>}
            {user.wiyu && <ContentRoute path="/wiyu-programs" component={ProgramsPage}/> } */}

          <ContentRoute path='/get-agency' component={DataAgencyPage} />
          <ContentRoute path="/get-agents" component={AgentPage} />
          <ContentRoute path="/my-profile" component={DataUserPage} />

          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/e-commerce" component={ECommercePage} />
          <Redirect to="error/error-v1" />
        </Switch>
      );
    } else {
      switch (user.role) {
        case 'Director':
          return (
            <Switch>
              {
                /* Redirect from root URL to /dashboard. */
                renderSwitchRedirect(user)
              }
              <ContentRoute path="/inicio" component={InicioPage} />
              <ContentRoute path="/analytics" component={DashboardPage} />
              <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage} />
              <ContentRoute path="/tutorial/course/lesson" component={LessonPage} />
              <ContentRoute path="/tutorial/course" component={CoursePage} />
              <ContentRoute path="/tutorial" component={TutorialsSectionPage} />
              {user.billing === 1 && <ContentRoute path="/billing" component={MainBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage} />}
              {/* {user.tutorials && <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>} */}
              {user.learning && <ContentRoute path="/get-ranking" component={RankingPage} />}
              {user.learning && <ContentRoute path="/builder" component={BuilderPage} />}
              {user.learning && <ContentRoute path="/my-page" component={MyPage} />}

              {user.learning && <ContentRoute path="/get-task" component={GetTasksPage} />}
              {user.learning && <ContentRoute path="/add-task" component={AddTaskPage} />}

              {user.learning && <ContentRoute path="/get-agents" component={AgentPage} />}
              {user.learning && <ContentRoute path="/get-team" component={TeamPage} />}
              {user.learning && <ContentRoute path="/get-reports" component={ReportsPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation-edit" component={EvaluationLessonEditPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson" component={LessonPage} />}
              {user.learning && <ContentRoute path="/get-courses/course" component={CoursePage} />}
              {user.learning && <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage} />}
              {user.learning && <ContentRoute path="/get-courses" component={CoursesPage} />}
              {user.learning && <ContentRoute path="/get-agency" component={DataAgencyPage} />}
              {user.learning && <ContentRoute path="/get-offices" component={DataOfficePage} />}

              {user.accounting && <ContentRoute path="/get-script" component={ScriptEdvisorPage} />}
              {user.accounting && <ContentRoute path='/get-invoices-legacy' component={ScriptEdvisorLegacyPage} />}

              {user.accounting && <ContentRoute path="/get-invoices-management" component={InvoicesPage} />}
              {user.accounting && <ContentRoute path='/get-invoices' component={InvoicesManagementPage} />}
              {user.accounting && <ContentRoute path="/get-view-options" component={ViewOptionsPage} />}
              {user.accounting && <ContentRoute path="/get-edit-invoice" component={EditInvoicePage} />}
              {user.accounting && <ContentRoute path="/get-exchanges" component={ExchangesPage} />}
              {user.accounting && <ContentRoute path='/get-agency-profit' component={AgencyProfitAgencyPage} />}

                        {/* EXPENSES ROUTES */}
          {/* {user.expenses} */}
          <ContentRoute path='/get-expenses' component={ExpensesPage} />
          <ContentRoute path='/get-categories' component={CategoriesPage} />
          <ContentRoute path='/get-subcategories' component={SubCategoriesPage} />
          <ContentRoute path='/get-providers' component={ProvidersPage} />
          <ContentRoute path='/get-analytics-expenses' component={ExpensesAnalyticsPage} />


              {/* Wiyu */}
              {/* {user.wiyu && <ContentRoute path="/wiyu-students" component={StudentsPage}/>}
                {user.wiyu && <ContentRoute path="/wiyu-events" component={EventsPage}/>}
                {user.wiyu && <ContentRoute path="/wiyu-groups" component={GroupStudentsPage}/>}
                {user.wiyu && <ContentRoute path="/wiyu-notifications" component={NotificationPage}/>}
                {user.wiyu && <ContentRoute path="/wiyu-programs" component={ProgramsPage}/> } */}

              {user.accounting && <ContentRoute path="/get-exchangesPublic" component={ExchangesRatesPublicPage} />}


              {user.accounting && <ContentRoute path="/get-accounts" component={AccountsPage} />}
              {user.accounting && <ContentRoute path="/get-exchangeOffices" component={ExchangeOfficesPage} />}
              {user.accounting && <ContentRoute path="/get-agencyCommissions" component={AgencyCommissionsPage} />}
              {user.accounting && <ContentRoute path="/get-agent-payments" component={AgentPaymentsPage} />}
              {user.accounting && <ContentRoute path="/get-admin-payments" component={AdminPaymentsPage} />}
              {user.accounting && <ContentRoute path="/get-transfers" component={TransfersPage} />}
              {user.accounting && <ContentRoute path="/get-payment-transfers" component={PaymentTransfersPage} />}
              {user.accounting && <ContentRoute path="/get-commissions" component={CommissionsPage} />}
              {user.accounting && <ContentRoute path="/get-analytics" component={AnalyticsPage} />}


              <ContentRoute path="/my-profile" component={DataUserPage} />

              <Route path="/google-material" component={GoogleMaterialPage} />
              <Route path="/react-bootstrap" component={ReactBootstrapPage} />
              <Route path="/e-commerce" component={ECommercePage} />
              <Redirect to="error/error-v1" />
            </Switch>
          );
        case 'Gerente':
          return (
            <Switch>
              {
                /* Redirect from root URL to /dashboard. */
                renderSwitchRedirect(user)
              }
              {user.learning && <ContentRoute path="/builder" component={BuilderPage} />}
              {user.learning && <ContentRoute path="/my-page" component={MyPage} />}

              {user.learning && <ContentRoute path="/get-task" component={GetTasksPage} />}
              {user.learning && <ContentRoute path="/add-task" component={AddTaskPage} />}

              <ContentRoute path="/inicio" component={InicioPage} />
              <ContentRoute path="/analytics" component={DashboardPage} />
              <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage} />
              <ContentRoute path="/tutorial/course/lesson" component={LessonPage} />
              <ContentRoute path="/tutorial/course" component={CoursePage} />
              <ContentRoute path="/tutorial" component={TutorialsSectionPage} />
              {user.billing === 1 && <ContentRoute path="/billing" component={MainBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage} />}
              {/* {user.tutorials && <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>} */}
              {user.learning && <ContentRoute path="/get-ranking" component={RankingPage} />}
              {user.learning && <ContentRoute path="/get-agents" component={AgentPage} />}
              {user.learning && <ContentRoute path="/get-team" component={TeamPage} />}
              {user.learning && <ContentRoute path="/get-reports" component={ReportsPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation-edit" component={EvaluationLessonEditPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson" component={LessonPage} />}
              {user.learning && <ContentRoute path="/get-courses/course" component={CoursePage} />}
              {user.learning && <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage} />}
              {user.learning && <ContentRoute path="/get-courses" component={CoursesPage} />}
              {user.learning && <ContentRoute path="/get-agency" component={DataAgencyPage} />}
              {user.learning && <ContentRoute path="/get-offices" component={DataOfficePage} />}
              {user.accounting && <ContentRoute path='/get-invoices' component={InvoicesManagementPage} />}
              {user.accounting && <ContentRoute path="/get-view-options" component={ViewOptionsPage} />}
              {user.accounting && <ContentRoute path="/get-edit-invoice" component={EditInvoicePage} />}
              {user.accounting && <ContentRoute path="/get-exchanges" component={ExchangesPage} />}

              {user.accounting && <ContentRoute path="/get-exchangesPublic" component={ExchangesRatesPublicPage} />}

                        {/* EXPENSES ROUTES */}
          {/* {user.expenses} */}
          <ContentRoute path='/get-expenses' component={ExpensesPage} />
          <ContentRoute path='/get-categories' component={CategoriesPage} />
          <ContentRoute path='/get-subcategories' component={SubCategoriesPage} />
          <ContentRoute path='/get-providers' component={ProvidersPage} />
          <ContentRoute path='/get-analytics-expenses' component={ExpensesAnalyticsPage} />



              {user.accounting && <ContentRoute path="/get-analytics" component={AnalyticsPage} />}


              <ContentRoute path="/my-profile" component={DataUserPage} />

              <Route path="/google-material" component={GoogleMaterialPage} />
              <Route path="/react-bootstrap" component={ReactBootstrapPage} />
              <Route path="/e-commerce" component={ECommercePage} />
              <Redirect to="error/error-v1" />
            </Switch>
          );
        case 'Coordinador':
          return (
            <Switch>
              {
                /* Redirect from root URL to /dashboard. */
                renderSwitchRedirect(user)
              }
              {user.learning && <ContentRoute path="/builder" component={BuilderPage} />}
              {user.learning && <ContentRoute path="/my-page" component={MyPage} />}

              {user.learning && <ContentRoute path="/get-task" component={GetTasksPage} />}
              {user.learning && <ContentRoute path="/add-task" component={AddTaskPage} />}

              <ContentRoute path="/inicio" component={InicioPage} />
              <ContentRoute path="/analytics" component={DashboardPage} />
              <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage} />
              <ContentRoute path="/tutorial/course/lesson" component={LessonPage} />
              <ContentRoute path="/tutorial/course" component={CoursePage} />
              <ContentRoute path="/tutorial" component={TutorialsSectionPage} />
              {user.billing === 1 && <ContentRoute path="/billing" component={MainBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage} />}
              {/* {user.tutorials && <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>} */}
              {user.learning && <ContentRoute path="/get-ranking" component={RankingPage} />}
              {user.learning && <ContentRoute path="/get-agents" component={AgentPage} />}
              {user.learning && <ContentRoute path="/get-team" component={TeamPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation-edit" component={EvaluationLessonEditPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson" component={LessonPage} />}
              {user.learning && <ContentRoute path="/get-courses/course" component={CoursePage} />}
              {user.learning && <ContentRoute path="/get-course-assignment" component={CourseAssignmentPage} />}
              {user.learning && <ContentRoute path="/get-courses" component={CoursesPage} />}
              {user.learning && <ContentRoute path="/get-agency" component={DataAgencyPage} />}
              {user.accounting && <ContentRoute path='/get-invoices' component={InvoicesManagementPage} />}
              {user.accounting && <ContentRoute path="/get-view-options" component={ViewOptionsPage} />}
              {user.accounting && <ContentRoute path="/get-edit-invoice" component={EditInvoicePage} />}
              {user.accounting && <ContentRoute path="/get-exchanges" component={ExchangesPage} />}

              {user.accounting && <ContentRoute path="/get-exchangesPublic" component={ExchangesRatesPublicPage} />}

                        {/* EXPENSES ROUTES */}
          {/* {user.expenses} */}
          <ContentRoute path='/get-expenses' component={ExpensesPage} />
          <ContentRoute path='/get-categories' component={CategoriesPage} />
          <ContentRoute path='/get-subcategories' component={SubCategoriesPage} />
          <ContentRoute path='/get-providers' component={ProvidersPage} />
          <ContentRoute path='/get-analytics-expenses' component={ExpensesAnalyticsPage} />



              {user.accounting && <ContentRoute path="/get-analytics" component={AnalyticsPage} />}

              <ContentRoute path="/my-profile" component={DataUserPage} />

              <Route path="/google-material" component={GoogleMaterialPage} />
              <Route path="/react-bootstrap" component={ReactBootstrapPage} />
              <Route path="/e-commerce" component={ECommercePage} />
              <Redirect to="error/error-v1" />
            </Switch>
          );
        case 'Agente':
          return (
            <Switch>
              {
                /* Redirect from root URL to /dashboard. */
                renderSwitchRedirect(user)
              }
              {user.learning && <ContentRoute path="/builder" component={BuilderPage} />}
              {user.learning && <ContentRoute path="/my-page" component={MyPage} />}

              {user.learning && <ContentRoute path="/get-task" component={GetTasksPage} />}
              {user.learning && <ContentRoute path="/add-task" component={AddTaskPage} />}

              <ContentRoute path="/inicio" component={InicioPage} />
              <ContentRoute path="/dashboard" component={DashboardPageDefault} />
              <ContentRoute path="/tutorial/course/lesson/evaluation" component={EvaluationLessonPage} />
              <ContentRoute path="/tutorial/course/lesson" component={LessonPage} />
              <ContentRoute path="/tutorial/course" component={CoursePage} />
              <ContentRoute path="/tutorial" component={TutorialsSectionPage} />
              {user.billing === 1 && <ContentRoute path="/billing" component={MainBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/report-payment" component={ReportPaymentPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/to-pay" component={ToPayBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices/upgrade" component={UpgradeBillingPage} />}
              {user.billing === 1 && <ContentRoute path="/movements-and-invoices" component={MovementsAndInvoicesBillingPage} />}
              {/* {user.tutorials && <ContentRoute path="/tutorial" component={TutorialsSectionPage}/>} */}
              {user.learning && <ContentRoute path="/get-ranking" component={RankingPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation-edit" component={EvaluationLessonEditPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson/evaluation" component={EvaluationLessonPage} />}
              {user.learning && <ContentRoute path="/get-courses/course/lesson" component={LessonPage} />}
              {user.learning && <ContentRoute path="/get-courses/course" component={CoursePage} />}
              {user.learning && <ContentRoute path="/get-courses" component={CoursesPage} />}
              {user.accounting && <ContentRoute path='/get-invoices' component={InvoicesManagementPage} />}
              {user.accounting && <ContentRoute path="/get-view-options" component={ViewOptionsPage} />}
              {user.accounting && <ContentRoute path="/get-edit-invoice" component={EditInvoicePage} />}
              {user.accounting && <ContentRoute path="/get-exchanges" component={ExchangesPage} />}

                        {/* EXPENSES ROUTES */}
          {/* {user.expenses} */}
          <ContentRoute path='/get-expenses' component={ExpensesPage} />
          {/* <ContentRoute path='/get-categories' component={CategoriesPage} />
          <ContentRoute path='/get-subcategories' component={SubCategoriesPage} />
          <ContentRoute path='/get-providers' component={ProvidersPage} /> */}
                    <ContentRoute path='/get-analytics-expenses' component={ExpensesAnalyticsPage} />


              {user.accounting && <ContentRoute path="/get-exchangesPublic" component={ExchangesRatesPublicPage} />}



              <ContentRoute path="/my-profile" component={DataUserPage} />

              <Route path="/google-material" component={GoogleMaterialPage} />
              <Route path="/react-bootstrap" component={ReactBootstrapPage} />
              <Route path="/e-commerce" component={ECommercePage} />
              <Redirect to="error/error-v1" />
            </Switch>
          );
        default:
          return (
            <Switch>
              {
                /* Redirect from root URL to /dashboard. */
                renderSwitchRedirect(user)
              }
              <ContentRoute path="/builder" component={BuilderPage} />
              <ContentRoute path="/my-page" component={MyPage} />

              <ContentRoute path="/get-task" component={GetTasksPage} />
              <ContentRoute path="/add-task" component={AddTaskPage} />

              <Route path="/google-material" component={GoogleMaterialPage} />
              <Route path="/react-bootstrap" component={ReactBootstrapPage} />
              <Route path="/e-commerce" component={ECommercePage} />
              <Redirect to="error/error-v1" />
            </Switch>
          );
      }
    }
  };

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {renderSwitchRoute(user)}
    </Suspense>
  );
}
