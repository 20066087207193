//* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
//import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css'

export function AsideMenuListDO({ layoutProps }) {
  //const int1 = useIntl();
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/google-material", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/google-material">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
            </span>
            <span className="menu-text text-uppercase">Expenses module</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              {/* EXPENSES */}
              <li className="menu-section">
                <h4 className="menu-text text-white-alpha-90 text-base">Administration</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>

              {/* LIST EXPENSES */}
              <li className="menu-section">
                <li
                  className={`menu-item ${getMenuItemActive("/get-expenses", false)}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/get-expenses">
                    <span className="svg-icon menu-icon">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
                    </span>
                    <span className="menu-text">Expenses</span>
                  </NavLink>
                </li>

              </li>

              {/* CONCILIAR */}
              <li
                className={`menu-item ${getMenuItemActive("/get-reconcile", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-agency-profit">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
                  </span>
                  <span className="menu-text">Reconcile</span>
                </NavLink>
              </li>

              {/* PROVIDERS */}
              {/* begin::section */}
              <li className="menu-section">
                <h4 className="menu-text text-white-alpha-90 text-base">Catalogs</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}

              <li
                className={`menu-item ${getMenuItemActive("/get-providers", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-providers">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")} />
                  </span>
                  <span className="menu-text">Providers list</span>
                </NavLink>
              </li>

              {/*end::1 Level*/}

              {/* Categories */}
              {/* begin::section */}
              {/* <li className="menu-section ">
                <h4 className="menu-text text-white-alpha-90 text-base"> Categories</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li> */}
              {/* end:: section */}

              <li
                className={`menu-item ${getMenuItemActive("/get-categories", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-categories">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Exchange.svg")} />
                  </span>
                  <span className="menu-text">Categories list</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/get-subcategories", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/get-subcategories">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Exchange.svg")} />
                  </span>
                  <span className="menu-text">Subcategories list</span>
                </NavLink>
              </li>

              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
