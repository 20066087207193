import React from 'react';
import { useSubheader } from "../../_metronic/_core";
import ExpensesAnalytics from '../moduleExpenses/Analytics/Dashboard';

export const ExpensesAnalyticsPage = () => {
    const subheader = useSubheader();
    subheader.setTitle('Expenses Analytics')

    return (
        <>
            <ExpensesAnalytics />
        </>
    )
}