import React, {Component} from 'react'
import axios from 'axios'
import ServerURL from '../../../ServerURL'
/*import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';*/

 
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import { TabView, TabPanel } from 'primereact/tabview';
import { Dropdown } from 'primereact/dropdown';

import 'primeflex/primeflex.css';

import { connect } from "react-redux";
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";

import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { Redirect } from 'react-router-dom'
import { ToggleButton } from 'primereact';

const url = ServerURL();

class InvoicesTable extends Component {
    
  emptyInvoice = {
    invoiceId:null,
    invoiceTypeId:null,
    invoiceType:null,
    invoiceStatusId:null,
    invoiceStatus:null,
    note:null,
    currencyId:null,
    currencyCode:null,
    currencySymbol:null,
    currencyOneUsd:null,
    totalAmount:null,
    studentId:null,
    student:null,
    studentEmail:null,
    enterpriseId:null,
    enterprise:null,
    enterpriseUrl:null,
    agencyId:null,
    agency:null,
    agencyEmail:null,
    agentId:null,
    agent:null,
    agentEmail:null,
    invoice_options: null,
    created_at:null,
    updated_at:null,     
}



constructor(props) {
  super(props)

  this.columns = [
    {field: 'externalId', header: "Id" },
    // {field: 'created_at', header: 'Created at'},
    // {field: 'agency', header: 'Office'},
    // {field: 'agent', header: 'Agent'},
    // {field: 'provider', header: 'Provider'}
    // {field: 'student', header: 'Student'},
    // {field: 'symbolAmount', header: 'Total'},
    // {field: 'statusBalance', header: 'Validate' },
    // {field: <Column body={this.actionBodyTemplate}></Column>}
    // {field: 'agent', header: 'Agent'},
  ]

  this.invoiceOptions = [
    {name: 'All invoices', id: 0},
    {name: 'Fully paid', id: 1},
    {name: 'Validated not paid invoices', id: 2},
    {name: 'Validated paid invoices', id: 3},
    {name: 'Not validated invoices', id: 4},

  ]


  this.state = { 
      
    invoice: this.emptyInvoice,
    invoiceItem:this.emptyInvoiceItem,

    invoices:null,
    invoiceItems:null,

    
    globalFilter: null,
    selectedInvoices: null,
    selectedColumns: this.columns,
    invoiceDialog: false,
    invoiceDialogItem: false,
    deleteInvoiceDialog: false,
    deleteInvoicesDialog: false,
    deleteInvoiceItemDialog: false,
    disableInvoicesDialog: false,
    enableInvoicesDialog: false, 
    disableInvoiceDialog:false,
    enableInvoiceDialog:false,
    assignInvoicesDialog: false,
    CheckedIsAgencyItem:false,
    selectedCurrency: null, 
    resetCurrency:null, 

    redirect: false,
    activeIndex:0,

    selectedInvoiceOptions: null,
    resetOptions: null,

    symbolAmountFrozen: false,




    submitted: false,
    campusText: getTextCampusTranslate(),
    paginatorText: getTextPaginatorTranslate(),
    textTraslate: getGeneralTextTranslate()
  }
 
  this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
  this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
  //this.saveInvoice = this.saveInvoice.bind(this);


  this.disableSelectedInvoices = this.disableSelectedInvoices.bind(this);
  this.enableSelectedInvoices = this.enableSelectedInvoices.bind(this);
  this.deleteInvoice = this.deleteInvoice.bind(this);
  this.disableInvoice = this.disableInvoice.bind(this);
  this.enableInvoice = this.enableInvoice.bind(this);
  this.openNew = this.openNew.bind(this);
  this.exportCSV = this.exportCSV.bind(this);
  this.confirmDeleteInvoice= this.confirmDeleteInvoice.bind(this);
  this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
  this.deleteSelectedInvoices = this.deleteSelectedInvoices.bind(this);
  // this.eraseSelectedInvoices = this.eraseSelectedInvoices.bind(this);
  this.hideDeleteInvoiceDialog = this.hideDeleteInvoiceDialog.bind(this);
  this.hideDeleteInvoicesDialog = this.hideDeleteInvoicesDialog.bind(this);
  this.hideDeleteInvoiceItemDialog = this.hideDeleteInvoiceItemDialog.bind(this);
  this.hideDisableInvoicesDialog = this.hideDisableInvoicesDialog.bind(this);
  this.hideEnableInvoicesDialog  = this.hideEnableInvoicesDialog.bind(this);
  this.hideDisableInvoiceDialog = this.hideDisableInvoiceDialog.bind(this);
  this.hideEnableInvoiceDialog = this.hideEnableInvoiceDialog.bind(this);

  this.statusBodyTemplate = this.statusBodyTemplate.bind(this);

  this.confirmDisableInvoice = this.confirmDisableInvoice.bind(this);
  this.confirmEnableInvoice = this.confirmEnableInvoice.bind(this);
  this.confirmDisableSelected = this.confirmDisableSelected.bind(this);
  this.confirmEnableSelected = this.confirmEnableSelected.bind(this);

  this.onInvoiceOptions = this.onInvoiceOptions.bind(this)

  this.onColumnToggle = this.onColumnToggle.bind(this)

  this.symbolAmountTemplate = this.symbolAmountTemplate.bind(this)

  }
  
      componentDidMount() {
        this.getInvoices()
      }

      symbolAmountTemplate(rowData) {
        return (
          <span className='font-bold'>
            {rowData.totalAmount}
          </span>
        )
      }

      getInvoices = async () =>{
          try {
              await axios.get(`${url}/api/auth/invoices`)
              .then(res => {
                const invoices = res.data;
                this.symbolAmount(invoices)
                this.symbolAmountAgency(invoices)
                this.symbolAmountTemplate(invoices)
                this.statusBalance(invoices)
                this.showProvider(invoices)
                this.DateInvoice(invoices)
                this.setState({ invoices })

                console.log(res.data);
              });                     
                // console.log(res.data)
              }catch(error){

         }
      }

      getInvoiceValidated = async () => {
        try {
          await axios.get(`${url}/api/auth/invoicesValidated`)
          .then(res => {
            const invoices = res.data;
            this.symbolAmount(invoices)
            this.symbolAmountAgency(invoices)
            this.statusBalance(invoices)
            this.DateInvoice(invoices)
            this.setState({ invoices })

            console.log((res.data));
          })
        } catch (error) {
          console.log("ERROR: ", error);
        }
      }

      getInvoiceNotValidated = async () => {
        try {
          await axios.get(`${url}/api/auth/invoicesNotValidated`)
          .then(res => {
            const invoices = res.data
            this.symbolAmount(invoices)
            this.symbolAmountAgency(invoices)
            this.statusBalance(invoices)
            this.DateInvoice(invoices)

            this.setState({ invoices })

            console.log(res.data);
          })
        } catch (error) {
          console.log("ERROR: ", error);
        }
      }

      getDismissedInvoices = async () =>{
        try {
           
            let res = await axios.get(`${url}/api/auth/dismissedInvoices`)
            .then(res => {
              const invoices = res.data;
              this.symbolAmount(invoices)
              this.symbolAmountAgency(invoices)
              this.statusBalance(invoices)
              this.DateInvoice(invoices)
              this.setState({ invoices })
            });                     
              console.log(res.data)
            }catch(error){

       }
    }

    getInvoicesFullyPaid = async () =>{
      try {
          let res = await axios.get(`${url}/api/auth/invoicesFullyPaid`)
          .then(res => {
            const invoices = res.data;
            this.symbolAmount(invoices)
            this.symbolAmountAgency(invoices)
            this.statusBalance(invoices)
            this.DateInvoice(invoices)
            this.setState({ invoices })

            console.log(res.data)
          });                     
            console.log(res)
          }catch(error){
      }
    }

    getInvoicesValidatedPaidInvoices = async () =>{
      try {
          let res = await axios.get(`${url}/api/auth/invoicesValidatedPaidInvoices`)
          .then(res => {
            const invoices = res.data;
            this.symbolAmount(invoices)
            this.symbolAmountAgency(invoices)
            this.statusBalance(invoices)
            this.DateInvoice(invoices)
            this.setState({ invoices })

            console.log(res.data)
          });                     
            console.log(res)
          }catch(error){

        }
    }

      getCurrencies = async () =>{
        try {
           
            let res = axios.get(`${url}/api/auth/currencies`)
            .then(res => {
              const currencies = res.data;
              this.setState({ currencies})
            });                     
              console.log(res.data)
            }catch(error){
        }
      }


      statusBalance(e){             
        e.map((invoice) => {

        if(invoice.validateInvoice===0){
        invoice.statusBalanceString="Not validated"
        }else {
        invoice.statusBalanceString="Validate"
        }

        return invoice
       });
      }


   
      symbolAmount(e){             
        let dollarUSLocale = Intl.NumberFormat('en-US');
        e.map((invoice) => {
        //console.log("E:",agreement.updated_at)

        invoice.symbolAmount=invoice.symbol+' '+dollarUSLocale.format(invoice.totalInvoice)+' '+invoice.code;
        
         return invoice

       });
      }

      showProvider(e) {
        let provider
        e.map((invoice) => {
          if (typeof invoice.provider == 'string') {
            provider = invoice.provider.slice(0, 20)

            // for ( let i in provider){
              // console.log("PROVIDER: ", i);

              if( provider.length >= 19) {
                // console.log("PROVIDER: ", provider );

                provider += '...'

              }

              invoice.provider = provider 
            // }

          }

          return invoice
        })
        
      }

      symbolAmountAgency(e){
        let dollarUSLocale = Intl.NumberFormat('en-US');
             
        e.map((invoice) => {
        //console.log("E:",agreement.updated_at)

        invoice.symbolAmountAgency=invoice.symbolA+' '+dollarUSLocale.format(invoice.totalAmountAgency)+' '+invoice.codeA;
        
         return invoice

       });
      }

      DateInvoice(e){

        e.map((invoice) => {
          //console.log("E:",agreement.updated_at)

          const date = new Date(invoice.created_at);
          

          let year = date.getFullYear();
          let month = date.getMonth()+1;
          let dt = date.getDate();

          if (dt < 10) {
            dt = '0' + dt;
          }
          if (month < 10) {
            month = '0' + month;
          }

          let new_date = dt +'/' + month + '/'+year
          //console.log("New Data:",new_date)

          invoice.created_at= new_date

          return invoice

        });

      }
      

      Date(e){
        
           let year = e.getFullYear();
           let month = e.getMonth()+1;
           let day = e.getDate();

           if (day < 10) {
             day = '0' + day;
           }
           if (month < 10) {
             month = '0' + month;
           }
        
           let new_date= day +'/' + month + '/'+year

           return new_date

      }

    

      async deleteInvoice(){
        let data = {...this.state.invoice};

        console.log("DELETE INVOICE: ", data);

        try {
        await axios.delete(`${url}/api/auth/invoice/${data.externalId}`)
          .then(res => {
            console.log(res)
            this.setState({
              data,
              deleteInvoiceDialog: false,
              // invoice: this.emptyInvoice
          });

          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice Deleted', life: 3000 });
        })

        // console.log("RES: ", res);
        // if (!res.error) {
        //   this.setState({
        //     data,
        //     deleteInvoiceDialog: false,
        //     // invoice: this.emptyInvoice
        // });
        // }
          }catch(error){
            console.log("ERROR: ", error);
          } 

          this.getDismissedInvoices()
      }    
      
      async disableInvoice(){
        let data = {...this.state.invoice};

        try {
        await axios.put(`${url}/api/auth/dismissInvoice/${data.id}`)
          .then(res => {
            console.log(res)
            this.setState({
              data,
              disableInvoiceDialog: false,
              enableInvoice:1
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice Dismissed', life: 3000 });
        })
     
          }catch(error){
          } 
          this.getInvoices()

      }     

      async enableInvoice(){
        let data = {...this.state.invoice};

        try {
        await axios.put(`${url}/api/auth/enableInvoice/${data.id}`)
          .then(res => {
            console.log(res)
            this.setState({
              data,
              enableInvoiceDialog: false,
              enableInvoice:0,
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoice Enabled', life: 3000 });
        })
     
          }catch(error){
          } 
          this.getDismissedInvoices()
      }     


      disableSelectedInvoices() {
        let invoices = this.state.invoices.filter(val => !this.state.selectedInvoices.includes(val));
        let data = this.state.invoices.filter(val => this.state.selectedInvoices.includes(val));
  
        try {
        axios.put(`${url}/api/auth/dismissInvoices`,{data})
          .then(res => {
            this.setState({
              invoices,
              disableInvoicesDialog: false,
              selectedInvoices: null,
              enableInvoice:1
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoices Dismissed', life: 3000 });
          
        })
        
          }catch(error){
          } 
          this.getInvoices()
      }
  
      enableSelectedInvoices() {
        let invoices = this.state.invoices.filter(val => !this.state.selectedInvoices.includes(val));
        let data = this.state.invoices.filter(val => this.state.selectedInvoices.includes(val));
  
        try {
        axios.put(`${url}/api/auth/enableInvoices`,{data})
          .then(res => {
            this.setState({
              invoices,
              enableInvoicesDialog: false,
              selectedInvoices: null,
              enableInvoice:0
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoices Enabled', life: 3000 });
          
        })
        
          }catch(error){
          } 
          this.getDismissedInvoices()
  
      }
    

      
      openNew() {
        this.setState({
            invoice: this.emptyInvoice,
            submitted: false,
            invoiceDialog: true,       
        })
      }  

      async deleteInvoiceItem(id){
        let data = {...this.state.invoiceItem};

        try {
        await axios.delete(`${url}/api/auth/invoiceItem/${id}`)
          .then(res => {
          //this.getUsers()
        })
        //if (!res.error) {
          this.setState({
            data,
            deleteInvoiceItemDialog: false,
            invoiceItem: this.emptyInvoiceItem,

        });
        //console.log(res)
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'InvoiceItem Deleted', life: 3000 });
        console.log("Delete:",this.state.deleteInvoiceItemDialog)

        //}
          }catch(error){
          } 

          
      }    

      confirmDeleteInvoice(invoice) {
        this.setState({
            invoice,
            deleteInvoiceDialog: true
        })
      }

      confirmDisableInvoice(invoice) {
        this.setState({
          invoice,
          disableInvoiceDialog: true
      })
    }

    confirmEnableInvoice(invoice) {
      this.setState({
          invoice,
          enableInvoiceDialog: true
      })
    }


      confirmDeleteSelected() {
        this.setState({ deleteInvoicesDialog: true })
      }

      confirmDisableSelected() {
        this.setState({ disableInvoicesDialog: true })
      }
      confirmEnableSelected() {
        this.setState({ enableInvoicesDialog: true })
      }

     

      deleteSelectedInvoices() {
        let invoices = this.state.invoices.filter(val => !this.state.selectedInvoices.includes(val));
        let data = this.state.invoices.filter(val => this.state.selectedInvoices.includes(val));

        try {
        
        let res = axios.delete(`${url}/api/auth/invoices`, {data})
          .then(res => {
            console.log("RES: ", res);
          this.getInvoices()
        })
        if (!res.error) {
          this.setState({
            invoices,
            deleteInvoicesDialog: false,
            selectedInvoices: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoices dismissed', life: 3000 });
        }
          }catch(error){
          } 
      }

      // eraseSelectedInvoices() {
      //   let invoices = this.state.invoices.filter(val => !this.state.selectedInvoices.includes(val))
      //   let data = this.state.invoices.filter(val => this.state.selectedInvoices.includes(val))

      //   try {
      //     let res = axios.delete(`${url}/api/auth/eraseInvoices`, {data})
      //     .then(res => {
      //       this.getInvoices()
      //     })

      //     if(!res.error) {
      //       this.setState({
      //         invoices,
      //         eraseInvoicesDialog: false,
      //         selectedInvoices: null
      //       });

      //       this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Invoices deleted', life: 300})
      //     }
      //   } catch (error) {
      //     console.log("Error: ", error);
      //   }
      // }

      hideDeleteInvoiceDialog() {
        this.setState({ deleteInvoiceDialog: false })
      }
        
      hideDeleteInvoicesDialog() {
        this.setState({ deleteInvoicesDialog: false });
    }

           
        exportCSV() {
          this.dt.exportCSV();
      }

      setRedirect = (invoice) => {
        this.props.getInvoiceId(invoice.invoiceId)

        this.setState({
          redirect: true
        })
      }
      renderRedirect = () => {

        if (this.state.redirect) {
          return <Redirect to='/get-view-options' />
        }
      }   

      actionBodyTemplate(rowData) {
        console.log("ROWDATA: ", rowData.dismiss);

        let bodyView = []

        if(rowData.dismiss === 0) {
          bodyView.push(
            <>
              {/* {this.renderRedirect(rowData)}
              <Button tooltip='Edit invoice' tooltipOptions={{ position: 'top'}} icon="pi pi-pencil px-2" className='p-0 p-button-rounded p-button-outlined'  color='primary' style={{verticalAlign: 'middle', marginLeft:'5px', width: "30px", height: "30px",}}  onClick={() => this.setRedirect(rowData)} /> */}
              {this.buttonED(rowData)}
            </>
          )
        } else {
          bodyView.push(
            <>
              {this.buttonED(rowData)}
            </>
          )
        }

        return bodyView
      }

      statusBodyTemplate(rowData) {
        let showTag=[]

        if(rowData.validateInvoice===0){
          // rowData.validateInvoice = 'Not Validate'
          showTag.push(
            <Button 
              tooltip='Not validated' 
              tooltipOptions={{ showOnDisabled: true, position: 'top'}}
              icon='pi pi-exclamation-triangle px-10' 
              className="p-button-rounded p-button-outlined p-button-warning" 
              disabled
              // border='10px'
              style={{border: '1.8px solid', verticalAlign: 'middle', width: '30px', height:'30px', color:'#FF9700'}}>
            </Button>
            
          )
        }else {
          // rowData.validateInvoice = 'Validate'

          showTag.push(
            <Button
              tooltip='Validate'
              tooltipOptions={{ position: 'top', showOnDisabled: true }}
              icon='pi pi-check px-10'
              className='p-button-rounded p-button-outlined'
              disabled
              style={{ border: '1.8px solid #51FF00', verticalAlign: 'middle', width: '30px', height: '30px', color: '#51FF00'}}
            ></Button>
          )
        }

        return showTag
      }

      fileGrossBodyTemplate (rowData) {
        let UploadAndShow=[]

        if(rowData.invoice_gross_file_url){
    
          UploadAndShow.push(
            <>    
              <div style={{ marginLeft:10}}>
                <a href={`${rowData.invoice_gross_file_url}`} target="_blank"rel="noopener noreferrer"><Button type="button" icon="pi pi-download" className='p-button-success p-button-rounded p-button-outlined' ></Button></a>
              </div>
            </>
          )
          return UploadAndShow
    
        }
      }

      fileNetBodyTemplate (rowData) {
        let UploadAndShow=[]

        if(rowData.invoice_net_file_url){
    
          UploadAndShow.push(
            <>    
              <div style={{ marginLeft:10}}>
                <a href={`${rowData.invoice_net_file_url}`} target="_blank"rel="noopener noreferrer"><Button type="button" icon="pi pi-download" className='p-button-success p-button-rounded p-button-outlined' ></Button></a>
              </div>
            </>
          )
          return UploadAndShow
    
        }
      }



      buttonED=(rowData)=>{

        let EDView=[]
    
          if( rowData.dismiss===0){
            EDView.push(
              <Button 
              tooltip='Dismiss invoice' 
              tooltipOptions={{ position: 'top'}} 
              icon="pi pi-times" 
              className="p-button-rounded p-button-danger p-button-outlined" 
              style={{verticalAlign: 'middle', marginLeft:'5px',  width: "30px", height: "30px",}} 
              onClick={() => this.confirmDisableInvoice(rowData)} />
              )
            }else{
             EDView.push(
              <>
                <Button tooltip='Enable invoice' tooltipOptions={{ position: 'top'}} icon="pi pi-check"   className="p-button-rounded p-button-outlined" style={{border: '1.7px solid #51FF00', color: '#51FF00', verticalAlign: 'middle', width: "30px", height: "30px",}} onClick={() => this.confirmEnableInvoice(rowData)} />
                <Button tooltip='Delete invoice' tooltipOptions={{ position: 'top'}} icon="pi pi-trash"   className="p-button-rounded p-button-danger p-button-outlined" style={{verticalAlign: 'middle', marginLeft:'5px', width: "30px", height: "30px",}} onClick={() => this.confirmDeleteInvoice(rowData)} />
              </>
             )
             }
    
             return EDView
      }

      

      

      toolbarButtonED=()=>{
        let EDView=[]
    
            if(this.state.activeIndex===0){
            EDView.push(
                          // <FormattedMessage id="GENERAL.DISMISS">
                          //   {(message) => 
                          //     <Button tooltip='Dismiss' tooltipOptions={{ position: 'bottom'}} icon="pi pi-times" label={message}  className="p-button-danger" onClick={this.confirmDisableSelected} disabled={!this.state.selectedInvoices || !this.state.selectedInvoices.length}/>
                          //   }
                          // </FormattedMessage>
                          <>
                            <Button tooltip='Dismiss' tooltipOptions={{ position: 'bottom'}} icon="pi pi-times" className="p-button-danger" onClick={this.confirmDisableSelected} disabled={!this.state.selectedInvoices || !this.state.selectedInvoices.length}/>
                          </>
    
                          )
            }else if(this.state.activeIndex===1){
              EDView.push(
                // <FormattedMessage id="GENERAL.ENABLE">
                //   {(message) => 
                //   <Button label={message} icon="pi pi-check" className="p-button-danger" onClick={this.confirmEnableSelected} disabled={!this.state.selectedInvoices || !this.state.selectedInvoices.length} />
                // }</FormattedMessage>

                <>
                  <Button tooltip='Enable' tooltipOptions={{ position: 'bottom'}} icon="pi pi-check" className="p-button-success" onClick={this.confirmEnableSelected} disabled={!this.state.selectedInvoices || !this.state.selectedInvoices.length} />
                  <Button tooltip='Delete' style={{ marginLeft: '10px'}} tooltipOptions={{ position: 'bottom'}} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedInvoices || !this.state.selectedInvoices.length} />
                </>
              )
            }
            return EDView
      }

      
      onColumnToggle(event) {
        let selectedColumns = event.value
        let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some( sCol => sCol.field === col.field));

        // console.log("SELECTED COLUMNS: ", selectedColumns);
          // orderedSelectedColumns = this.columns.filter(col => selectedColumns.some( sCol => sCol.field === col.field));
          // this.columns.filter(col => selectedColumns.some( sCol => sCol.field === col.field));
          console.log("ORDERED COLUMNS: ", orderedSelectedColumns);

          this.setState({ selectedColumns: orderedSelectedColumns})
      }
    

     
      
      hideDeleteInvoiceItemDialog() {
        this.setState({ deleteInvoiceItemDialog: false })
      }

      hideDisableInvoicesDialog() {
        this.setState({ disableInvoicesDialog: false });
      }
  
      hideEnableInvoicesDialog() {
        this.setState({ enableInvoicesDialog: false })
      }

      hideDisableInvoiceDialog() {
        this.setState({ disableInvoiceDialog: false })
      }

      hideEnableInvoiceDialog() {
        this.setState({ enableInvoiceDialog: false })
      }


     /* formatCurrency = (value) => {
        return value.toLocaleString('en-US', {mode:"currency", maxFractionDigits:2, minFractionDigits:2});
      }  */

      onIndexChange(e) {    

        if(e===0){
          this.getInvoices()
        }
        else if(e===1){
          this.getDismissedInvoices()
        }

        // else if(e===2){
        //   this.getInvoicesFullyPaid()
        // }
        // else if(e===3) {
        //   this.getInvoiceValidated()
        // } 
        // else if ( e===4 ) {
        //   this.getInvoiceNotValidated()
        // }

        this.setState({invoices:null, activeIndex:e});
      }

      onInvoiceOptions (e) {
        console.log("E: ", e);
        // const invoice = {...this.state.invoice}

        // invoice[`invoice_options`] = e.value

        if ( e.target.value === 0) {
          this.getInvoices()
        } else if ( e.value === 1) {
          this.getInvoicesFullyPaid()
        } else if ( e.value === 2) {
          this.getInvoiceValidated()
        } else if ( e.value === 3) {
          this.getInvoicesValidatedPaidInvoices()
        }
        else if ( e.value === 4) {
          this.getInvoiceNotValidated()
        }

        this.setState( {selectedOption: e.value})
      }


      tableInvoices(){
        let showInvoices=[]
        
        const header = (
          <div style={{ textAlign: 'left'}}>
            <MultiSelect
              value={this.state.selectedColumns}
              placeholder='Fields to show...'
              display='chip' 
              options={this.columns} 
              optionLabel='header'
              onChange={this.onColumnToggle}
            />

            <Dropdown 
              style={{ width: 190, height:33, marginLeft: 10 }}
              variant="outlined"
              placeholder='Select invoice options...'
              margin="dense"
              value= { this.state.selectedOptions} 
              options={this.invoiceOptions} 
              onChange={this.onInvoiceOptions}  
              optionLabel="name" 
              optionValue="id"
              />
            {/* <ToggleButton 
              checked={this.state.symbolAmountFrozen} 
              onChange={(e) => this.setState({ symbolAmountFrozen: e.value })} 
              onIcon="pi pi-lock" 
              offIcon='pi pi-lock-open' 
              onLabel='Unfreeze balance' 
              offLabel='Freeze balance' 
              style={{ flexGrow: 1, flexBasis: '12rem', marginLeft: '10px'}}
            /> */}
          </div>
        )

        // const columnComponents = this.state.selectedColumns.map( col =>{
        //   return (
        //     <>
        //       <Column sortable filter field={col.field} header={col.header} />
        //       {dynamicColumns}
        //     </>
            
        //   )
        // })

        const dynamicColumns = this.state.selectedColumns.map( (col, i) => {
          return <Column reorderable sortable filter key={col.field} columnKey={col.field} field={col.field} header={col.header} headerStyle={{width: 'flex'}} />;

          // (
            // <>
              // {columnComponents}

            // </>
          // )
      });

          showInvoices.push(
            <>
           {/*SHOW INVOICE */}
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
              <DataTable 
                ef={(el) => this.dt = el} value={this.state.invoices} 
                paginator 
                paginatorTemplate="InvoicePageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport"
                resizableColumns 
                columnResizeMode='fit' 
                responsiveLayout='stack'
                header={header} 
                reorderableColumns
                stripedRows
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
                rows={10} 
                rowsPerPageOptions={[10,20,50]} ref={(el) => { this.dt = el; }}
                globalFilter={this.state.globalFilter} 
                emptyMessage={<FormattedMessage id="ACCOUNTING_INVOICE.NO_DATA"/>} 
                loading={this.state.loading}
                selection={this.state.selectedInvoices} 
                onSelectionChange={e => this.setState({ selectedInvoices: e.value })} 
                dataKey="id"
                >
                  <Column selectionMode="multiple"></Column>
                  {dynamicColumns}
                  {/* {columnComponents} */}
                  {/* <Column field="externalId" header="External ID" sortable  headerStyle={{width: 'flex'}} filter filterMatchMode="contains"></Column> */}
                  <Column field="created_at" header="Created at" sortable  headerStyle={{width: 'flex'}} filter filterMatchMode="contains"></Column>
                  {/* <Column field="invoiceId" header={this.state.campusText.InvoiceId} sortable  headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column> */}
                  {/* <Column field="agency" header={this.state.campusText.Agency} sortable  headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office}></Column> */}
                  {/* <Column field="agent" header={this.state.campusText.Agent} sortable  headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Agent}></Column> */}
                  <Column field="student" header={this.state.campusText.Student} sortable  headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Student}></Column>
                  {/* <Column field="provider" header="Provider" sortable  headerStyle={{width: 'flex'}} filter filterPlaceholder="Provider"></Column> */}
                  {/* <Column field="symbolAmount" header={this.state.campusText.ProgramTotal} sortable  headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.ProgramTotal}></Column> */}
                  {/* <Column field="symbolAmountAgency" header={this.state.campusText.AgencyTotal} sortable  headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.AgencyTotal} filterMatchMode="contains"></Column> */}
                  {/* <Column className='text-center' field='invoice_gross_file_url' columnKey='invoice_gross_file_url' body={this.fileGrossBodyTemplate} header='Gross' headerStyle={{ width: 'flex'}} sortable />
                  <Column className='text-center' field='invoice_net_file_url' columnKey='invoice_net_file_url' body={this.fileNetBodyTemplate} header='Net' headerStyle={{ width: 'flex'}} sortable />
                  <Column className='text-center' header="Validated" field='statusBalanceString' sortable columnKey='statusBalanceString' body={this.statusBodyTemplate}></Column> */}
                  <Column columnKey header='Actions' body={this.actionBodyTemplate}></Column>
                </DataTable>
              </div>
            </>
          )
          return showInvoices
      }
  

      render(){
       const deleteInvoiceDialogFooter = (
        <>
            <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteInvoiceDialog} />
            <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteInvoice} />
        </>
       );

       
       const deleteInvoicesDialogFooter = (
        <>
            <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteInvoicesDialog} />
            <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedInvoices} />
        </> 
    ); 

    const deleteInvoiceItemDialogFooter = (
      <>
          <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeleteInvoiceItemDialog} />
          <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteInvoiceItem} />
      </>
     );

     const disableInvoiceDialogFooter = (
      <>
          <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDisableInvoiceDialog} />
          <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.disableInvoice} />
      </>
     );

     const enableInvoiceDialogFooter = (
      <>
          <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableInvoiceDialog} />
          <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.enableInvoice} />
      </>
     );

     const disableInvoicesDialogFooter = (
      <>
          <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDisableInvoicesDialog} />
          <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.disableSelectedInvoices} />
      </> 
      ); 

      const enableInvoicesDialogFooter = (
        <>
            <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideEnableInvoicesDialog} />
            <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.enableSelectedInvoices} />
        </> 
      ); 


      return(       
        <div>
          <div className= "card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />  
    			<h3 className="card-label" >
            <FormattedMessage id="ACCOUNTING_INVOICE.TITLE"/>
				    <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ACCOUNTING_INVOICE.DESCRIPTION"/></span>
			    </h3>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'  }}>  
             {/*TOOL BAR */} 


                    <div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.EXPORT">
                        {(message) => 
                          <p>
                            <Tooltip target=".export-button" position='bottom' />
                            <Button
                            className='export-button'
                              type="button" 
                              icon="pi pi-external-link" 
                              onClick={this.exportCSV}
                              data-pr-tooltip="Export file"
                            />


                          </p>
                        }
                      </FormattedMessage>
                    </div>

                    {/*<div style={{ marginRight: 10}}>
                      <FormattedMessage id="GENERAL.DELETE">
                        {(message) => <Button label={message} icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedInvoices || !this.state.selectedInvoices.length} />}
                      </FormattedMessage>
                    </div>*/}

                    <div style={{ marginRight: 10}}>
                      {this.toolbarButtonED()}
                    </div>

                    <div style={{ marginRight: 10}}>
                    <p> <span className="p-input-icon-left">
                    <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
                    </span></p>
                    </div>  
                    
            </div>
          </div>


          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onIndexChange(e.index )} >
            <TabPanel header={this.state.campusText.Invoices}>
              <div>
                    {/*SHOW INVOICE */}
                    {/* <Tooltip target=".export-buttons>button" position='bottom' /> */}
                    {this.tableInvoices()}
              </div>
            </TabPanel>

            {/*SHOW CANCELLED INVOICE */}
            {/* <TabPanel header={this.state.campusText.InvoicesFullyPaid}>
                <div>
                    {this.tableInvoices()}
                </div>
            </TabPanel> */}

            {/* SHOW VALIDATED INVOICES */}
            {/* <TabPanel header="Invoices validated">
              <div>
                {this.tableInvoices()}
              </div>
            </TabPanel> */}

            {/* SHOW NOT VALIDATED INVOICES */}
            {/* <TabPanel header="Invoices not validated">
              {this.tableInvoices()}
            </TabPanel> */}

            {/*SHOW CANCELLED INVOICE */}
            <TabPanel header={this.state.campusText.DismissedInvoices}>
                <div>
                    {this.tableInvoices()}
                </div>
            </TabPanel>
          </TabView>

          <div> 
            {/*DELETE INVOICE */}
            <Dialog visible={this.state.deleteInvoiceDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={deleteInvoiceDialogFooter} onHide={this.hideDeleteInvoiceDialog}>
                      <div className="confirmation-content">
                          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                          {this.state.invoice && <span> <FormattedMessage id="ACCOUNTING_INVOICE.DIALOG_DELETE"/></span>}
                      </div>
            </Dialog>
          </div>

          {/*DELETE INVOICE ITEM */}
          <Dialog visible={this.state.deleteInvoiceItemDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={deleteInvoiceItemDialogFooter} onHide={this.hideDeleteInvoiceItemDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.invoiceItem && <span> <FormattedMessage id="ACCOUNTING_INVOICE_ITEM.DIALOG_DELETE"/><b>{this.state.invoiceItem.program}</b>?</span>}
                    </div>
          </Dialog>

          {/*DELETE WITH TOOL BAR*/}
          <Dialog visible={this.state.deleteInvoicesDialog} style={{ width: '450px' }} header={this.state.campusText.Balance} modal footer={deleteInvoicesDialogFooter} onHide={this.hideDeleteInvoicesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.invoice && <span><FormattedMessage id="ACCOUNTING_INVOICE_DELETE_ALL"/></span>}
                    </div>
          </Dialog>

          <div> 
         {/*DISABLE INVOICE */}
          <Dialog visible={this.state.disableInvoiceDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={disableInvoiceDialogFooter} onHide={this.hideDisableInvoiceDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {<span> <FormattedMessage id="ACCOUNTING_INVOICE.DIALOG_DISABLE"/></span>}
                    </div>
          </Dialog>
          </div>

          <div> 
         {/*ENABLE INVOICE*/}
          <Dialog visible={this.state.enableInvoiceDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={enableInvoiceDialogFooter} onHide={this.hideEnableInvoiceDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {<span> <FormattedMessage id="ACCOUNTING_INVOICE.DIALOG_ENABLE"/></span>}
                    </div>
          </Dialog>
          </div>

          {/*DISABLE WITH TOOL BAR*/}
          <Dialog visible={this.state.disableInvoicesDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={disableInvoicesDialogFooter} onHide={this.hideDisableInvoicesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.invoice && <span><FormattedMessage id="ACCOUNTING_INVOICE.DIALOG_DISABLE_ALL"/></span>}
                    </div>
          </Dialog>


          {/*ENABLE WITH TOOL BAR*/}
          <Dialog visible={this.state.enableInvoicesDialog} style={{ width: '450px' }} header={this.state.campusText.Confirm} modal footer={enableInvoicesDialogFooter} onHide={this.hideEnableInvoicesDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem'}} />
                        {this.state.invoice && <span><FormattedMessage id="ACCOUNTING_INVOICE.DIALOG_ENABLE_ALL"/></span>}
                    </div>
          </Dialog>
         
            

       {/*} <Tooltip title={this.state.campusText.NewOrder} aria-label="add" onClick={this.openNew}>
        <Fab color="secondary">
          <AddIcon />
        </Fab>
       </Tooltip>*/}
        </div>  
      )
  }
}


const mapStateToProps = state => ({
  invoice: state.auth.invoiceId
});
export default connect(mapStateToProps,auth.actions)(InvoicesTable);






