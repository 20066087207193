import React from 'react'
import { useSubheader } from '../../_metronic/_core'
import SubCategoriesTable from '../moduleExpenses/Subcategories/SubCategoriesTable'

export const SubCategoriesPage = () => {
    const subheader = useSubheader();
    subheader.setTitle('Sub categories')

    return (
        <>
            <SubCategoriesTable />
        </>
    )
}