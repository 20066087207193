import React, { Component } from 'react'
import axios from 'axios'
import ServerURL from '../../../ServerURL'

import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
// import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';



import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { FileUpload } from 'primereact/fileupload';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputNumber } from 'primereact/inputnumber';

import { MultiSelect } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';
import { ToggleButton } from 'primereact/togglebutton';

import OpenExchangeURL from '../../../OpenExchangeURL'
import OpenExchangeAPI from '../../../OpenExchangeAPI'

import 'primeflex/primeflex.css';

import { connect } from "react-redux";
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";



import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { Redirect } from 'react-router-dom'




const url = ServerURL();
const urlOE = OpenExchangeURL();
const apiOE = OpenExchangeAPI();

class AdminPaymentsTable extends Component {

  emptyBalance = {
    invoiceId: '',
    itemId: '',
    total: '',
    fk_id_currency: '',
    item_name: '',
    code: '',
  }

  emptyAccount = {
    account_name: '',
    fk_id_currency: '',
    code: '',
    description: '',
  }



  emptyPayment = {
    invoiceId: '',
    fk_id_balance_item: [],
    rates: [],
    fk_id_account: '',
    amount: 0,
    payment_date: '',
    payment_date_show: '',
    fk_id_payment_method: '',
    payment_file_url: '',
    payment_file: null,
    note: '',
    status: ''
  }

  emptyApprovedPayment = {
    invoiceId: '',
    fk_id_balance_item: [],
    rates: [],
    fk_id_account: '',
    amount: 0,
    payment_date: '',
    payment_date_show: '',
    fk_id_payment_method: '',
    payment_file_url: '',
    payment_file: null,
    note: '',
    status: ''
  }




  constructor(props) {
    super(props)

    this.columns = [
      { field: 'externalId', header: "Invoice Id" },
      // {field: 'created_at', header: 'Created at'},
      { field: 'office', header: 'Office' },
      { field: 'agent', header: 'Agent' },
      { field: 'provider', header: 'Provider' }
      // {field: 'student', header: 'Student'},
      // {field: 'symbolAmount', header: 'Total'},
      // {field: 'statusBalance', header: 'Validate' },
      // {field: <Column body={this.actionBodyTemplate}></Column>}
      // {field: 'agent', header: 'Agent'},

    ]

    this.state = {

      payment: this.emptyPayment,
      payments: [],

      approvedPayment: this.emptyApprovedPayment,
      approvedPayments: [],

      // pendingPayments: null,

      balance: this.emptyBalance,
      balances: [],
      activeIndex: 0,


      globalFilter: null,
      selectedInvoices: null,
      paymentDialog: false,
      deletePaymentDialog: false,
      deletePaymentsDialog: false,
      approvePaymentsDialog: false,

      selectedAccount: null,
      resetAccount: null,
      selectedMethod: null,
      resetMethod: null,
      showCheckBox: false,
      CheckedApprove: false,

      selectedPayments: null,



      redirect: false,

      statusPayments: 1,
      edit: null,


      selectedColumns: this.columns,

      submitted: false,
      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate()
    }

    this.studentBalance = this.studentBalance.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.deletePayment = this.deletePayment.bind(this);
    this.openNew = this.openNew.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.confirmDeletePayment = this.confirmDeletePayment.bind(this);
    this.confirmDeleteSelected = this.confirmDeleteSelected.bind(this);
    this.confirmApproveSelected = this.confirmApproveSelected.bind(this);
    this.deleteSelectedPayments = this.deleteSelectedPayments.bind(this);
    this.approveSelectedPayments = this.approveSelectedPayments.bind(this);
    this.hideDeletePaymentDialog = this.hideDeletePaymentDialog.bind(this);
    this.hideDeletePaymentsDialog = this.hideDeletePaymentsDialog.bind(this);
    this.hideApprovePaymentsDialog = this.hideApprovePaymentsDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onApproveChange = this.onApproveChange.bind(this);
    this.onIndexChange = this.onIndexChange.bind(this);
    this.nameInCheck = this.nameInCheck.bind(this);
    this.readOnlyItem = this.readOnlyItem.bind(this);
    this.styleItem = this.styleItem.bind(this);





    this.Check = this.Check.bind(this);
    this.InputRate = this.InputRate.bind(this);
    this.onBalanceChange = this.onBalanceChange.bind(this);
    this.getExchangeRates = this.getExchangeRates.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
    this.onMethodChange = this.onMethodChange.bind(this);
    this.onRateChange = this.onRateChange.bind(this);
    this.savePayment = this.savePayment.bind(this);
    this.fileBodyTemplate = this.fileBodyTemplate.bind(this);
    this.onSort = this.onSort.bind(this);

    this.onColumnToggle = this.onColumnToggle.bind(this)
  }

  componentDidMount() {
    this.getPayments()
  }

  isValid() {
    const { fk_id_role_type, fk_id_country, enterprise_name, contact, email, phone, website } = this.state.enterprise
    const fk_id_role_typeMessage = this.validateRoleType()
    const fk_id_countryMessage = this.validateCountry()
    const enterprise_nameMessage = this.validateEnterpriseName()
    const contactMessage = this.validateContact()
    const emailMessage = this.validateEmail()
    const phoneMessage = this.validatePhone()
    const websiteMessage = this.validateWebsite()



    if (fk_id_role_type && fk_id_country && enterprise_name && contact && email && phone && website) {
      if (!fk_id_role_typeMessage && !fk_id_countryMessage && !enterprise_nameMessage && !contactMessage && !emailMessage && !phoneMessage && !websiteMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }




  getPayments = async () => {

    try {

      let res = axios.get(`${url}/api/auth/paymentsPending`)
        .then(res => {
          const payments = res.data;
          this.symbolPaymentsCurrency(payments)
          this.showProvider(payments)
          this.DatePayment(payments)

          this.setState({ payments, statusPayments: 1 })
        });
      console.log(res.data)
    } catch (error) {
    }
  }


  symbolPaymentsCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolAmountCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].amount) + ' ' + e[i].code;
    }
    return e
  }


  symbolTotalCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');

    for (let i = 0; i < length; i++) {
      e[i].symbolTotalCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].total) + ' ' + e[i].code;
    }

    return e
  }


  symbolBalanceCurrency(e) {
    let length = Object.keys(e).length
    let dollarUSLocale = Intl.NumberFormat('en-US');
    for (let i = 0; i < length; i++) {
      e[i].symbolBalanceCurrency = e[i].symbol + ' ' + dollarUSLocale.format(e[i].balance) + ' ' + e[i].code;
    }
    return e
  }

  symbolPaidCurrency(e) {
    let dollarUSLocale = Intl.NumberFormat('en-US');

    e.map((invoiceItems) => {

      invoiceItems.symbolPaidCurrency = invoiceItems.symbol + ' ' + dollarUSLocale.format(invoiceItems.paid) + ' ' + invoiceItems.code;

      return invoiceItems

    });
  }


  getPaymentsApproved = async () => {

    try {

      let res = axios.get(`${url}/api/auth/paymentsApproved`)
        .then(res => {
          const approvedPayments = res.data;
          this.symbolPaymentsCurrency(approvedPayments)
          this.showProvider(approvedPayments)
          this.DatePayment(approvedPayments)
          this.setState({ approvedPayments, statusPayments: 0 })
        });
      console.log(res.data)
    } catch (error) {
    }
  }

  getAccounts = async () => {

    try {

      let res = axios.get(`${url}/api/auth/accounts`)
        .then(res => {
          const accounts = res.data;
          this.setState({ accounts })
        });
      console.log(res.data)
    } catch (error) {
    }
  }


  getInvoices = async (payment) => {

    try {

      let res = axios.get(`${url}/api/auth/invoice/${payment.invoiceId}`)
        .then(res => {
          const invoices = res.data;
          this.symbolPaidCurrency(invoices)
          this.setState({ invoices })
        });
      console.log(res.data)
    } catch (error) {
    }
  }

  getInvoiceItems = async (payment) => {

    try {

      let res = axios.get(`${url}/api/auth/invoiceItems/${payment.invoiceId}`)

        .then(res => {
          const invoiceItems = res.data;
          this.setState({ invoiceItems })
        });
      console.log(res.data)
    } catch (error) {
    }
  }

  getBalances = async (payment) => {
    try {

      axios.get(`${url}/api/auth/balance/${payment.invoiceId}`)
        .then(res => {
          const balances = res.data;
          console.log("BALANCES EN STATE GET:", balances)

          this.symbolTotalCurrency(balances)
          this.symbolBalanceCurrency(balances)
          this.setState({ balances })
        });
    } catch (error) {
    }

  }





  getCurrencies = async () => {

    try {

      let res = axios.get(`${url}/api/auth/currencies`)
        .then(res => {
          const currencies = res.data;
          this.setState({ currencies })
        });
      console.log(res.data)
    } catch (error) {
    }
  }



  getMethods = async () => {

    try {

      let res = axios.get(`${url}/api/auth/methods`)
        .then(res => {
          const methods = res.data;
          this.setState({ methods })
        });
      console.log(res.data)

    } catch (error) {
    }
  }




  symbolAmount(e) {
    let dollarUSLocale = Intl.NumberFormat('en-US');
    e.map((invoice) => {

      //console.log("E:",agreement.updated_at)

      invoice.symbolAmount = invoice.symbol + ' ' + dollarUSLocale.format(invoice.totalAmount) + ' ' + invoice.code;

      return invoice

    });
  }

  symbolAmountAgency(e) {
    let dollarUSLocale = Intl.NumberFormat('en-US');
    e.map((invoice) => {
      //console.log("E:",agreement.updated_at)

      invoice.symbolAmountAgency = invoice.symbolA + ' ' + dollarUSLocale.format(invoice.totalAmountAgency) + ' ' + invoice.codeA;

      return invoice

    });
  }

  showProvider(e) {
    let provider
    e.map((payments) => {
      if (typeof payments.provider == 'string') {
        provider = payments.provider.slice(0, 20)

        // for ( let i in provider){
        // console.log("PROVIDER: ", i);

        if (provider.length >= 19) {
          // console.log("PROVIDER: ", provider );

          provider += '...'

        }

        payments.provider = provider
        // }

      }

      return payments
    })
  }

  DateInvoice(e) {

    e.map((invoice) => {
      //console.log("E:",agreement.updated_at)

      const date = new Date(invoice.created_at);


      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      let new_date = dt + '/' + month + '/' + year
      //console.log("New Data:",new_date)

      invoice.created_at = new_date

      return invoice

    });

  }


  DatePayment(e) {

    e.map((payment) => {

      var origin = payment.payment_date.split('/')
      var originDate = origin[1] + '/' + origin[0] + '/' + origin[2];

      var date = new Date(originDate).getTime();

      payment.payment_date_new = date

      return payment
    });
  }


  Date(e) {
    let payment = { ...this.state.payment }

    let year = e.getFullYear();
    let month = e.getMonth() + 1;
    let day = e.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = day + '/' + month + '/' + year

    payment.payment_date = new_date

    this.setState({ payment })

    return payment

  }



  async deletePayment() {
    let data = { ...this.state.payment };

    try {
      await axios.delete(`${url}/api/auth/payment/${data.id}`)
        .then(res => {
          console.log(res)
          this.setState({
            data,
            deletePaymentDialog: false,
            payment: this.emptyPayment
          });
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payment Deleted', life: 3000 });
        })

    } catch (error) {
    }
    if (data.status === 0) {
      this.onIndexChange(0)
      this.getPayments()
    }
    else if (data.status === 1) {
      this.onIndexChange(1)
      this.getPaymentsApproved()
    }

  }


  savePayment() {
    let state = { submitted: true };
    let invoice = {...this.state.invoices}

    //const decision = this.isValid();

    //if (this.state.agreement.fk_id_agency.trim()) {       
    let data = { ...this.state.payment, externalId: invoice[0].externalId };

    if (this.state.payment.id) {
      if (typeof data.payment_date === 'string') {
        console.log("PAYMENT DATE IS STRING");
        console.log(`PAYMENT DATE STRING: ${data.payment_date}`)
  
        let [day, month, year] = data.payment_date.split('/')
        data.payment_date= new Date(+year, +month - 1, +day)

        console.log(`PAYMENT RESULT: ${data.payment_date}`)
      }
      // let year = data.payment_date_show.getFullYear();
      // let month = data.payment_date_show.getMonth() + 1;
      // let day = data.payment_date_show.getDate();

      // if (day < 10) {
      //   day = '0' + day;
      // }
      // if (month < 10) {
      //   month = '0' + month;
      // }

      // let new_date = `${day}/${month}/${year}`

      // data.payment_date = new_date
    }

    console.log("DATA FINAL:", data)
    const formData = new FormData();

    const filePayment = this.uploadInput.getFiles()

    if (filePayment.length !== 0) {
      console.log('Entro condicion payments');
      formData.append('payment_file', filePayment[0]);
      // console.log(formData.append('payment_file',this.uploadInput.files[0]));
    }

    formData.append('invoiceId', data.invoiceId);
    formData.append('fk_id_balance_item', data.fk_id_balance_item);
    formData.append('rates', data.rates);
    formData.append('fk_id_account', data.fk_id_account);
    formData.append('amount', data.amount);
    formData.append('payment_date', data.payment_date);
    formData.append('fk_id_payment_method', data.fk_id_payment_method);
    formData.append('note', data.note);
    formData.append('status', data.status);

    const config = {
      headers: {

        'Content-Type': 'multipart/form-data'
      }
    }

    console.log(formData, config)

    if (this.state.payment.id) {
      try {
        let res = axios.put(`${url}/api/auth/payment/${data.id}`, formData, config)
          .then(res => {
            //console.log(res);
            if (data.status === 0) {
              this.onIndexChange(0)
              this.getPayments()
              console.log(res);
            }
            else if (data.status === 1) {
              this.onIndexChange(1)
              this.getPaymentsApproved()
              console.log(res);
            }
          })
        if (!res.error) {
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payment Updated', life: 3000 });

        }
      } catch (error) {
      }

    } else {

      try {

        let res = axios.post(`${url}/api/auth/payment`, formData, config)
          .then(res => {
            if (data.status === 0) {
              this.onIndexChange(1)
              this.getPayments()
            }
            else if (data.status === 1) {
              this.onIndexChange(0)
              this.getPaymentsApproved()
            }
          })
        if (!res.error) {
          this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payment Created', life: 3000 });
        }
      } catch (error) {
      }
    }
    state = {
      ...state,
      data,
      paymentDialog: false,
      payment: this.emptyPayment,
      selectedAcccount: null,
      resetAccount: null,
      selectedMethod: null,
      resetMethod: null,
    };


    this.setState(state);
  }





  confirmDeletePayment(payment) {
    this.setState({
      payment,
      deletePaymentDialog: true
    })
  }


  confirmDeleteSelected() {
    this.setState({ deletePaymentsDialog: true })
  }

  confirmApproveSelected() {
    this.setState({ approvePaymentsDialog: true })
  }


  deleteSelectedPayments() {
    try {
      let payments = this.state.payments.filter(val => !this.state.selectedPayments.includes(val));
      let data = this.state.payments.filter(val => this.state.selectedPayments.includes(val));
      console.log("delete payments:", data)

      try {
        axios.delete(`${url}/api/auth/payments`, { data })
          .then(res => {
            this.setState({
              payments,
              deletePaymentsDialog: false,
              selectedPayments: null
            });
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payments Deleted', life: 3000 });

          })

      } catch (error) {
      }
      if (data.status === 0) {
        this.onIndexChange(1)
        this.getPayments()
      }
      else if (data.status === 1) {
        this.onIndexChange(0)
        this.getPaymentsApproved()
      }
    } catch (error) {
      console.log("ERROR: ", error);
    }
  }

  approveSelectedPayments() {
    try {
      let payments = this.state.payments.filter(val => !this.state.selectedPayments.includes(val))
      let data = this.state.payments.filter(val => this.state.selectedPayments.includes(val))
      console.log("DATA: ", data);

      try {
        axios.put(`${url}/api/auth/approvedPayments`, { data })
          .then(res => {
            this.setState({
              payments,
              approvePaymentsDialog: false,
              selectedPayments: null
            })

            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Payments Approved', life: 3000 });
          })

      } catch (error) {
        console.log('ERROR: ', error);
      }

      if (data.status === 0) {
        this.onIndexChange(1)
        this.getPayments()
      } else if (data.status === 1) {
        this.onIndexChange(0)
        this.getPaymentsApproved()
      }
    } catch {

    }
  }



  hideDeletePaymentDialog() {
    this.setState({ deletePaymentDialog: false })
  }

  hideDeletePaymentsDialog() {
    this.setState({ deletePaymentsDialog: false });
  }

  hideApprovePaymentsDialog() {
    this.setState({ approvePaymentsDialog: false })
  }


  exportCSV() {
    this.dt.exportCSV();
  }




  editPayment(payment) {
    // console.log('PAYMEND EDIT: ', payment[0]);
    this.getInvoices(payment)
    this.getInvoiceItems(payment)
    this.getBalances(payment)
    this.getMethods()
    this.getAccounts()

    // this.DateToOE(payment.payment_date)
    
    console.log('PAYMENT DATE: ', payment.payment_date);

    if (typeof payment.payment_date === 'string') {
      console.log("PAYMENT DATE IS STRING");
      console.log(`PAYMENT DATE STRING: ${payment.payment_date}`)

      let [day, month, year] = payment.payment_date.split('/')
      payment.payment_date_show= new Date(+year, +month - 1, +day)

      console.log(`PAYMENT RESULT: ${payment.payment_date_show}`)
    }


    this.setState({
      payment: { ...payment },
      selectedAccount: null,
      resetAccount: null,
      selectedMethod: null,
      resetMethod: null,
      paymentDialog: true,
      CheckedApprove: false,
      edit: true,

    })

    console.log("PAYMENT EDIT: ", payment);

  }

  openNew() {
    this.setState({
      payment: this.emptyPayment,
      balance: this.emptyBalance,

      selectedAccount: null,
      selectedMethod: null,
      /* CheckedOwner:false, 
       CheckedLearning:false,
       resetRole:"Role",
       resetEnterprise:"Enterprise",*/
      submitted: false,
      paymentDialog: true,
      CheckedItem: false,
      CheckedApprove: false,
      edit: false





    })
    this.getPayments()
    this.getAccounts()
    this.getMethods()
  }

  hideDialog() {
    let payment = { ...this.state.payment }

    payment.rates = []
    payment.fk_id_balance_item = []
    payment.invoiceId = ''
    payment.fk_id_account = ''
    payment.method_name = ''
    payment.account_name = ''
    payment.amount = 0
    payment.amount_payable = 0
    payment.payment_date = ''
    payment.payment_date_show = ''
    payment.fk_id_payment_method = ''
    payment.payment_file_url = ''
    payment.payment_file = null
    payment.note = ''
    payment.code = ''
    payment.status = 0
    payment.symbol = ''
    payment.id = ''
    payment.idCurrency = ''
    payment.created_at = ''
    payment.symbolAmountCurrency = ''
    payment.updated_at = ''
    payment.newPayment = ''
    payment.fk_id_currency_account = ''

    this.setState({
      payment,
      submitted: false,
      paymentDialog: false,
      showCheckBox: false,
      balance: this.emptyBalance,
      account: this.emptyAccount,

      selectedPayments: null,
      selectedAccount: null,
      resetAccount: null,
      selectedMethod: null,
      resetMethod: null,
      CheckedApprove: false,




      /*
       emailMessage: '',
       passwordMessage: '',
       firstNameMessage: '',
       lastNameMessage: '',
       phoneMessage: '',
       fkIdRoleMessage: '',
       enterpriseMessage:'',     */
    })
  }




  hideDeletePaymentItemDialog() {
    this.setState({ deletePaymentItemDialog: false })
  }


  /*formatCurrency = (value) => {
    return value.toLocaleString('en-US', {mode:"currency", maxFractionDigits:2, minFractionDigits:2});
  }*/

  getAccount = async (e) => {
    let accountER
    try {
      await axios.get(`${url}/api/auth/account/${e.value}`)
        .then(res => {
          accountER = res.data;
        });
    } catch (error) {
    }
    return accountER
  }


  onApproveChange(e) {
    let payment = { ...this.state.payment }
    
    console.log("E.CHECKED:", e.checked)
    console.log("value:", e.value)
    if (e.checked) {
      payment['status'] = 1
      console.log("SÍ?:", payment.status)

    } else {
      payment['status'] = 0
      console.log("NO?:", payment.status)
    }

    this.setState({ CheckedApprove: e.checked, payment });

  }

  DateToOE(e) {

    let year = e.getFullYear();
    let month = e.getMonth() + 1;
    let day = e.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = year + '-' + month + '-' + day

    return new_date

  }



  async onAccountChange(e) {

    let account = await this.getAccount(e)
    console.log("ACCOUNT EN ACCOUNT CHANGE:", account)

    let payment = { ...this.state.payment };
    payment['fk_id_account'] = e.value;
    payment['code'] = account.codeCurrency
    payment['invoiceId'] = this.props.invoice


    this.setState({ selectedAccount: e.value, payment });

  }

  getExchangeRates = async (DateT, payment, DateOE) => {
    let balance = { ...this.state.balances }
    let data = {
      date: null
    }
    data.date = DateT
    let year = DateT.getFullYear();
    let month = DateT.getMonth() + 1;
    let day = DateT.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }

    let new_date = `${day}/${month}/${year}`

    data.date = new_date
    data.fk_id_currency_account = payment.fk_id_currency_account
    if (payment.fk_id_currency_account === this.props.user.fk_id_baseCurrency) {
      try {
        await axios.post(`${url}/api/auth/exchangeRatesByAccount`, data)
          .then(res => {
            let exchanges = res.data;
            for (let i = 0; i < Object.keys(balance).length; i++) {
              for (let j = 0; j < Object.keys(exchanges).length; j++) {
                if (balance[i].id_currency === exchanges[j].fk_id_currency)
                  balance[i].rate = exchanges[j].rate
              }
            }
            this.setState({ balance })
          });
      } catch (error) {
      }
    } else {
      try {
        await axios.get(`${urlOE}historical/${DateOE}.json?app_id=${apiOE}&base=${payment.code}`)

          .then(res => {
            let exchanges = res.data;
            for (let i = 0; i < Object.keys(balance).length; i++) {
              for (let j = 0; j < Object.keys(exchanges).length; j++) {
                balance[i].rate = (1 / exchanges.rates[`${balance[i].code}`])
              }
            }
            this.setState({ balance })
          });
      } catch (error) {
      }
    }
  }



  onDateChange(e, name) {
    // const val =  this.Date(e.target.value) || ''
    // const DateOE = this.DateToOE(e.target.value) || ''
    let DateT
    let DateOE
    let payment = { ...this.state.payment }

    payment[`${name}`] = e.target.value
    DateT = payment[`${name}`]
    DateOE = this.DateToOE(DateT)

    this.Date(payment[`${name}`])
    this.DateToOE(DateT)

    this.getExchangeRates(DateT, payment, DateOE)

    // payment[`${name}`] = val;

    this.setState({ payment });
  }

  onInputChange(e, name) {
    const val = (e.target && e.target.value) || '';
    let payment = { ...this.state.payment };

    payment[`${name}`] = val;

    this.setState({ payment });
  }

  onAmountChange(e) {
    const val = (e.target && e.target.value) || '';
    console.log("E:", e)

    let payment = { ...this.state.payment }
    let balance = { ...this.state.balances }

    payment['amount'] = val;

    for (let i = 0; i < Object.keys(balance).length; i++) {
      balance[i].amount = val;
    }
    this.setState({ payment, balance });
  }






  onRateChange(e, id) {
    const val = (e.target && e.target.value) || '';
    let balance = { ...this.state.balances }
    let payment = { ...this.state.payment }

    if (typeof payment.fk_id_balance_item === 'string') {
      payment.fk_id_balance_item = payment.fk_id_balance_item.split(',')
      payment.fk_id_balance_item = payment.fk_id_balance_item.map(Number);

      payment.rates = payment.rates.split(',')
      payment.rates = payment.rates.map(Number);
    }


    for (let n = 0; n < Object.keys(balance).length; n++) {
      if (balance[n].id === id) {
        for (let o = 0; o < Object.keys(payment.fk_id_balance_item).length; o++) {
          if (payment.fk_id_balance_item[o] === id) {
            payment['amount'] = payment['amount'] - (payment.rates[o] * balance[n].balance)
            balance[n].rate = val
            payment.rates[o] = val
            payment['amount'] = payment['amount'] + (balance[n].rate * balance[n].balance)
            break;
          }
        }
      }
    }

    this.setState({ balance, payment });
  }



  onMethodChange(e) {

    let payment = { ...this.state.payment };

    payment['fk_id_payment_method'] = e.value;

    this.setState({ selectedMethod: e.value, payment });
  }

  FilePayment() {
    let UploadAndShow = []
    let payment = { ...this.state.payment }

    if (payment.payment_file_url) {

      UploadAndShow.push(
        <>

          <div className="col-lg-2.5">
            <FileUpload
              type="file"
              name="payment_file"
              ref={(ref) => { this.uploadInput = ref; }}
              multiple={false}
              mode="basic"
              chooseLabel={this.state.campusText.ProgramFile}
            />
          </div>
          <div className="col-lg-1">
            <a href={`${payment.payment_file_url}`} target="_blank" rel="noopener noreferrer"><Button type="button" icon="pi pi-download" ></Button></a>
          </div>
        </>

      )
    } else {

      UploadAndShow.push(
        <>
          <div className="col-lg-3">
            <FileUpload
              type="file"
              name="payment_file"
              ref={(ref) => { this.uploadInput = ref; }}
              multiple={false}
              mode="basic"
              chooseLabel={this.state.campusText.ProgramFile} />
          </div>
        </>

      )

    }


    return UploadAndShow
  }


  onBalanceChange(e, rate, id) {
    let payment = { ...this.state.payment }
    let balance = { ...this.state.balances }


    if (typeof payment.fk_id_balance_item === 'string') {

      payment.fk_id_balance_item = payment.fk_id_balance_item.split(',')
      payment.fk_id_balance_item = payment.fk_id_balance_item.map(Number);

      payment.rates = payment.rates.split(',')
      payment.rates = payment.rates.map(Number);


      if (e.checked) {
        payment['fk_id_balance_item'].push(e.value)
        payment['rates'].push(rate)
        for (let n = 0; n < Object.keys(balance).length; n++) {
          if (balance[n].id === id) {
            payment['amount'] = payment['amount'] + (balance[n].rate * balance[n].balance)
            break;
          }
        }

      } else {
        for (let i = 0; i < payment.fk_id_balance_item.length + 1; i++) {
          if (payment.fk_id_balance_item[i] === e.value) {
            payment.fk_id_balance_item.splice(i, 1);
            break;
          }
        }

        for (let i = 0; i < payment.rates.length + 1; i++) {
          if (payment.rates[i] === rate) {
            payment.rates.splice(i, 1);
            break;
          }
        }
        for (let n = 0; n < Object.keys(balance).length; n++) {
          if (balance[n].id === id) {
            payment['amount'] = payment['amount'] - (balance[n].rate * balance[n].balance)
            break;
          }
        }
      }
      this.setState({ payment });


    } else {

      if (e.checked) {
        payment['fk_id_balance_item'].push(e.value)
        payment['rates'].push(rate)
        for (let n = 0; n < Object.keys(balance).length; n++) {
          if (balance[n].id === id) {
            payment['amount'] = payment['amount'] - (balance[n].rate * balance[n].balance)
            break;
          }
        }


      } else {
        for (let i = 0; i < payment.fk_id_balance_item.length + 1; i++) {
          if (payment.fk_id_balance_item[i] === e.value) {
            payment.fk_id_balance_item.splice(i, 1);
            break;
          }
        }

        for (let i = 0; i < payment.rates.length + 1; i++) {
          if (payment.rates[i] === rate) {
            payment.rates.splice(i, 1);
            break;
          }
        }
        for (let n = 0; n < Object.keys(balance).length; n++) {
          if (balance[n].id === id) {
            payment['amount'] = payment['amount'] - (balance[n].rate * balance[n].balance)
            break;
          }
        }

      }
      this.setState({ payment });
    }
  }




  Check(val) {
    let a = { ...this.state.payment };


    if (typeof a.fk_id_balance_item === 'string') {
      a.fk_id_balance_item = a.fk_id_balance_item.split(',')
      a.fk_id_balance_item = a.fk_id_balance_item.map(Number);


      return a['fk_id_balance_item'].some(arrVal => val === arrVal);


    } else {
      return a['fk_id_balance_item'].some(arrVal => val === arrVal);
    }
  }


  nameInCheck(val) {
    let b = { ...this.state.balances };
    console.log("VAL:", val)
    for (let i = 0; i < Object.keys(b).length; i++) {
      if (b[i].id === val) {
        return b[i].item_name
      }
    }
  }

  codeInCheck(val) {
    let b = { ...this.state.balances };
    console.log("VAL:", val)
    for (let i = 0; i < Object.keys(b).length; i++) {
      if (b[i].id === val) {
        return b[i].code
      }
    }
  }


  readOnlyItem(val) {
    let b = { ...this.state.balances };
    console.log("VAL:", val)

    for (let i = 0; i < Object.keys(b).length; i++) {
      if (b[i].id === val && b[i].status === 1) {
        return true
      }
    }
  }

  styleItem(val) {
    let b = { ...this.state.balances };
    console.log("VAL:", val)

    for (let i = 0; i < Object.keys(b).length; i++) {
      if (b[i].id === val && b[i].status === 1) {
        return ({ textDecorationLine: 'line-through', textDecorationStyle: 'solid' })
      }
    }
  }

  InputRate(val, i) {
    let payment = { ...this.state.payment }
    let balance = { ...this.state.balances }

    console.log("PAYMENT:", payment)
    //console.log("BALANCES:",b)
    console.log("VAL:", val)
    console.log("I:", i)

    if (balance[i].rate) {
      return balance[i].rate

    } else {
      if (typeof payment.fk_id_balance_item === 'string') {
        payment.fk_id_balance_item = payment.fk_id_balance_item.split(',')
        payment.fk_id_balance_item = payment.fk_id_balance_item.map(Number);

        payment.rates = payment.rates.split(',')
        payment.rates = payment.rates.map(Number);

        if (payment['fk_id_balance_item'].length > 1) {

          if (payment['fk_id_balance_item'].some(arrVal => val === arrVal)) {
            console.log(" RATES 1:", payment.rates)
            return payment.rates[i]
          }
        } else {
          if (payment['fk_id_balance_item'].some(arrVal => val === arrVal)) {
            console.log(" RATES 1:", payment.rates)
            return payment.rates
          }
        }

      } else {


        if (payment['fk_id_balance_item'].length > 1) {

          if (payment['fk_id_balance_item'].some(arrVal => val === arrVal)) {
            console.log("RATES 2:", payment.rates)
            return payment.rates[i]
          }
        } else {
          if (payment['fk_id_balance_item'].some(arrVal => val === arrVal)) {
            console.log("RATES 2:", payment.rates)
            return payment.rates
          }
        }
      }
    }
  }


  onIndexChange(e) {

    if (e === 0) {
      this.getPayments()
    } else if (e === 1) {
      this.getPaymentsApproved()
    }

    this.setState({ payments: null, activeIndex: e });
  }

  onSort(event) {
    let data = [...this.state.payments];
    data.sort((data1, data2) => {
      console.log("EVENT:", event)
      const value1 = data1.payment_date_new;
      const value2 = data2.payment_date_new;

      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2, undefined, { numeric: true });
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order * result);
    });

    return data;
  }

  onColumnToggle(event) {
    let selectedColumns = event.value
    let orderedSelectedColumns = this.columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
    this.setState({ selectedColumns: orderedSelectedColumns })
  }

  tablePayments() {
    let showPayments = []

    const header = (
      <div style={{ textAlign: 'left' }}>
        <MultiSelect
          value={this.state.selectedColumns}
          placeholder='Fields to show...'
          display='chip'
          options={this.columns}
          optionLabel='header'
          onChange={this.onColumnToggle}
        // style={{ width: '20em'}}
        />
        {/* 
      <ToggleButton 
        checked={this.state.symbolAmountFrozen} 
        onChange={(e) => this.setState({ symbolAmountFrozen: e.value })} 
        onIcon="pi pi-lock" 
        offIcon='pi pi-lock-open' 
        onLabel='Unfreeze balance' 
        offLabel='Freeze balance' 
        style={{ flexGrow: 1, flexBasis: '12rem', marginLeft: '10px'}}
      /> */}
      </div>
    )

    const columnComponents = this.state.selectedColumns.map(col => {
      return <Column sortable filter key={col.field} field={col.field} header={col.header} />
    })

    showPayments.push(
      <>
        <div>
          {/*SHOW PAYMENTS */}
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <DataTable
              ef={(el) => this.dt = el}
              value={this.state.payments}
              paginator
              paginatorTemplate="InvoicePageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              resizableColumns
              columnResizeMode='fit'
              header={header}
              reorderableColumns
              stripedRows
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              ref={(el) => { this.dt = el; }}
              globalFilter={this.state.globalFilter}
              emptyMessage={<FormattedMessage id="ACCOUNTING_ADMIN_PAYMENT.NO_DATA" />}
              loading={this.state.loading}
              selection={this.state.selectedPayments}
              onSelectionChange={e => this.setState({ selectedPayments: e.value })}
              dataKey="id">
              <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
              {columnComponents}
              {/* <Column field="externalId" header={this.state.campusText.InvoiceId} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column> */}
              {/* <Column field="externalId" header={this.state.campusText.InvoiceId} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column> */}
              {this.renderRedirect()}
              <Column field="student" body={this.studentBalance} header={this.state.campusText.Student} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Student} filterMatchMode="contains"></Column>
              {/* <Column field="office" header={this.state.campusText.Office} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office} filterMatchMode="contains"></Column> */}
              <Column field="payment_date" header={this.state.campusText.PaymentDate} headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.PaymentDate} filterMatchMode="contains"></Column>
              <Column field="account_name" header={this.state.campusText.Account} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Account} filterMatchMode="contains"></Column>
              <Column field="symbolAmountCurrency" header={this.state.campusText.Amount} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Amount} filterMatchMode="contains"></Column>
              <Column field="name_created_by" header='Created by' sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder='Created by' filterMatchMode="contains"></Column>
              <Column field="name_updated_by" header='Updated by' sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder='Updated by' filterMatchMode="contains"></Column>

              <Column body={this.fileBodyTemplate} header={this.state.campusText.File}></Column>
              <Column body={this.actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </>
    )
    return showPayments
  }

  tableApprovedPayments() {
    let showPayments = []

    const header = (
      <div style={{ textAlign: 'left' }}>
        <MultiSelect
          value={this.state.selectedColumns}
          placeholder='Fields to show...'
          display='chip'
          options={this.columns}
          optionLabel='header'
          onChange={this.onColumnToggle}
        // style={{ width: '20em'}}
        />

        {/* <ToggleButton 
        checked={this.state.symbolAmountFrozen} 
        onChange={(e) => this.setState({ symbolAmountFrozen: e.value })} 
        onIcon="pi pi-lock" 
        offIcon='pi pi-lock-open' 
        onLabel='Unfreeze balance' 
        offLabel='Freeze balance' 
        style={{ flexGrow: 1, flexBasis: '12rem', marginLeft: '10px'}}
      /> */}
      </div>
    )

    const columnComponents = this.state.selectedColumns.map(col => {
      return <Column sortable filter key={col.field} field={col.field} header={col.header} />
    })

    showPayments.push(
      <>
        <div>
          {/*SHOW PAYMENTS */}
          <div className="card-header flex-wrap border-0 pt-6 pb-0">
            <DataTable
              ef={(el) => this.dt = el}
              value={this.state.approvedPayments}
              paginator
              paginatorTemplate="InvoicePageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              resizableColumns
              columnResizeMode='fit'
              responsiveLayout='scroll'
              header={header}
              reorderableColumns
              stripedRows
              currentPageReportTemplate="Showing {first} to {last} to {totalRecords}"
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              ref={(el) => { this.dt = el; }}
              globalFilter={this.state.globalFilter}
              emptyMessage={<FormattedMessage id="ACCOUNTING_ADMIN_PAYMENT.NO_DATA" />}
              loading={this.state.loading}
              selection={this.state.selectedPayments}
              onSelectionChange={e => this.setState({ selectedPayments: e.value })}
              dataKey="id">
              <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
              {columnComponents}
              {/* <Column field="externalId" header={this.state.campusText.InvoiceId} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.InvoiceId} filterMatchMode="contains"></Column> */}
              {this.renderRedirect()}
              <Column field="student" body={this.studentBalance} header={this.state.campusText.Student} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Student} filterMatchMode="contains"></Column>
              {/* <Column field="office" header={this.state.campusText.Office} sortable headerStyle={{width: 'flex'}} filter filterPlaceholder={this.state.campusText.Office} filterMatchMode="contains"></Column> */}
              <Column field="payment_date" header={this.state.campusText.PaymentDate} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.PaymentDate} filterMatchMode="contains"></Column>
              <Column field="account_name" header={this.state.campusText.Account} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Account} filterMatchMode="contains"></Column>
              <Column field="symbolAmountCurrency" header={this.state.campusText.Amount} sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder={this.state.campusText.Amount} filterMatchMode="contains"></Column>
              <Column field="name_created_by" header='Created by' sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder='Created by' filterMatchMode="contains"></Column>
              <Column field="name_updated_by" header='Updated by' sortable headerStyle={{ width: 'flex' }} filter filterPlaceholder='Updated by' filterMatchMode="contains"></Column>

              <Column body={this.fileBodyTemplate} header={this.state.campusText.File}></Column>
              <Column body={this.actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </>
    )
    return showPayments
  }


  balance = () => {
    let balanceView = []
    let InputAmountView = []
    let Order = []


    let balance = { ...this.state.balances }
    let payment = { ...this.state.payment }

    console.log("BALANCE IN VIEW:", balance)

    if (typeof payment.fk_id_balance_item === 'string') {
      payment.fk_id_balance_item = payment.fk_id_balance_item.split(',')
      payment.fk_id_balance_item = payment.fk_id_balance_item.map(Number);

      payment.rates = payment.rates.split(',')
      payment.rates = payment.rates.map(Number);
    }




    for (let i = 0; i < Object.keys(payment.fk_id_balance_item).length; i++) {
      if (payment.fk_id_account && payment.payment_date) {
        balanceView.push(
          <>
            {/* Balance */}
            {/* <div className="form-group row">
            <div className="p-field-checkbox">
                <Checkbox 
                style={{ width: 20, height:20 }}
                variant="outlined"
                margin="dense"
                inputid="id"
                disabled="true"
                name="balance" 
                value={payment.fk_id_balance_item[i]} 
                onChange={(e) => this.onBalanceChange(e,payment.rates[i],payment.fk_id_balance_item[i])}
                checked={this.Check(payment.fk_id_balance_item[i])} 
                />               
            <div className="col-lg-12">      
                <div inputid="id">{this.nameInCheck(payment.fk_id_balance_item[i])}
                </div>  
                {this.state.campusText.Rate}:<InputNumber  
                    locale="en-US" 
                    minFractionDigits={2}   
                    min="0"
                    inputStyle={{width:60, height:25,  margin: "10px"}}  
                    id="rate" 
                    value={ payment.rates[i]} 
                    disabled={!this.Check(payment.fk_id_balance_item[i])} 
                    onValueChange={(e) => this.onRateChange(e,payment.fk_id_balance_item[i])}
                    /> 
                </div>  
          </div>
        </div> */}
            <div className="grid p-fluid">
              <div className="col">
                <div className='p-inputgroup'>
                  <span className='p-inputgroup-addon'>
                    <Checkbox
                      style={{ width: 20, height: 20 }}
                      variant="outlined"
                      margin="dense"
                      inputid="id"
                      disabled="true"
                      name="balance"
                      value={payment.fk_id_balance_item[i]}
                      onChange={(e) => this.onBalanceChange(e, payment.rates[i], payment.fk_id_balance_item[i])}
                      checked={this.Check(payment.fk_id_balance_item[i])}
                    // className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.fk_id_balance_item})}
                    />
                  </span>
                  <InputText placeholder={this.nameInCheck(payment.fk_id_balance_item[i])} disabled />
                  <span className='p-inputgroup-addon'>{this.codeInCheck(payment.fk_id_balance_item[i])}</span>
                  {/* <small id="fk_id_balance_item-help" className={classNames({ 'p-invalid': this.state.submitted })}>{this.getTextAuxFK_Id_Balance_Item(this.state)}</small> */}
                </div>
              </div>

              <div className='col-fixed'>
                <div className='p-inputgroup'>
                  <span className='p-inputgroup-addon'>$</span>
                  <InputNumber
                    locale='en-US'
                    minFractionDigits={2}
                    min='0'
                    id='rate'
                    style={{ width: 60 }}
                    value={payment.rates[i]}
                    disabled={!this.Check(payment.fk_id_balance_item[i])}
                  // className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.rates})}
                  />
                  <span className='p-inputgroup-addon'>{this.codeInCheck(payment.fk_id_balance_item[i])}</span>
                </div>
              </div>
            </div>
            <br />
          </>
        )
      }



    }

    if (payment.amount) {
      InputAmountView.push(
        <>
          {/* <div className="form-group row">  
                    <div className="col-lg-3">
                    <span className="p-float-label">  
                      <InputNumber
                        locale="en-US" 
                        minFractionDigits={2} 
                        min="0"  
                        style={{ width: 190, height:33 }}
                        id="amount"
                        variant="outlined"
                        margin="dense"
                        value={payment.amount}   
                        onValueChange={(e) => this.onAmountChange(e)}
                        required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.amount  })}
                        />
                        <label htmlFor="amount">{this.state.campusText.AmountPayable}</label>
                    </span>
                    </div>
                    </div> */}
          <div>
            <div className='col-lg-4'>
              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>$</span>
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  style={{ width: 60 }}
                  id="amount"
                  variant="outlined"
                  margin="dense"
                  value={payment.amount}
                  onValueChange={(e) => this.onAmountChange(e)}
                  required autoFocus
                />
                <span className='p-inputgroup-addon' htmlFor="amount">{this.state.campusText.AmountPayable}</span>
              </div>
            </div>
          </div>
          <br />
        </>
      )
    } else {
      //payment.amount=totalAmount
      InputAmountView.push(
        <>
          {/* <div className="form-group row">  
                <div className="col-lg-3">
                <span className="p-float-label">  
                  <InputNumber
                    locale="en-US" 
                    minFractionDigits={2} 
                    min="0"
                    style={{ width: 190, height:33 }}
                    id="amount"
                    variant="outlined"
                    margin="dense"
                    value={payment.amount}
                    onValueChange={(e) => this.onAmountChange(e)}
                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.amount  })}
                    />
                    <label htmlFor="amount">{this.state.campusText.AmountPayable}</label>
                </span>
                </div>
                </div> */}
          <div>
            <div className='col-lg-4'>
              <div className='p-inputgroup'>
                <span className='p-inputgroup-addon'>$</span>
                <InputNumber
                  locale="en-US"
                  minFractionDigits={2}
                  min="0"
                  style={{ width: 60 }}
                  id="amount"
                  variant="outlined"
                  margin="dense"
                  value={payment.amount}
                  onValueChange={(e) => this.onAmountChange(e)}
                  required autoFocus
                />
                <span className='p-inputgroup-addon' htmlFor="amount">{this.state.campusText.AmountPayable}</span>
              </div>
            </div>
          </div>
          <br />


        </>
      )
    }

    Order[0] = (balanceView)
    Order[1] = (InputAmountView)
    return Order
  }


  fileBodyTemplate(rowData) {
    if (rowData.payment_file_url !== null) {
      return (
        <>
          <a href={`${rowData.payment_file_url}`} target="_blank" rel="noopener noreferrer"><Button type="button" className='p-button-rounded p-button-outlined p-button-help' icon="pi pi-download" style={{ verticalAlign: 'middle', width: "30px", height: "30px", }}></Button></a>
        </>
      )
    }
  }


  actionBodyTemplate(rowData) {
    return (
      <>
        <Button tooltip='Edit payment' tooltipOptions={{ position: 'bottom' }} className='p-button-rounded p-button-outlined' icon="pi pi-pencil" color='primary' style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.editPayment(rowData)} />
        <Button tooltip='Delete payment' tooltipOptions={{ position: 'bottom' }} icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-danger" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.confirmDeletePayment(rowData)} />
        {/*<Button icon="pi pi-dollar"  color='primary' style={{verticalAlign: 'middle',   margin: "10px", width: "30px", height: "30px",}}  onClick={() => this.setRedirect(rowData)} />*/}
      </>
    )
  }



  /*formatCurrency = (value) => {
    if(value===0 || value===null){
      return null
    }else{
    return value.toLocaleString('en-US', {mode:"currency", maxFractionDigits:2, minFractionDigits:2});
    }
}  */


  studentBalance(rowData) {
    return (
      <>
        {this.renderRedirect()}
        <Button label={rowData.student} className="p-button-text" onClick={() => this.setRedirect(rowData)} />
      </>
    )
  }

  showStudent = () => {
    let invoice = { ...this.state.invoices }
    let name

    for (let i in invoice) {
      name = invoice[i].student
    }

    let showStudent = []
    showStudent.push(
      <div>
        <label>Student: </label>
        <span className='text-3xl text-900'>{name}</span>
      </div>
    )

    return showStudent
  }


  setRedirect = (payment) => {

    this.props.getInvoiceId(payment.invoiceId)

    this.setState({
      redirect: true
    })
  }
  renderRedirect = () => {

    if (this.state.redirect) {
      return <Redirect to='/get-agent-payments' />
    }
  }



  render() {

    let invoices = {...this.state.invoices}
    let validateInvoice = null
    console.log('INVOICES 822: ', invoices);

    for ( let i in invoices ) {
      console.log('INVOICES: ', invoices[i]);
      validateInvoice = invoices[i].validateInvoice
    }
    
    const paymentDialogFooter = (
      <>


        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
        </FormattedMessage>

        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.savePayment} />}
        </FormattedMessage>
      </>
    );

    const deletePaymentDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeletePaymentDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deletePayment} />
      </>
    );



    const deletePaymentsDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideDeletePaymentsDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.deleteSelectedPayments} />
      </>
    );

    const approvePaymentsDialogFooter = (
      <>
        <Button label={this.state.campusText.No} icon="pi pi-times" className="p-button-text" onClick={this.hideApprovePaymentsDialog} />
        <Button label={this.state.campusText.Yes} icon="pi pi-check" className="p-button-text" onClick={this.approveSelectedPayments} />
      </>
    )


    return (
      <div>
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />
          <h3 className="card-label" >
            <FormattedMessage id="ACCOUNTING_ADMIN_PAYMENT_TITLE" />
            <span className="d-block text-muted pt-2 font-size-sm">
              <FormattedMessage id="ACCOUNTING_ADMIN_PAYMENT.DESCRIPTION" />
            </span>
          </h3>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {/*TOOL BAR */}


            <div style={{ marginRight: 10 }}>
              <FormattedMessage id="GENERAL.EXPORT">
                {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
              </FormattedMessage>
            </div>

            <div style={{ marginRight: 10 }}>
              <Button
                tooltip='Delete'
                tooltipOptions={{ position: 'bottom' }}
                type='button'
                icon='pi pi-trash'
                className='p-button-danger'
                onClick={this.confirmDeleteSelected}
                disabled={!this.state.selectedPayments || !this.state.selectedPayments.length}
              />
            </div>

            <div style={{ marginRight: 10 }}>
              <Button
                tooltip='Approved all'
                tooltipOptions={{ position: 'bottom' }}
                type='button'
                icon='pi pi-check'
                className='p-button-success'
                onClick={this.confirmApproveSelected}
                disabled={!this.state.selectedPayments || !this.state.selectedPayments.length}
              />
            </div>

            <div style={{ marginRight: 10 }}>
              <p> <span className="p-input-icon-left">
                <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
              </span></p>
            </div>

          </div>
        </div>

        <div>
          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onIndexChange(e.index)} >
            <TabPanel header={this.state.campusText.PenddingPayments}>
              <div>
                {/*SHOW PENDDING COMMISSIONS */}
                {this.tablePayments()}
              </div>
            </TabPanel>
            <TabPanel header={this.state.campusText.ApprovedPayments}>
              <div>
                {/*SHOW SCHEDULE PAYMENTS */}
                {this.tableApprovedPayments()}
              </div>
            </TabPanel>
          </TabView>
        </div>


        <div>
          {/*DELETE PAYMENT */}
          <Dialog visible={this.state.deletePaymentDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePaymentDialogFooter} onHide={this.hideDeletePaymentDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {<span> <FormattedMessage id="ACCOUNTING_PAYMENT.DIALOG_DELETE" /></span>}
            </div>
          </Dialog>
        </div>
        {/*DELETE WITH TOOL BAR*/}
        <Dialog visible={this.state.deletePaymentsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePaymentsDialogFooter} onHide={this.hideDeletePaymentsDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {this.state.payment && <span><FormattedMessage id="ACCOUNTING_PAYMENT.DIALOG_DELETE_ALL" /></span>}
          </div>
        </Dialog>

        {/* APPROVE WITH TOOL BAR */}
        <Dialog visible={this.state.approvePaymentsDialog} style={{ width: '450px' }} header="Confirm" modal footer={approvePaymentsDialogFooter} onHide={this.hideApprovePaymentsDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {this.state.payment && <span>Approve all selected payments</span>}
          </div>
        </Dialog>

        <div>
          {/*UPDATE & CREATE PAYMENT*/}
          <Dialog visible={this.state.paymentDialog} contentStyle={{ maxHeight: "1600px", width: "900px", height: "1000px", overflow: "auto" }} header={<FormattedMessage id="OPTION.PAYMENT" />} modal footer={paymentDialogFooter} onHide={this.hideDialog}>
            <br></br>
            {this.showStudent()}

            <br></br>
            <div className='for-group row'>
              {this.FilePayment()}
            </div>
            {/* Account */}
            <div className="form-group row">
              {/* <div className="col-lg-2">
              <div className="p-float-label" style={{ width: 190, height:33, fontSize:18, verticalAlign: 'middle',   margin: "-5px", color:'green' }}  inputid="approvePayment">{this.state.campusText.PaymentApproved}</div>
            </div> */}
            { 
              validateInvoice === 1 ?
                <div className="col-lg-2">
                  <Checkbox
                    id="approvePayment"
                    margin="dense"
                    value={this.state.CheckedApprove || this.state.payment.status}
                    checked={this.state.CheckedApprove}
                    onChange={this.onApproveChange}
                  />
                </div>
              :
                <></>
            }
            </div>


            <br></br>
            <div className="form-group row">
              <div className="col-lg-3">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: 190, height: 33 }}
                    id="account_name"
                    variant="outlined"
                    placeholder={this.state.resetAccount || this.state.payment.account_name}
                    margin="dense"
                    value={this.state.selectedAccount || "account_name"}
                    options={this.state.accounts}
                    onChange={this.onAccountChange}
                    optionLabel="account_name"
                    optionValue="id"
                  />
                  <label htmlFor="account_name">{this.state.campusText.Account}</label>
                </span>
                {/*<small id="account_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxRoleType(this.state)}</small>*/}
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-3">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: 190, height: 33 }}
                    id="method_name"
                    variant="outlined"
                    placeholder={this.state.resetMethod || this.state.payment.method_name}
                    margin="dense"
                    value={this.state.selectedMethod || "method_name"}
                    options={this.state.methods}
                    onChange={this.onMethodChange}
                    optionLabel="method_name"
                    optionValue="id"
                  />
                  <label htmlFor="method_name">{this.state.campusText.PaymentMethod}</label>
                </span>
                {/*<small id="method_name-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxMethodName(this.state)}</small>*/}
              </div>

              <div className="col-lg-3">
                <span className="p-float-label">
                  <Calendar
                    style={{ width: 190, height: 33 }}
                    showIcon
                    margin="dense"
                    variant="outlined"
                    readOnlyInput="true"
                    dateFormat="dd/mm/yy"
                    id="payment_date"
                    placeholder={this.state.payment.payment_date_show || "Payment Date"}
                    value={this.state.payment.payment_date_show || "payment_date"}
                    onChange={(e) => this.onDateChange(e, 'payment_date_show')} />
                  <label htmlFor="payment_date">{this.state.campusText.PaymentDate}</label>
                </span>
                {/*<small id="amount-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxAmount(this.state)}</small>*/}
              </div>
            </div>
            <br></br>
            <br></br>
            {this.balance()}

            <div className="form-group row">
              <div className="col-lg-12">
                <div className="card">
                  <DataTable
                    ef={(el) => this.dt = el} value={this.state.balances}
                    loading={this.state.loading}>
                    <Column field="item_name" header={this.state.campusText.InvoiceName}></Column>
                    <Column field="symbolTotalCurrency" header={this.state.campusText.Total}></Column>
                    <Column field="symbolBalanceCurrency" header={this.state.campusText.Balance}></Column>
                  </DataTable>
                </div>
              </div>
            </div>


            <div className="form-group row">
              <div className="col-lg-6">
                <span className="p-float-label">
                  <InputTextarea
                    style={{ width: 600, height: 80 }}
                    id="note"
                    variant="outlined"
                    margin="dense"
                    value={this.state.payment.note}
                    onChange={(e) => this.onInputChange(e, 'note')}
                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.payment.note })}
                  />
                  <label htmlFor="note">{this.state.campusText.Note}</label>
                </span>
                {/*<small id="note-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxNote(this.state)}</small>*/}
              </div>
            </div>
          </Dialog>
        </div>


        <Tooltip title={this.state.campusText.NewPayment} aria-label="add" onClick={this.openNew}>
          <Fab color="secondary">
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>

    )
  }
}


const mapStateToProps = state => ({
  invoice: state.auth.invoiceId,
  user: state.auth.user,
});
export default connect(mapStateToProps, auth.actions)(AdminPaymentsTable);






