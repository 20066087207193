import React from "react";
import { useSubheader } from "../../_metronic/_core";
import ExpensesDataTable from "../moduleExpenses/Expenses/ExpensesTable";

export const ExpensesPage = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle('Expenses');

    return (
        <>
            <ExpensesDataTable />
        </>
    )
}