import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import  PowerBiComponent  from "./PowerBiComponent";

const ExpensesAnalytics = () => {
  const { state } = useLocation();
//   let Id = state.id;
//   let Value = state.data;

  /* Above values are coming from other parent component */

  const location = useLocation();
  const [details, setDetails] = useState([]);
  const [reportId, setReportId] = useState("");
  const [embedUrl, setEmbedUrl] = useState("");

  /*if this variable is initialized with reportId instead of empty string, report loads perfectly 
      without issue, but that is not the case*/
// 
//   let customerId = location.state.customerId;

//   useEffect(() => {
//     const getUrl = "http://localhost:8080/Api/GetData/" + customerId;
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(getUrl);
//         const data = await response.data;
//         setDetails(data);
//         url = new URL(data.visualizationUrl);
//         const queryParam = new URLSearchParams(url.search);
//         setReportId(queryParam.get("reportId"));
//         setEmbedUrl(url.href);
//       } catch (e) {
//         console.log("error", e);
//       }
//     };
//     fetchData();
//   }, [customerId]);

  return (
    <>
      {/* <div>
        <p>details.customerName</p>
        <p>details.customerAddress</p>
      </div> */}
      <div>
        <PowerBiComponent  />
      </div>
    </>
  );
};

export default ExpensesAnalytics;