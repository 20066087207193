import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import ServerURL from '../../../ServerURL';

import OpenExchangeURL from '../../../OpenExchangeURL';
import OpenExchangeAPI from '../../../OpenExchangeAPI';

import * as auth from '../../modulesAdmin/Auth/_redux/authRedux';

// Primereact components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber'
import { Checkbox } from 'primereact/checkbox';
import { InputMask } from 'primereact/inputmask';

// Utileria
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { classNames } from 'primereact/utils';

const url = ServerURL();

let emptyProvider = {
    legal_name: '',
    commercial_name: '',
    rfc_provider: '',
    phone1: 0,
    phone2: 0,
    email_provider: '',
    address1_provider: '',
    address2_provider: '',
    city_provider: '',
    state_provider: '',
    country_provider: '',
    cp_provider: '',
    bank_provider: '',
    clabe_provider: '',
    fk_id_category: 0,
    fk_id_subcategory: 0,
    created_by: '',
    updated_by: ''
}

let emptyCategory = {
    id: null,
    description: ''
}

let emptySubCategory = {
    id: null,
    descriptionSub: ''
}

let emptySubCategoryByCategory = {
    id: null,
    descriptionSub: ''
}

const ProvidersTable = () => {
    const [providers, setProviders] = useState(null)
    const [provider, setProvider] = useState(emptyProvider)

    const [categories, setCategories] = useState(null)
    const [category, setCategory] = useState(emptyCategory)

    const [subCategories, setSubCategories] = useState(null)
    const [subCategory, setSubCategory] = useState(null)

    const [subCategoriesByCategory, setSubCategoriesByCategory] = useState()

    const [submitted, setSubmitted] = useState(false)
    const [checked, setChecked] = useState(false)

    // Dialogs
    const [providerDialog, setProviderDialog] = useState(false)
    const [deleteProviderDialog, setDeleteProviderDialog] = useState(false)

    // PETICIONES API
    // GET Providers
    const getProviders = async () => {
        try {
            const providers = await axios.get(`${url}/api/auth/expenses/providers`)
            setProviders(providers.data)
            console.log('PROVIDERS');

        } catch (error) {
            console.log("ERROR: ", error);
        }
    }

    const getCategories = async () => {
        const res = await axios.get(`${url}/api/auth/expenses/categories`)
        setCategories(res.data)
        console.log('CATEGORIES: ', categories);
    }

    const getSubCategories = async () => {
        try {
            await axios.get(`${url}/api/auth/expenses/sub_categories`)
                .then(res => {
                    setSubCategories(res.data)
                });
        } catch (error) {
            console.log('ERROR: ', error);
        }
    }

    const getSubCategoryByCategory = async (e) => {
        try {
            await axios.get(`${url}/api/auth/expenses/sub_category_by_id/${e}`)
                .then(res => {
                    // let _subCategories = res.data
                    setSubCategories(res.data)
                    console.log('E 124: ', res.data);

                })
        } catch (error) {

        }
    }

    useEffect(async () => {
        getProviders();
        getCategories();
        getSubCategories();
    }, [])

    // Save provider
    const saveProvider = async () => {
        setSubmitted(true)

        let _provider = { ...provider }

        const formData = new FormData();

        formData.append('legal_name', _provider.legal_name)
        formData.append('commercial_name', _provider.commercial_name)
        formData.append('rfc_provider', _provider.rfc_provider)
        formData.append('phone1', _provider.phone1)
        formData.append('phone2', _provider.phone2)
        formData.append('email_provider', _provider.email_provider)
        formData.append('address1_provider', _provider.address1_provider)
        formData.append('address2_provider', _provider.address2_provider)
        formData.append('city_provider', _provider.city_provider)
        formData.append('state_provider', _provider.state_provider)
        formData.append('country_provider', _provider.country_provider)
        formData.append('cp_provider', _provider.cp_provider)
        formData.append('bank_provider', _provider.bank_provider)
        formData.append('clabe_provider', _provider.clabe_provider)
        formData.append('fk_id_category', _provider.fk_id_category)
        formData.append('fk_id_subcategory', _provider.fk_id_subcategory)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        console.log('FORM DATA: ', formData);

        if (_provider.id) {
            console.log('EDITAR REGISTRO: ');

            await axios.put(`${url}/api/auth/expenses/provider/${_provider.id}`, formData, config)
                .then(res => {
                    console.log('RES: ', res.data);

                    setProvider(emptyProvider)
                    setCategory(emptyCategory)
                    setSubCategory(emptySubCategory)
                    setSubmitted(false)
                    setProviderDialog(false)

                    getProviders()
                    getCategories()
                    getSubCategories()
                })
        } else {
            await axios.post(`${url}/api/auth/expenses/provider`, formData, config)
                .then(res => {
                    console.log('RES: ', res.data);

                    setProvider(emptyProvider)
                    setCategory(emptyCategory)
                    setSubCategory(emptySubCategory)
                    setSubmitted(false)
                    setProviderDialog(false)

                    getProviders()
                    getCategories()
                    getSubCategories()
                })
                .catch((error) => {
                    console.log(error);

                });
        }

        console.log('SAVE PROVIDER: ', _provider);

    }

    // Delete provider
    const deleteProvider = async () => {
        let _provider = { ...provider }

        try {
            await axios.delete(`${url}/api/auth/expenses/provider/${_provider.id}`)
                .then(res => {
                    console.log('RES: ', res.data);
                    setProvider(emptyProvider)
                    setCategory(emptyCategory)
                    setSubCategory(emptySubCategory)
                    setDeleteProviderDialog(false)

                    getProviders()
                    getCategories()
                    getSubCategories()
                })
        } catch (error) {

        }
    }

    // Funciones pagina dinamica
    const openNew = () => {
        setProvider(emptyProvider)
        setSubmitted(false)
        setProviderDialog(true)
    }

    const editProvider = (editProvider) => {
        // console.log('PROVIDER: ', editProvider);

        let lengthCategories = Object.keys(categories).length
        let lengthSubCategories = Object.keys(subCategories).length


        for(let i = 0; i < lengthCategories; i++) {
            if(categories[i].id === editProvider.fk_id_category) {
                // console.log('CATEGORY: ', categories[i]);
                let _categories = categories[i]
                setCategory(_categories)
                onEditCategoryChange(_categories)                                
            }
        }

        
        for(let i = 0; i < lengthSubCategories; i++) {
            if(subCategories[i].id === editProvider.fk_id_subcategory) {
                // console.log('CATEGORY: ', categories[i]);
                let _subCategories = subCategories[i]
                setSubCategory(_subCategories)
                onEditSubCategoryChange(_subCategories)                                
            }
        }

        

        // let _provider = { ...provider }
        setProvider(editProvider)
        setProviderDialog(true)

        // console.log('PROVIDER: ', editProvider);
    }

    const confirmDeleteProvider = (deleteProvider) => {
        console.log('Delete provider,', deleteProvider);

        setProvider(deleteProvider)
        setDeleteProviderDialog(true)
    }

    const hideDialog = () => {
        setProvider(emptyProvider)
        setCategory(emptyCategory)
        setSubCategory(emptySubCategory)
        setSubmitted(false)
        setProviderDialog(false)
    }

    const hideDeleteDialog = () => {
        setSubmitted(false)
        setProvider(emptyProvider)
        setCategory(emptyCategory)
        setSubCategory(emptySubCategory)
        setDeleteProviderDialog(false)
    }

    const onInputChange = (e, name) => {
        const val = (e.target.value && e.target.value || '');
        let _provider = { ...provider }
        _provider[`${name}`] = val

        setProvider(_provider)
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _provider = { ...provider }
        _provider[`${name}`] = val

        setProvider(_provider)
    }

    const onCategoryChange = (e) => {
        // console.log('E: ', e.value);
        provider.fk_id_category = e.value.id

        setCategory(e.value)
        setSubCategory(emptySubCategory)
        getSubCategoryByCategory(e.value.id)
    }

    const onEditCategoryChange = (e) => {
        setCategory(e)
        getSubCategoryByCategory(e.id)
    }

    const onSubCategoryChange = (e) => {
        console.log('E: ', e.value)
        provider.fk_id_subcategory = e.value.id

        setSubCategory(e.value)
    }

    const onEditSubCategoryChange = (e) => {
        setSubCategory(e)
        getSubCategoryByCategory(e.fk_id_category)
    }


    // Templates
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button tooltip='New category' tooltipOptions={{ position: 'bottom' }} icon='pi pi-plus' className='p-button-rounded p-button-outlined mr-2' onClick={openNew} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon='pi pi-pencil' className='p-button-rounded p-button-outlined mr-2' onClick={() => editProvider(rowData)} />
                <Button icon='pi pi-trash' className='p-button-rounded p-button-outlined p-button-danger mr-2' onClick={() => confirmDeleteProvider(rowData)} />
            </React.Fragment>
        )
    }

    // Footers
    const providerDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDialog} />
                <Button label='Save' icon='pi pi-check' className='p-button-text' onClick={saveProvider} />
            </React.Fragment>
        )
    };

    const deleteProviderDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDeleteDialog} />
                <Button label='Confirm' icon='pi pi-check' className='p-button-text' onClick={deleteProvider} />
            </React.Fragment>
        )
    }

    // Render
    return (
        <div>
            <Toolbar left={leftToolbarTemplate} />
            <DataTable value={providers} sortable dataKey='id' paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                currentPageReportTemplate='Showing {first} to {last} of {totalRecords} providers'
                responsiveLayout='scroll'>
                <Column selectionMode='multiple' headerStyle={{ width: '3rem'}} exportable={false} />
                <Column field='legal_name' header='Legal name' sortable style={{ minWidth: '16rem'}} />
                <Column field='commercial_name' header='Commercial name' sortable style={{ minWidth: '12rem'}} />
                <Column field='rfc_provider' header='RFC' sortable style={{ minWidth: '6rem'}} />
                <Column field='phone1' header='Phone 1' sortable style={{ minWidth: '6rem'}} />
                <Column field='phone2' header='Phone 2' sortable style={{ minWidth: '6rem'}} />
                <Column field='email_provider' header='Email' sortable style={{ minWidth: '6rem'}} />
                <Column field='address1_provider' header='Address 1' sortable style={{ minWidth: '6rem'}} />
                <Column field='address2_provider' header='Address 2' sortable style={{ minWidth: '6rem'}} />
                <Column field='city_provider' header='City' sortable style={{ minWidth: '6rem'}} />
                <Column field='state_provider' header='State' sortable style={{ minWidth: '6rem'}} />
                <Column field='country_provider' header='Country' sortable style={{ minWidth: '6rem'}} />
                <Column field='cp_provider' header='CP' sortable style={{ minWidth: '6rem'}} />
                <Column field='bank_provider' header='Bank' sortable style={{ minWidth: '6rem'}} />
                <Column field='clabe_provider' header='CLABE' sortable style={{ minWidth: '6rem'}} />
                <Column field='description' header='Category' sortable style={{ minWidth: '6rem'}} />
                <Column field='descriptionSub' header='Sub category' sortable style={{ minWidth: '6rem'}} />
                <Column field='created_by' header='Created by' sortable style={{ minWidth: '6rem'}} />
                <Column field='updated_by' header='Updated by' sortable style={{ minWidth: '6rem'}} />
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem'}} />

            </DataTable>

            <Dialog visible={providerDialog} onHide={hideDialog} style={{ width: '750px' }}
                header='New provider' modal className='p-fluid mb-3' footer={providerDialogFooter}>
                {/* LEGAL NAME && COMMERCIAL NAME && RFC */}
                <div className='formgrid grid mt-5' style={{ marginTop: '10px' }} >
                    <div className='field col-4 md:col-3'>
                        <span className='p-float-label'>
                            <InputText id='legal_name' value={provider.legal_name} onChange={(e) => onInputChange(e, 'legal_name')}
                                required className={classNames({ 'p-invalid': submitted && !provider.legal_name })} />
                            {submitted && !provider.legal_name && <small className='p-error'>Legal name is required</small>}
                            <label htmlFor='legal_name'>Legal name</label>
                        </span>
                    </div>

                    <div className='field col-4 md:col-3'>
                        <span className='p-float-label'>
                            <InputText id='commercial_name' value={provider.commercial_name} onChange={(e) => onInputChange(e, 'commercial_name')}
                                required className={classNames({ 'p-invalid': submitted && !provider.commercial_name })} />
                            {submitted && !provider.commercial_name && <small className='p-error'>Commercial name is required</small>}
                            <label htmlFor='commercial_name'>Commercial name</label>
                        </span>
                    </div>

                    <div className='field col-4 md:col-3'>
                        <span className='p-float-label'>
                            <InputText id='rfc_provider' value={provider.rfc_provider} onChange={(e) => onInputChange(e, 'rfc_provider')}
                                required className={classNames({ 'p-invalid': submitted && !provider.rfc_provider })} />
                            {submitted && !provider.rfc_provider && <small className='p-error'>RFC is required</small>}
                            <label htmlFor='rfc_provider'>RFC</label>
                        </span>
                    </div>
                </div>

                {/* PHONE 1 && PHONE 2 */}
                <div className='formgrid grid' style={{ marginTop: '10px' }}>
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <InputMask id='phone1' mask='(999) 999-9999' value={provider.phone1} onChange={(e) => onInputNumberChange(e, 'phone1')}
                                required className={classNames({ 'p-invalidad': submitted && !provider.phone1 })} />
                            <label htmlFor='phone1'>Phone 1</label>
                        </span>
                    </div>
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <InputMask id='phone2' mask='(999) 999-9999' value={provider.phone2} onChange={(e) => onInputNumberChange(e, 'phone2')}
                                className={classNames({ 'p-invalidad': submitted && !provider.phone2 })} />
                            <label htmlFor='phone2'>Phone 2</label>
                        </span>
                    </div>
                </div>

                {/* EMAIL PROVIDER */}
                <div className='formgrid grid' style={{ marginTop: '10px' }}>
                    <div className='field col-12 md:col-6'>
                        <span className='p-float-label'>
                            <InputText id='email_provider' value={provider.email_provider} onChange={(e) => onInputChange(e, 'email_provider')}
                                required className={classNames({ 'p-invalid': submitted && !provider.email_provider })} />
                            {submitted && !provider.email_provider && <small className='p-error'>Email provider is required</small>}
                            <label htmlFor='email_provider'>Email</label>
                        </span>
                    </div>
                </div>

                {/* ADDRES 1 && ADDRESS 2 */}
                <div className='formgrid grid' style={{ marginTop: '10px' }}>
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <InputText id='address1_provider' value={provider.address1_provider} onChange={(e) => onInputChange(e, 'address1_provider')}
                                required className={classNames({ 'p-invalid': submitted && !provider.address1_provider })} />
                            {submitted && !provider.address1_provider && <small className='p-error'>Address 1 is required</small>}
                            <label htmlFor='address1_provider'>Address 1</label>
                        </span>
                    </div>

                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <InputText id='address2_provider' value={provider.address2_provider} onChange={(e) => onInputChange(e, 'address2_provider')}
                                className={classNames({ 'p-invalid': submitted && !provider.address2_provider })} />
                            <label htmlFor='address2_provider'>Address 2</label>
                        </span>
                    </div>
                </div>

                {/* CITY && STATE && COUNTRY && CP */}
                <div className='formgrid grid' style={{ marginTop: '10px' }}>
                    <div className='field col-3 md:col-2'>
                        <span className='p-float-label'>
                            <InputText id='city_provider' value={provider.city_provider} onChange={(e) => onInputChange(e, 'city_provider')}
                                required className={classNames({ 'p-invalid': submitted && !provider.city_provider })} />
                            {submitted && !provider.city_provider && <small className='p-error'>City is required</small>}
                            <label htmlFor='city_provider'>City</label>
                        </span>
                    </div>

                    <div className='field col-3 md:col-2'>
                        <span className='p-float-label'>
                            <InputText id='state_provider' value={provider.state_provider} onChange={(e) => onInputChange(e, 'state_provider')}
                                required className={classNames({ 'p-invalid': submitted && !provider.state_provider })} />
                            {submitted && !provider.state_provider && <small className='p-error'>City is required</small>}
                            <label htmlFor='state_provider'>State</label>
                        </span>
                    </div>

                    <div className='field col-3 md:col-2'>
                        <span className='p-float-label'>
                            <InputText id='country_provider' value={provider.country_provider} onChange={(e) => onInputChange(e, 'country_provider')}
                                required className={classNames({ 'p-invalid': submitted && !provider.country_provider })} />
                            {submitted && !provider.country_provider && <small className='p-error'>Country is required</small>}
                            <label htmlFor='country_provider'>City</label>
                        </span>
                    </div>

                    <div className='field col-3 md:col-2'>
                        <span className='p-float-label'>
                            <InputText id='cp_provider' value={provider.cp_provider} onChange={(e) => onInputChange(e, 'cp_provider')}
                                required className={classNames({ 'p-invalid': submitted && !provider.cp_provider })} />
                            {submitted && !provider.cp_provider && <small className='p-error'>City is required</small>}
                            <label htmlFor='cp_provider'>CP</label>
                        </span>
                    </div>

                </div>

                {/* BANK DATA */}
                <div className='formgrid grid' style={{ marginTop: '10px                                                                                                                        ' }}>
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <InputText id='bank_provider' value={provider.bank_provider} onChange={(e) => onInputChange(e, 'bank_provider')}
                                required className={classNames({ 'p-invalid': submitted && !provider.bank_provider })} />
                            {submitted && !provider.bank_provider && <small className='p-error'>Bank is required</small>}
                            <label htmlFor='bank_provider'>Bank</label>
                        </span>
                    </div>

                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <InputText id='clabe_provider' value={provider.clabe_provider} onChange={(e) => onInputChange(e, 'clabe_provider')}
                                required className={classNames({ 'p-invalid': submitted && !provider.clabe_provider })} />
                            {submitted && !provider.clabe_provider && <small className='p-error'>Bank is required</small>}
                            <label htmlFor='clabe_provider'>CLABE</label>
                        </span>
                    </div>
                </div>

                {/* CATEGORY && SUBCATEGORY */}
                <div className='formgrid grid' style={{ marginTop: '10px' }}>
                    <div className='field col-6 md:col-4'>
                        <span className='p-float-label'>
                            <Dropdown value={category} options={categories} onChange={onCategoryChange} optionLabel='description' />
                            <label htmlFor='fk_id_category'>Select a category...</label>
                        </span>
                    </div>

                    <div className='field col-6 md:col-4'>
                    <span className='p-float-label'>
                            <Dropdown value={subCategory} options={subCategories} onChange={onSubCategoryChange}
                            optionLabel='descriptionSub'  />
                            <label htmlFor='fk_id_subcategory'>Select a sub category...</label>
                        </span>                    </div>
                </div>
            </Dialog>

            <Dialog visible={deleteProviderDialog} style={{ width: '450px' }} header='Confirm' modal footer={deleteProviderDialogFooter}
                onHide={hideDeleteDialog} >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {provider && <span>Are you sure you want to delete <b>{provider.legal_name}</b>?</span>}
                </div>
            </Dialog>
        </div>
    )
}

export default ProvidersTable;