import React, { Component } from 'react'
import classNames from 'classnames';
import axios from 'axios'
import { Redirect } from "react-router-dom";
import ServerURL from '../../../ServerURL'
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';


import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { connect } from "react-redux";
//import { FileUpload } from 'primereact/fileupload';



import { FormattedMessage } from "react-intl";
import { getTextCampusTranslate, getTextPaginatorTranslate, getGeneralTextTranslate } from '../../../_metronic/i18n/TextTranslate'

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';

import * as Validation from '../../../Validation';
import * as auth from "../../modulesAdmin/Auth/_redux/authRedux";




const url = ServerURL();




class UsersTable extends Component {



  emptyUser = {
    email: null,
    password: null,
    first_name: null,
    last_name: null,
    fullname: null,
    phone: null,
    fk_id_role: null,
    fk_id_enterprise: null,
    fk_id_office: null,
    flphoto: null,
    owner: 0,
    learning: 0,
    accounting: 0,
    accounting_office: null,
    fk_id_boss: null,
    billing: 0,
    wiyu: 0,
    expenses: 0

  }

  emptyBoss = {
    fk_id_role: null,
    fk_id_enterprise: null,
    fk_id_office: null,
  }







  constructor(props) {
    super(props)


    this.state = {
      email: null,
      password: null,
      first_name: null,
      last_name: null,
      phone: null,
      fk_id_role: null,
      fk_id_enterprise: null,
      fk_id_office: null,
      imageURL: null,
      flphoto: null,
      owner: 0,
      learning: 0,
      accounting: 0,
      accounting_offie: null,
      billing: 0,
      wiyu: 0,
      fk_id_boss: null,
      redirect: null,

      /**
       * Aux messages
       */
      emailMessage: '',
      passwordMessage: '',
      firstNameMessage: '',
      lastNameMessage: '',
      phoneMessage: '',
      enterpriseMessage: '',
      fkIdRoleMessage: '',
      fkIdOfficeMessage: '',
      ownerMessage: '',
      learningMessage: '',
      accountingMessage: '',
      accounting_officeMessage: '',
      billingMessage: '',
      wiyuMessage: '',





      user: this.emptyUser,
      boss: this.emptyBoss,

      users: null,
      enterprises: null,
      roles: null,
      selectedRole: null,
      selectedEnterprise: null,
      selectedOffice: null,

      selectedRole_boss: null,
      selectedEnterprise_boss: null,
      selectedOffice_boss: null,

      selectedBoss: null,
      CheckedOwner: false,
      CheckedLearning: false,
      CheckedAccounting: false,
      CheckedBilling: false,
      CheckedWiyu: false,
      CheckedExpenses: false,

      resetRole: null,
      resetEnterprise: null,
      resetOffice: null,
      resetBoss: null,
      selectedRoleType: null,
      globalFilter: null,
      selectedUsers: null,
      userDialog: false,
      disableUserDialog: false,
      enableUserDialog: false,
      disableUsersDialog: false,
      enableUsersDialog: false,
      assignUsersDialog: false,
      statusUser: 1,

      submitted: false,
      campusText: getTextCampusTranslate(),
      paginatorText: getTextPaginatorTranslate(),
      textTraslate: getGeneralTextTranslate()
    }

    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.actionUserView = this.actionUserView.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.userView = this.userView.bind(this);
    this.disableUser = this.disableUser.bind(this);
    this.enableUser = this.enableUser.bind(this);
    this.openNew = this.openNew.bind(this);
    this.userBodyTemplate = this.userBodyTemplate.bind(this);
    this.exportCSV = this.exportCSV.bind(this);
    this.editUser = this.editUser.bind(this);
    this.confirmDisableUser = this.confirmDisableUser.bind(this);
    this.confirmEnableUser = this.confirmEnableUser.bind(this);
    this.confirmDisableSelected = this.confirmDisableSelected.bind(this);
    this.confirmEnableSelected = this.confirmEnableSelected.bind(this);
    this.confirmAssignSelected = this.confirmAssignSelected.bind(this);
    this.disableSelectedUsers = this.disableSelectedUsers.bind(this);
    this.enableSelectedUsers = this.enableSelectedUsers.bind(this);
    this.assignSelectedUsers = this.assignSelectedUsers.bind(this);
    this.hideDisableUserDialog = this.hideDisableUserDialog.bind(this);
    this.hideEnableUserDialog = this.hideEnableUserDialog.bind(this);
    this.hideDisableUsersDialog = this.hideDisableUsersDialog.bind(this);
    this.hideEnableUsersDialog = this.hideEnableUsersDialog.bind(this);
    this.hideAssignUsersDialog = this.hideAssignUsersDialog.bind(this);
    this.hideDialog = this.hideDialog.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onRoleChange = this.onRoleChange.bind(this);
    this.onEnterpriseChange = this.onEnterpriseChange.bind(this);
    this.onOfficeChange = this.onOfficeChange.bind(this);
    this.onRoleTypeChange = this.onRoleTypeChange.bind(this);
    this.onOwnerChange = this.onOwnerChange.bind(this);
    this.onLearningChange = this.onLearningChange.bind(this);
    this.onAccountingChange = this.onAccountingChange.bind(this);
    this.onBillingChange = this.onBillingChange.bind(this);
    this.onWiyuChange = this.onWiyuChange.bind(this);
    this.onExpensesChange = this.onExpensesChange.bind(this)
    this.onBossChange = this.onBossChange.bind(this);
    this.onPhoto = this.onPhoto.bind(this);
    this.checkOwnerBodyTemplate = this.checkOwnerBodyTemplate.bind(this);
    this.onBossChange = this.onBossChange.bind(this);
  }



  componentDidMount() {
    this.getUsers()

  }

  getUsers = async () => {

    try {

      let res = axios.get(`${url}/api/auth/users`)
        .then(res => {
          const users = res.data;
          this.setState({ users, statusUser: 1 })
        });
      console.log(res.data)
    } catch (error) {

    }
  }

  getOffices = async () => {
    try {
      let res = axios.get(`${url}/api/auth/offices`)
        .then(res => {
          const offices = res.data;
          this.setState({ offices })
          // console.log("OFFICES: ", offices);
        });
      console.log(res.data)
    } catch (error) {
    }
  }

  getUsersDisabled = async () => {

    try {

      let res = axios.get(`${url}/api/auth/usersDisabled`)
        .then(res => {
          const users = res.data;
          this.setState({ users, statusUser: 0 })
        });
      console.log(res.data)
    } catch (error) {

    }
  }

  getRoleTypes = async () => {

    try {

      let res = axios.get(`${url}/api/auth/role_types`)
        .then(res => {
          const role_types = res.data;
          this.setState({ role_types })
        });
      console.log(res.data)
    } catch (error) {

    }
  }

  getEnterprises(id) {
    try {

      let res = axios.get(`${url}/api/auth/enterprises-byroletype/${id}`)
        .then(res => {
          const enterprises = res.data;
          this.setState({ enterprises })
          console.log(enterprises)

        });
      console.log(res.data)
    } catch (error) {

    }
  }

  getRoles(id) {
    try {

      let res = axios.get(`${url}/api/auth/roles-byroletype/${id}`)
        .then(res => {
          const roles = res.data;
          this.setState({ roles })

        });
      console.log(res.data)
    } catch (error) {

    }
  }


  async getBosses(boss) {
    try {

      this.setState({ boss })
      //console.log("Boss:",boss)


      if (boss.fk_id_role != null && boss.fk_id_enterprise != null) {

        let res = await axios.get(`${url}/api/auth/bosses/${boss.fk_id_role}/${boss.fk_id_enterprise}`)
          .then(res => {
            const bosses = res.data;
            this.setState({ bosses })
          });
        console.log(res.data)
      }
    } catch (error) {

    }
  }


  isValid() {

    const { email, password, first_name, last_name, phone, fk_id_role, fk_id_enterprise } = this.state.user


    const emailMessage = Validation.validateEmail(this.state)
    this.setState({ emailMessage })
    const passwordMessage = Validation.validatePassword(this.state)
    this.setState({ passwordMessage })
    const firstNameMessage = Validation.validateFirstName(this.state)
    this.setState({ firstNameMessage })
    const lastNameMessage = Validation.validateLastName(this.state)
    this.setState({ lastNameMessage })
    const phoneMessage = Validation.validatePhone(this.state)
    this.setState({ phoneMessage })
    const roletypeMessage = Validation.validateRoleType(this.state)
    this.setState({ roletypeMessage })
    const fkIdRoleMessage = Validation.validateRole(this.state)
    this.setState({ fkIdRoleMessage })
    const enterpriseMessage = Validation.validateEnterprise(this.state)
    this.setState({ enterpriseMessage })
    /*const accounting_officeMessage = Validation.validateAccountingOffice(this.state)
    this.setState({ accounting_officeMessage })*/


    if (email && password && first_name && last_name && phone && fk_id_role && fk_id_enterprise) {
      if (!emailMessage && !passwordMessage && !firstNameMessage && !lastNameMessage && !phoneMessage && !fkIdRoleMessage && !enterpriseMessage && !roletypeMessage) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  /* async deleteUser(){
     let data = {...this.state.user};

     try {
     await axios.delete(`${url}/api/auth/user/${data.id}`)
       .then(res => {
       this.getUsers()
     })
     console.log(res)
     //if (!res.error) {
       this.setState({
         data,
         deleteUserDialog: false,
         user: this.emptyUser,

     });
     this.toast.show({ severity: 'success', summary: 'Successful', detail: 'User Deleted', life: 3000 });
     console.log("Delete:",this.state.deleteUserDialog)

     //}
       }catch(error){
       }    
   }    */

  async disableUser() {
    let data = { ...this.state.user };

    try {
      let res = await axios.put(`${url}/api/auth/disable_user/${data.id}`)
        .then(res => {
          this.getUsers()
        })
      console.log(res)
      //if (!res.error) {
      this.setState({
        data,
        disableUserDialog: false,
        user: this.emptyUser,

      });
      this.toast.show({ severity: 'success', summary: 'Successful', detail: 'User Disabled', life: 3000 });

      //}
    } catch (error) {
    }
  }

  async enableUser() {
    let data = { ...this.state.user };

    try {
      let res = await axios.put(`${url}/api/auth/enable_user/${data.id}`)
        .then(res => {
          this.getUsersDisabled()
        })
      console.log(res)
      //if (!res.error) {
      this.setState({
        data,
        enableUserDialog: false,
        user: this.emptyUser,

      });
      this.toast.show({ severity: 'success', summary: 'Successful', detail: 'User Enabled', life: 3000 });

      //}
    } catch (error) {
    }
  }



  async saveUser() {

    let state = { submitted: true };

    const decision = this.isValid();

    // if (this.state.user.first_name.trim()) {       
    if (decision) {
      let data = { ...this.state.user };

      console.log("DATA:", data)
      const formData = new FormData();

      formData.append('first_name', data.first_name);
      formData.append('last_name', data.last_name);
      formData.append('email', data.email);
      formData.append('password', data.password);
      formData.append('phone', data.phone);
      formData.append('fk_id_role', data.fk_id_role);
      formData.append('fk_id_enterprise', data.fk_id_enterprise);
      formData.append('fk_id_office', data.fk_id_office)
      formData.append('owner', data.owner);
      formData.append('learning', data.learning);
      formData.append('accounting', data.accounting);
      formData.append('accounting_office', data.accounting_office);
      formData.append('billing', data.billing);
      formData.append('wiyu', data.wiyu);
      formData.append('expenses', data.expenses)
      formData.append('flphoto', this.uploadInput.files[0]);

      const config = {
        headers: {

          'Content-Type': 'multipart/form-data'
        }
        //,body:JSON.stringify(data)
      }

      console.log(formData, config)

      if (this.state.user.id) {
        try {
          let res = await axios.put(`${url}/api/auth/user/${data.id}`, formData, config)
            .then(res => {

              this.getUsers()
            })
          if (!res.error) {
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'User Updated', life: 3000 });
          }
        } catch (error) {
        }

      } else {
        try {

          let res = await axios.post(`${url}/api/auth/user/`, formData, config)
            .then(res => {
              this.getUsers()
            })
          if (!res.error) {
            this.toast.show({ severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000 });
          }
        } catch (error) {
        }
      }
      state = {
        ...state,
        data,
        userDialog: false,
        user: this.emptyUser,
        enterprises: null,
        roles: null,
        selectedRole: null,
        selectedEnterprise: null,
        selectedRoleType: null,
        selectedOffice: null,
        CheckedOwner: false,
        CheckedLearning: false,
        CheckedAccounting: false,
        CheckedBilling: false,
        CheckedWiyu: false,

        resetRole: null,
        resetEnterprise: null,
        resetOffice: null
      };
    }
    this.setState(state);
  }

  openNew() {
    this.setState({
      user: this.emptyUser,
      selectedRole: null,
      selectedEnterprise: null,
      selectedRoleType: null,
      CheckedOwner: false,
      CheckedLearning: false,
      CheckedAccounting: false,
      CheckedBilling: false,
      CheckedWiyu: false,
      resetRole: "Role",
      resetEnterprise: "Enterprise",
      submitted: false,
      userDialog: true,
    })
    this.getRoleTypes()
    // this.get
  }



  confirmAssignSelected() {
    this.setState({

      selectedRole: null,
      selectedEnterprise: null,
      selectedRoleType: null,
      resetRole: "Role",
      resetEnterprise: "Enterprise",
      submitted: false,
      assignUsersDialog: true,
    })
    this.getRoleTypes()


  }


  onInputChange(e, name) {
    const val = (e.target && e.target.value) || '';
    let user = { ...this.state.user };
    user[`${name}`] = val;

    this.setState({ selectedRole: e.value, selectedEnterprise: e.value, user });
  }

  onRoleChange(e) {

    let user = { ...this.state.user };
    let boss = { ...this.state.boss };

    boss['fk_id_role'] = e.value;
    user['fk_id_role'] = e.value;

    this.setState({ selectedRole: e.value, user });

    this.getBosses(boss)
  }


  onEnterpriseChange(e) {
    let user = { ...this.state.user };
    let boss = { ...this.state.boss };

    boss['fk_id_enterprise'] = e.value;
    user['fk_id_enterprise'] = e.value;

    this.setState({ selectedEnterprise: e.value, user });

    this.getBosses(boss)
  }

  onOfficeChange(e) {
    let user = { ...this.state.user }
    let boss = { ...this.state.boss }

    boss['fk_id_office'] = e.value
    user['fk_id_office'] = e.value

    console.log("VALUE: ", e.value);

    this.setState({ selectedOffice: e.value, user })

    this.getBosses(boss)
  }



  onRoleTypeChange(e) {
    this.setState({
      selectedRoleType: e.value,
      resetRole: "Role",
      resetEnterprise: "Enterprise",
      selectedEnterprise: null,
      selectedRole: null,
    });
    this.getRoles(e.value)
    this.getEnterprises(e.value)
  }

  onBossChange(e) {

    let user = { ...this.state.user };
    user['fk_id_boss'] = e.value;

    this.setState({ selectedBoss: e.value, user });

  }


  onOwnerChange(e) {
    let user = { ...this.state.user }
    console.log("E.CHECKED:", e.checked)
    if (e.checked) {
      user['owner'] = 1
      console.log("YES:", 1)

    } else {
      user['owner'] = 0
      console.log("NO:", 0)

    }
    console.log("Owner:", e.checked, e.value)
    this.setState({ CheckedOwner: e.checked, user });

  }

  onLearningChange(e) {
    let user = { ...this.state.user }
    console.log("E.CHECKED:", e.checked)
    if (e.checked) {
      user['learning'] = 1
      console.log("YES:", 1)

    } else {
      user['learning'] = 0
      console.log("NO:", 0)

    }
    console.log("Learning:", e.checked, user['learning'])
    this.setState({ CheckedLearning: e.checked, user });

  }

  onAccountingChange(e) {
    let user = { ...this.state.user }
    if (e.checked) {
      user['accounting'] = 1
      console.log("YES:", 1)
      this.getOffices()

    } else {
      user['accounting'] = 0
      console.log("NO:", 0)

    }
    console.log("Accounting:", e.checked, e.value)
    this.setState({ CheckedAccounting: e.checked, user });

  }


  onBillingChange(e) {
    let user = { ...this.state.user }
    if (e.checked) {
      user['billing'] = 1
      console.log("BILLING YES:", 1)

    } else {
      user['billing'] = 0
      console.log("BILLING NO:", 0)

    }
    console.log("Billing:", e.checked, e.value)
    this.setState({ CheckedBilling: e.checked, user });

  }

  onWiyuChange(e) {
    let user = { ...this.state.user }
    if (e.checked) {
      user['wiyu'] = 1
      console.log("WIYU YES:", 1)

    } else {
      user['wiyu'] = 0
      console.log("WIYU NO:", 0)

    }
    console.log("WIYU:", e.checked, e.value)
    this.setState({ CheckedWiyu: e.checked, user });

  }

  onExpensesChange(e) {
    let user = { ...this.state.user }
    if (e.checked) {
      user['expenses'] = 1
      console.log("EXPENSES YES:", 1)

    } else {
      user['expenses'] = 0
      console.log("EXPENSES NO:", 0)

    }
    console.log("EXPENSES: ", e.checked, e.value)
    this.setState({ CheckedExpenses: e.checked, user });

  }





  hideDialog() {
    this.setState({
      submitted: false,
      userDialog: false,
      enterprises: null,
      roles: null,
      emailMessage: '',
      passwordMessage: '',
      firstNameMessage: '',
      lastNameMessage: '',
      phoneMessage: '',
      fkIdRoleMessage: '',
      enterpriseMessage: '',
    })
  }


  editUser(user) {
    this.getRoleTypes()
    this.getEnterprises()
    this.getRoles()
    this.getOffices()
    this.setState({
      selectedRole: null,
      selectedEnterprise: null,
      selectedRoleType: null,
      CheckedOwner: false,
      CheckedLearning: false,
      CheckedAccounting: false,
      CheckedBilling: false,
      CheckedWiyu: false,
      resetRole: null,
      resetEnterprise: null,
      user: { ...user },
      userDialog: true
    })

  }

  confirmDisableUser(user) {
    this.setState({
      user,
      disableUserDialog: true
    })
  }

  confirmEnableUser(user) {
    this.setState({
      user,
      enableUserDialog: true
    })
  }

  confirmDisableSelected() {
    this.setState({ disableUsersDialog: true })
  }



  confirmEnableSelected() {
    this.setState({ enableUsersDialog: true })
  }



  disableSelectedUsers() {
    let users = this.state.users.filter(val => !this.state.selectedUsers.includes(val));
    let data = this.state.users.filter(val => this.state.selectedUsers.includes(val));

    console.log("DATA:", data)

    try {

      let res = axios.put(`${url}/api/auth/disable_users`, { data })
        .then(res => {
          this.getUsers()
        })
      if (!res.error) {
        this.setState({
          users,
          disableUsersDialog: false,
          selectedUsers: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Users Disabled', life: 3000 });
      }
    } catch (error) {
    }
  }

  enableSelectedUsers() {
    let users = this.state.users.filter(val => !this.state.selectedUsers.includes(val));
    let data = this.state.users.filter(val => this.state.selectedUsers.includes(val));


    try {

      let res = axios.put(`${url}/api/auth/enable_users`, { data })
        .then(res => {
          this.getUsersDisabled()
        })
      if (!res.error) {
        this.setState({
          users,
          enableUsersDialog: false,
          selectedUsers: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Users Enabled', life: 3000 });
      }
    } catch (error) {
    }
  }

  assignSelectedUsers() {
    let data = this.state.users.filter(val => this.state.selectedUsers.includes(val));

    for (let i = 0; i < data.length; i++) {
      data[i].fk_id_boss = this.state.selectedBoss;
    }

    try {

      let res = axios.put(`${url}/api/auth/assign_users`, { data })
        .then(res => {
          this.getUsers()
        })
      if (!res.error) {
        this.setState({
          assignUsersDialog: false,
          selectedUsers: null
        });
        this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Users Assigned', life: 3000 });
      }
    } catch (error) {
    }
  }




  async userView(user) {
    let id = user.id

    try {

      axios.get(`${url}/api/auth/userAux/${id}`)
        .then(res => {
          const data = res.data
          console.log(res)
          console.log("DATA USER AUX:", data[0])
          const admin = {
            ...this.props.user,
            id_ADMIN: this.props.user.id,
            email_ADMIN: this.props.user.email,
            password_ADMIN: this.props.user.password,
            photo_ADMIN: this.props.user.photo,
            phone_ADMIN: this.props.user.phone,
            first_name_ADMIN: this.props.user.first_name,
            last_name_ADMIN: this.props.user.last_name,
            fullname_ADMIN: this.props.user.fullname,
            fk_id_role_ADMIN: this.props.user.fk_id_role,
            fk_id_enterprie_ADMIN: this.props.user.fk_id_enterprise,
            learning_ADMIN: this.props.user.learning,
            owner_ADMIN: this.props.user.owner,
            accounting_ADMIN: this.props.user.accounting,
            accounting_office_ADMIN: this.props.user.accounting_office,
            fk_id_baseCurrency_ADMIN: this.props.user.fk_id_baseCurrency,
            role_ADMIN: this.props.user.role,
            fk_id_role_type_ADMIN: this.props.user.fk_id_role_type,
            role_type_ADMIN: this.props.user.role_type,
            enterprise_name_ADMIN: this.props.user.enterprise_name,
            boss_ADMIN: this.props.user.boss,
            codeCurrency_ADMIN: this.props.user.codeCurrency,
            view_ADMIN: this.props.user.view,
            tutorials_ADMIN: this.props.user.tutorials,
            billing_ADMIN: this.props.user.billing,
            wiyu_ADMIN: this.props.user.wiyu,
            additionalPercentage_ADMIN: user.additionalPercentage,
            fk_id_currencies_ADMIN: user.fk_id_currencies
          }
          this.props.updatedUser(admin)
          this.props.getAuthToken_ADMIN(this.props.authToken)

          axios.post(`${url}/api/auth/emulation`, data[0])
            .then(res => {
              const accessToken = res.data


              const user = {
                ...this.props.user,

                id: data[0].id,
                email: data[0].email,
                password: data[0].password,
                photo: data[0].photo,
                phone: data[0].phone,
                first_name: data[0].first_name,
                last_name: data[0].last_name,
                fullname: data[0].fullname,
                fk_id_role: data[0].fk_id_role,
                fk_id_enterprise: data[0].fk_id_enterprise,
                learning: data[0].learning,
                owner: data[0].owner,
                accounting: data[0].accounting,
                accounting_office: data[0].accounting_office,
                fk_id_baseCurrency: data[0].fk_id_baseCurrency,
                role: data[0].role,
                fk_id_role_type: data[0].fk_id_role_type,
                role_type: data[0].role_type,
                enterprise_name: data[0].enterprise_name,
                boss: data[0].boss,
                codeCurrency: data[0].codeCurrency,
                view: true,
                tutorials: data[0].tutorials,
                billing: data[0].billing,
                wiyu: data[0].wiyu,
                additionalPercentage: data[0].additionalPercentage,
                fk_id_currencies: data[0].fk_id_currencies


              }
              console.log("NEW TOKEN:", accessToken.data.token)
              this.setState({ redirect: "/" });
              this.props.updatedUser(user)
              this.props.updatedAuthToken(accessToken.data.token)
            })
        })
    } catch (error) { }
  }



  hideDisableUserDialog() {
    this.setState({ disableUserDialog: false })
  }

  hideEnableUserDialog() {
    this.setState({ enableUserDialog: false })
  }

  hideDisableUsersDialog() {
    this.setState({ disableUsersDialog: false });
  }

  hideEnableUsersDialog() {
    this.setState({ enableUsersDialog: false });
  }

  hideAssignUsersDialog() {
    this.setState({ assignUsersDialog: false });
  }

  exportCSV() {
    this.dt.exportCSV();
  }

  onPhoto() {
    this.toast.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded with Auto Mode' });
  }

  userBodyTemplate(rowData) {
    return (
      <>
        <Avatar alt={rowData.first_name} src={`${rowData.photo}`} width={32} style={{ verticalAlign: 'middle', margin: "10px", width: "50px", height: "50px", }} />
      </>
    )
  }




  actionBodyTemplate(rowData) {
    return (
      <>
        <Button icon="pi pi-pencil" color='primary' style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.editUser(rowData)} />
        {this.buttonED(rowData)}
        <Button icon="pi pi-eye" className="p-button-rounded p-button-success p-button-outlined" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.userView(rowData)} />

      </>
    )
  }

  actionUserView(rowData) {
    return (
      <>
        <Button icon="pi pi-eye" className="p-button-rounded p-button-success p-button-outlined" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.userView(rowData)} />
      </>
    )
  }

  buttonED = (rowData) => {


    let EDView = []

    if (this.state.statusUser === 1) {
      EDView.push(
        <Button icon="pi pi-times" className="p-button-danger" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.confirmDisableUser(rowData)} />
      )
    } else {
      EDView.push(
        <Button icon="pi pi-check" className="p-button-danger" style={{ verticalAlign: 'middle', margin: "10px", width: "30px", height: "30px", }} onClick={() => this.confirmEnableUser(rowData)} />
      )
    }

    return EDView
  }

  checkOwnerBodyTemplate(rowData) {
    const boolAux = rowData.owner === 1 ? true : false;
    return <span> <Checkbox id="owner" margin="dense" checked={boolAux} readOnly={true} /></span>;

  }

  checkLearningBodyTemplate(rowData) {
    return <span> <Checkbox id="learning" margin="dense" checked={rowData.learning} readOnly={true} /></span>;
  }

  checkAccountingBodyTemplate(rowData) {
    const boolAux = rowData.accounting === 1 ? true : false;
    return <span> <Checkbox id="accounting" margin="dense" checked={boolAux} readOnly={true} /></span>;
  }

  checkBillingBodyTemplate(rowData) {
    const boolAux = rowData.billing === 1 ? true : false;
    return <span> <Checkbox id="billing" margin="dense" checked={boolAux} readOnly={true} /></span>;
  }

  checkWiyuBodyTemplate(rowData) {
    const boolAux = rowData.wiyu === 1 ? true : false;
    return <span> <Checkbox id="wiyu" checked={boolAux} readOnly={true} /></span>;
  }

  checkExpensesBodyTemplate(rowData) {
    const boolAux = rowData.expense === 1 ? true : false;
    return <span> <Checkbox id="expense" checked={boolAux} readOnly={true} /></span>;
  }


  fieldAccountingOffice() {
    let office = { ...this.state.user }
    if (office['accounting'] === 1) {
      return (<>
        <div className="col-lg-6">
          <span className="p-float-label">
            {/* <InputText
            style={{ width: 190, height:33 }}
            id="accounting_office"
            variant="outlined"
            margin="dense"
            value={this.state.user.accounting_office}             
            onChange={(e) => this.onInputChange(e, 'accounting_office')}
            //required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.accounting  })}
            /> */}
            <Dropdown
              style={{ width: 190, height: 33 }}
              id='office'
              variant='outlined'
              placeholder={this.state.resetOffice || this.state.user.accounting_office}
              margin='dense'
              value={this.state.selectedOffice || 'office'}
              options={this.state.offices}
              onChange={this.onOfficeChange}
              optionLabel='office'
              optionValue='id'
            />
            <label htmlFor="first_name">Accounting Office</label>
          </span>
          {/*} <small id="accounting-help" className={classNames({ 'p-invalid': this.state.submitted })}>{Validation.getTextAuxAccounting(this.state)}</small>*/}
        </div>
      </>)
    }
  }


  enabledNdisabled = () => {


    let EDView = []

    if (this.state.statusUser === 1) {
      EDView.push(
        <FormattedMessage id="OPTION.DISABLED_USER">
          {(message) => <p><Button className="p-button-outlined p-button-secondary" type="button" label={message} onClick={this.getUsersDisabled} variant='contained' /></p>}
        </FormattedMessage>)
    } else {
      EDView.push(
        <FormattedMessage id="OPTION.ENABLED_USER">
          {(message) => <p><Button className="p-button-outlined p-button-secondary" type="button" label={message} onClick={this.getUsers} variant='contained' /></p>}
        </FormattedMessage>)
    }

    return EDView
  }

  toolbarButtonED = () => {


    let EDView = []

    if (this.state.statusUser === 1) {
      EDView.push(
        <FormattedMessage id="GENERAL.DISABLE">
          {(message) => <Button icon="pi pi-times" label={message} className="p-button-danger" onClick={this.confirmDisableSelected} disabled={!this.state.selectedUsers || !this.state.selectedUsers.length} />}
        </FormattedMessage>)
    } else {
      EDView.push(
        <FormattedMessage id="GENERAL.ENABLE">
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-danger" onClick={this.confirmEnableSelected} disabled={!this.state.selectedUsers || !this.state.selectedUsers.length} />}
        </FormattedMessage>)
    }

    return EDView
  }




  render() {

    console.log(this.state.users)

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const disableUserDialogFooter = (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDisableUserDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.disableUser} />
      </>
    );

    const enableUserDialogFooter = (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideEnableUserDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.enableUser} />
      </>
    );

    const userDialogFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label={message} icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label={message} icon="pi pi-check" className="p-button-text" onClick={this.saveUser} />}
        </FormattedMessage>
      </>
    );

    const disableUsersDialogFooter = (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDisableUsersDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.disableSelectedUsers} />
      </>
    );

    const enableUsersDialogFooter = (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideEnableUsersDialog} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.enableSelectedUsers} />
      </>
    );

    const assignUsersDialogFooter = (
      <>
        <FormattedMessage id="GENERAL.CANCEL">
          {(message) => <Button label="Canel" icon="pi pi-times" className="p-button-text" onClick={this.hideAssignUsersDialog} />}
        </FormattedMessage>
        <FormattedMessage id="GENERAL.SAVE">
          {(message) => <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.assignSelectedUsers} />}
        </FormattedMessage>
      </>
    );



    return (
      <div>
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <Toast ref={(el) => this.toast = el} />
          <h3 className="card-label" >
            <FormattedMessage id="ADMIN_USER.TITLE" />
            <span className="d-block text-muted pt-2 font-size-sm"><FormattedMessage id="ADMIN_USER.DESCRIPTION" /></span>
          </h3>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {/*TOOL BAR */}


            <div style={{ marginRight: 10 }}>
              <FormattedMessage id="GENERAL.EXPORT">
                {(message) => <p><Button type="button" icon="pi pi-external-link" label={message} onClick={this.exportCSV}></Button></p>}
              </FormattedMessage>
            </div>

            <div style={{ marginRight: 10 }}>
              {this.toolbarButtonED()}
            </div>

            <div style={{ marginRight: 10 }}>
              <FormattedMessage id="GENERAL.ASSIGN">
                {(message) => <Button label={message} icon="pi pi-user" className="p-button-help" onClick={this.confirmAssignSelected} disabled={!this.state.selectedUsers || !this.state.selectedUsers.length} />}
              </FormattedMessage>
            </div>

            <div style={{ marginRight: 10 }}>
              <FormattedMessage id="OPTION.NEW_USER">
                {(message) => <p><Button type="button" label={message} icon="pi pi-plus" onClick={this.openNew} variant='contained' color='primary' /></p>}
              </FormattedMessage>
            </div>

            <div style={{ marginRight: 10 }}>
              {this.enabledNdisabled()}
            </div>

            <div style={{ marginRight: 10 }}>
              <p> <span className="p-input-icon-left">
                <i className="pi pi-search" /><InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder={this.state.campusText.GrobalSearch} />
              </span></p>
            </div>

          </div>
        </div>


        <div>
          {/*SHOW USER */}

          <div className="card">
            <DataTable
              ef={(el) => this.dt = el}
              value={this.state.users}
              paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate={this.state.paginatorText}
              rows={10}
              rowsPerPageOptions={[10, 20, 50]}
              ref={(el) => { this.dt = el; }}
              globalFilter={this.state.globalFilter}
              emptyMessage="No User found."
              loading={this.state.loading}
              selection={this.state.selectedUsers}
              onSelectionChange={e => this.setState({ selectedUsers: e.value })}
              dataKey="id"
              responsiveLayout='scroll'
            >
              <Column selectionMode="multiple" headerStyle={{ width: '2em' }}></Column>
              <Column field="photo" body={this.userBodyTemplate}></Column>
              <Column field="first_name" header={this.state.campusText.FirstName} sortable></Column>
              <Column field="last_name" header={this.state.campusText.LastName} sortable></Column>
              <Column field="email" header={this.state.campusText.Email} sortable></Column>
              <Column field="role_type" header='Role Type' sortable></Column>
              <Column field="enterprise_name" header='Enterprise' sortable></Column>
              <Column field='accounting_office' header="Office" sortable></Column>
              <Column field="role" header='Role' sortable></Column>
              <Column field="boss" header='Boss' sortable></Column>
              <Column field="owner" header="Owner" sortable body={this.checkOwnerBodyTemplate}></Column>
              <Column field="learning" header="Learning" sortable body={this.checkLearningBodyTemplate}></Column>
              <Column field="accounting" header="Accounting" sortable body={this.checkAccountingBodyTemplate}></Column>
              <Column field="billing" header="Billing" sortable body={this.checkBillingBodyTemplate}></Column>
              <Column field="wiyu" header="WIYU" sortable body={this.checkWiyuBodyTemplate}></Column>
              <Column filed='wiyu' header='Expenses' sortable body={this.checkExpensesBodyTemplate}></Column>
              <Column body={this.actionBodyTemplate}></Column>
              {/*<Column body={this.actionUserView}></Column>*/}

            </DataTable>
          </div>
        </div>


        <div>
          {/*DISABLE USER */}
          <Dialog visible={this.state.disableUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={disableUserDialogFooter} onHide={this.hideDisableUserDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {this.state.user && <span> Are you sure you want to disable <b>{this.state.user.fullname}</b>?</span>}
            </div>
          </Dialog>
        </div>

        <div>
          {/*ENABLE USER */}
          <Dialog visible={this.state.enableUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={enableUserDialogFooter} onHide={this.hideEnableUserDialog}>
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
              {this.state.user && <span> Are you sure you want to enable <b>{this.state.user.fullname}</b>?</span>}
            </div>
          </Dialog>
        </div>

        {/*DISABLE WITH TOOL BAR*/}
        <Dialog visible={this.state.disableUsersDialog} style={{ width: '450px' }} header="Confirm" modal footer={disableUsersDialogFooter} onHide={this.hideDisableUsersDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {this.state.user && <span>Are you sure you want to disable the selected users?</span>}
          </div>
        </Dialog>

        {/*ENABLE WITH TOOL BAR*/}
        <Dialog visible={this.state.enableUsersDialog} style={{ width: '450px' }} header="Confirm" modal footer={enableUsersDialogFooter} onHide={this.hideEnableUsersDialog}>
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {this.state.user && <span>Are you sure you want to enable the selected users?</span>}
          </div>
        </Dialog>

        {/*ASSIGN WITH TOOL BAR*/}
        <Dialog visible={this.state.assignUsersDialog} contentStyle={{ maxHeight: "800px", width: "500px", height: "800px", overflow: "auto" }} header="Assign Users" modal footer={assignUsersDialogFooter} onHide={this.hideAssignUsersDialog}>
          {/* RoleType*/}
          <br></br>
          <div className="form-group row">
            <div className="col-lg-6">
              <span className="p-float-label">
                <Dropdown
                  style={{ width: 190, height: 33 }}
                  id="role_type"
                  variant="outlined"
                  placeholder={this.state.user.role_type || "Role Type"}
                  margin="dense"
                  value={this.state.selectedRoleType || "role_type"}
                  options={this.state.role_types}
                  onChange={this.onRoleTypeChange}
                  optionLabel="role_type"
                  optionValue="id"

                />
                <label htmlFor="role_type">Role Type</label>

              </span>
              {
                this.state.roletypeMessage && (
                  <small id="role_type-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.roletypeMessage} /></small>
                )
              }
            </div>

            {/* Enterprise*/}
            <br></br>
            <div className="col-lg-6">
              <span className="p-float-label">
                <Dropdown
                  style={{ width: 190, height: 33 }}
                  id="enterprise_name"
                  variant="outlined"
                  placeholder={this.state.resetEnterprise || this.state.user.enterprise_name}
                  margin="dense"
                  value={this.state.selectedEnterprise || "enterprise_name"}
                  options={this.state.enterprises}
                  onChange={this.onEnterpriseChange}
                  optionLabel="enterprise_name"
                  optionValue="id"
                />
                <label htmlFor="enterprise_name">Enterprise</label>
              </span>
              {
                this.state.enterpriseMessage && (
                  <small id="enterprice-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.enterpriseMessage} /></small>
                )
              }
            </div>
          </div>
          {/* Role*/}
          <br></br>
          <div className="form-group row">
            <div className="col-lg-6">
              <span className="p-float-label">
                <Dropdown
                  style={{ width: 190, height: 33 }}
                  id="role"
                  variant="outlined"
                  placeholder={this.state.resetRole || this.state.user.role}
                  margin="dense"
                  value={this.state.selectedRole || "role"}
                  options={this.state.roles}
                  onChange={this.onRoleChange}
                  optionLabel="role"
                  optionValue="id"
                />
                <label htmlFor="role">Role</label>
              </span>
              {
                this.state.fkIdRoleMessage && (
                  <small id="role-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.fkIdRoleMessage} /></small>
                )
              }
            </div>
            <div className="col-lg-6">
              <span className="p-float-label">
                <Dropdown
                  style={{ width: 190, height: 33 }}
                  id="boss"
                  variant="outlined"
                  placeholder={this.state.resetBoss || this.state.user.fullname}
                  margin="dense"
                  value={this.state.selectedBoss || "fullname"}
                  options={this.state.bosses}
                  onChange={this.onBossChange}
                  optionLabel="fullname"
                  optionValue="id"
                />
                <label htmlFor="boss">Boss</label>
              </span>
              {
                this.state.bossMessage && (
                  <small id="boss-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.bossMessage} /></small>
                )
              }
            </div>
          </div>
        </Dialog>


        <div>
          {/*UPDATE USER & CREATE USER*/}
          <Dialog visible={this.state.userDialog} contentStyle={{ maxHeight: "800px", width: "550px", height: "800px", overflow: "auto" }} header={<FormattedMessage id="OPTION.NEW_USER" />} modal footer={userDialogFooter} onHide={this.hideDialog}>
            <br></br>

            <div className="form-group row">
              <div className="col-lg-6">

                <Avatar
                  size="small"
                  shape="circle" />
                <input
                  type="file"
                  name="avatar"
                  ref={(ref) => { this.uploadInput = ref; }}
                  multiple={false} />
              </div>
            </div>
            {/* Email */}
            <br></br>
            <div className="form-group row">
              <div className="col-lg-6">
                <span className="p-float-label">
                  <InputText
                    style={{ width: 190, height: 33 }}
                    id="email"
                    variant="outlined"
                    label="Email"
                    margin="dense"
                    value={this.state.user.email}
                    onChange={(e) => this.onInputChange(e, 'email')}
                    keyfilter="email"
                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.email })}
                  />
                  <label htmlFor="email">Email</label>
                </span>
                {
                  this.state.emailMessage && (
                    <small id="email-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.emailMessage} /></small>
                  )
                }
              </div>
              {/* Password */}
              <div className="col-lg-6">
                <span className="p-float-label">
                  <InputText
                    style={{ width: 190, height: 33 }}
                    id="password"
                    variant="outlined"
                    type="password"
                    margin="dense"
                    value={this.state.user.password}
                    onChange={(e) => this.onInputChange(e, 'password')}
                    keyfilter={/[^\s]/}
                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.password })}
                  />
                  <label htmlFor="password">Password</label>
                </span>
                {
                  this.state.passwordMessage && (
                    <small id="password-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.passwordMessage} /></small>
                  )
                }
              </div>
            </div>
            {/* FirstName */}
            <br></br>
            <div className="form-group row">
              <div className="col-lg-6">
                <span className="p-float-label">
                  <InputText
                    style={{ width: 190, height: 33 }}
                    id="first_name"
                    variant="outlined"
                    margin="dense"
                    value={this.state.user.first_name}
                    onChange={(e) => this.onInputChange(e, 'first_name')}
                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.first_name })}
                  />
                  <label htmlFor="first_name">First Name</label>
                </span>
                {
                  this.state.firstNameMessage && (
                    <small id="first_name-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.firstNameMessage} /></small>
                  )
                }
              </div>
              {/* Last Name*/}
              <div className="col-lg-6">
                <span className="p-float-label">
                  <InputText
                    style={{ width: 190, height: 33 }}
                    id="last_name"
                    variant="outlined"
                    margin="dense"
                    value={this.state.user.last_name}
                    onChange={(e) => this.onInputChange(e, 'last_name')}
                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.last_name })}
                  />
                  <label htmlFor="last_name">Last Name</label>
                </span>
                {
                  this.state.lastNameMessage && (
                    <small id="last_name-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.lastNameMessage} /></small>
                  )
                }
              </div>
            </div>
            {/* Phone*/}
            <br></br>
            <div className="form-group row">
              <div className="col-lg-6">
                <span className="p-float-label">
                  <InputText
                    style={{ width: 190, height: 33 }}
                    id="phone"
                    type="phone"
                    variant="outlined"
                    margin="dense"
                    value={this.state.user.phone}
                    onChange={(e) => this.onInputChange(e, 'phone')}
                    keyfilter="pnum"
                    required autoFocus className={classNames({ 'p-invalid': this.state.submitted && !this.state.user.phone })}
                  />
                  <label htmlFor="phone">Phone</label>
                </span>
                {
                  this.state.phoneMessage && (
                    <small id="phone-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.phoneMessage} /></small>
                  )
                }
              </div>
            </div>
            {/* RoleType*/}
            <br></br>
            <div className="form-group row">
              <div className="col-lg-6">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: 190, height: 33 }}
                    id="role_type"
                    variant="outlined"
                    placeholder={this.state.user.role_type || "Role Type"}
                    margin="dense"
                    value={this.state.selectedRoleType || "role_type"}
                    options={this.state.role_types}
                    onChange={this.onRoleTypeChange}
                    optionLabel="role_type"
                    optionValue="id"

                  />
                  <label htmlFor="role_type">Role Type</label>

                </span>
                {
                  this.state.roletypeMessage && (
                    <small id="role_type-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.roletypeMessage} /></small>
                  )
                }
              </div>

              {/* Enterprise*/}
              <br></br>
              <div className="col-lg-6">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: 190, height: 33 }}
                    id="enterprise_name"
                    variant="outlined"
                    placeholder={this.state.resetEnterprise || this.state.user.enterprise_name}
                    margin="dense"
                    value={this.state.selectedEnterprise || "enterprise_name"}
                    options={this.state.enterprises}
                    onChange={this.onEnterpriseChange}
                    optionLabel="enterprise_name"
                    optionValue="id"
                  />
                  <label htmlFor="enterprise_name">Enterprise</label>
                </span>
                {
                  this.state.enterpriseMessage && (
                    <small id="enterprice-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.enterpriseMessage} /></small>
                  )
                }
              </div>
            </div>
            {/* Role*/}
            <br></br>
            <div className="form-group row">
              <div className="col-lg-6">
                <span className="p-float-label">
                  <Dropdown
                    style={{ width: 190, height: 33 }}
                    id="role"
                    variant="outlined"
                    placeholder={this.state.resetRole || this.state.user.role}
                    margin="dense"
                    value={this.state.selectedRole || "role"}
                    options={this.state.roles}
                    onChange={this.onRoleChange}
                    optionLabel="role"
                    optionValue="id"

                  />
                  <label htmlFor="role">Role</label>
                </span>
                {
                  this.state.fkIdRoleMessage && (
                    <small id="role-help" className={classNames({ 'p-invalid': this.state.submitted })}><FormattedMessage id={this.state.fkIdRoleMessage} /></small>
                  )
                }
              </div>
              <div className="col-lg-2">
                <div className="p-float-label" inputid="owner">Owner</div>
              </div>
              <div className="col-lg-1">
                <Checkbox
                  id="owner"
                  margin="dense"
                  value={this.state.CheckedOwner || this.state.user.owner}
                  checked={this.state.user.owner}
                  onChange={this.onOwnerChange} />

              </div>
              {/* Learning*/}
              <div className="col-lg-2">
                <div className="p-float-label" inputid="learning">Learning</div>
              </div>
              <div className="col-lg-1">
                <Checkbox
                  id="learning"
                  margin="dense"
                  value={this.state.CheckedLearning || this.state.user.learning}
                  checked={this.state.user.learning}
                  onChange={this.onLearningChange} />
              </div>
            </div>
            {/* Accounting*/}
            <br></br>
            <div className="form-group row">
              <div className="col-lg-2">
                <div className="p-float-label" inputid="accounting">Accounting</div>
              </div>
              <div className="col-lg-1">
                <Checkbox
                  id="accounting"
                  margin="dense"
                  value={this.state.CheckedAccounting || this.state.user.accounting}
                  checked={this.state.user.accounting}
                  onChange={this.onAccountingChange} />
              </div>

              {/* Accounting Office*/}
              {this.fieldAccountingOffice()}

              <div className="col-lg-2">
                <div className="p-float-label" inputid="billing">Billing</div>
              </div>
              <div className="col-lg-1">
                <Checkbox
                  id="billing"
                  margin="dense"
                  value={this.state.CheckedBilling || this.state.user.billing}
                  checked={this.state.user.billing}
                  onChange={this.onBillingChange} />
              </div>
            </div>

            {/* Wiyu*/}
            <br></br>
            <div className="form-group row">
              <div className="col-lg-2">
                <div className="p-float-label" inputid="wiyu">WIYU</div>
              </div>
              <div className="col-lg-1">
                <Checkbox
                  id="wiyu"
                  margin="dense"
                  value={this.state.CheckedWiyu || this.state.user.wiyu}
                  checked={this.state.user.wiyu}
                  onChange={this.onWiyuChange} />
              </div>
            </div>

            {/* Expenses*/}
            <br></br>
            <div className="form-group row">
              <div className="col-lg-2">
                <div className="p-float-label" inputid="wiyu">Expenses</div>
              </div>
              <div className="col-lg-1">
                <Checkbox
                  id="expenses"
                  margin="dense"
                  value={this.state.CheckedExpenses || this.state.user.expenses}
                  checked={this.state.user.expenses}
                  onChange={this.onExpensesChange} />
              </div>
            </div>
          </Dialog>
        </div>


        <Tooltip title="New Record" aria-label="add" onClick={this.openNew}>
          <Fab color="secondary">
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>

    )
  }
}

/*function mapStateToProps(state) {
  return {
    my_user: state.auth.user
  };
}*/

const mapStateToProps = state => ({
  user: state.auth.user,
  authToken_ADMIN: state.auth.authToken_Admin,
  authToken: state.auth.authToken
});

export default connect(mapStateToProps, auth.actions)(UsersTable);


