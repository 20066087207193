import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import ServerURL from '../../../ServerURL';

import OpenExchangeURL from '../../../OpenExchangeURL';
import OpenExchangeAPI from '../../../OpenExchangeAPI';

import * as auth from '../../modulesAdmin/Auth/_redux/authRedux';

// Primereact components
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber'
import { Checkbox } from 'primereact/checkbox';

// Utileria
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { classNames } from 'primereact/utils';
import './index.css'

const url = ServerURL();

let emptySubCategory = {
    descriptionSub: '',
    active: 0,
    active_text: 'Not active',
    fk_id_category: 0
}

let emptyCategory = {
    id: null,
    description: '',
}

const SubCategoriesTable = () => {
    const [subCategories, setSubCategories] = useState(null);
    const [subCategory, setSubCategory] = useState(emptySubCategory);
    
    const [categories, setCategories] = useState('');
    const [category, setCategory] = useState(emptyCategory)
    
    const [submitted, setSubmitted] = useState(null)
    const [checked, setChecked] = useState(false)

    const [subCategoryDialog, setSubCategoryDialog] = useState(false)
    const [deleteSubCategoryDialog, setDeleteSubCategoryDialog] = useState(false);

    // Peticiones API
    // GET Subcategories

    const getSubCategories = async () => {
        try {
            await axios.get(`${url}/api/auth/expenses/sub_categories`)
                .then(res => {
                    let _subCategories = res.data

                    setSubCategories(_subCategories)
                    // activeSubCategories(_subCategories)


                });
        } catch (error) {
            console.log('ERROR: ', error);
        }
    }

    const getCategories = async () => {
        const res = await axios.get(`${url}/api/auth/expenses/categories`)
        setCategories(res.data)
        console.log('CATEGORIES: ', categories);
    }

    useEffect(async () => {
        getSubCategories();
        getCategories();

    }, []);

        // Formato
        // const activeSubCategories = (e) => {
        //     console.log('E: ', e);
            
        //     let length = Object.keys(e).length
        //     for (let i = 0; i < length; i++) {
        //         if( e[i].active === 1) {
        //             e[i].active_text = 'Active'
        //             setSubCategory(e)
    
        //         } else if (e[i].active === 0 ) {
        //             e[i].active_text = 'Not active'
        //             setSubCategory(e)
    
        //         }
    
        //         return e
        //     }
        // }

    // SAVE && EDIT Subcategory
    const saveSubCategory = async () => {
        setSubmitted(true);

        let _subCategory = { ...subCategory }
        let _category = {...category}

        const formData = new FormData();

        console.log('SUB CATEGORY: ', _subCategory);
        

        formData.append('descriptionSub', subCategory.descriptionSub)
        formData.append('active', subCategory.active)
        formData.append('fk_id_category', subCategory.fk_id_category)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        if (_subCategory.id) {
            console.log('EDITAR REGISTRO');
            
            await axios.put(`${url}/api/auth/expenses/sub_category/${_subCategory.id}`, formData, config)
            .then(res => {
                setSubCategory(emptySubCategory)
                setCategory(emptyCategory)
                setChecked(false)
                setSubmitted(false)
                setSubCategoryDialog(false)

                getSubCategories()
                getCategories()
                
            })
        } else {
            console.log('NUEVO REGISTRO');
            
        await axios.post(`${url}/api/auth/expenses/sub_category`, formData, config)
        .then(res => {
            console.log('RES: ', res);
            setSubCategory(emptySubCategory)
            setCategory(emptyCategory)

            setChecked(false)
            setSubCategoryDialog(false)
            setSubmitted(false)
            getSubCategories()
            getCategories()
        })
        }

        console.log('SAVE SUB CATEGORY: ', _subCategory);
    }

    // DELETE subcategory
    const deleteSubCategory = async () => {
        let _subCategory = { ...subCategory }

        try {
            await axios.delete(`${url}/api/auth/expenses/sub_category/${_subCategory.id}`)
                .then(res => {
                    console.log('RES, ', res.data);
                    getCategories()
                    getSubCategories()
                    setDeleteSubCategoryDialog(false)
                    setSubCategory(emptySubCategory)
                    setCategory(emptyCategory)
                })
        } catch (error) {

        }
    }


    // Funciones pagina dinamica
    const openNew = () => {
        setSubCategory(emptySubCategory)
        setSubmitted(false)
        setSubCategoryDialog(true)

    }

    const editSubCategory = (editSubCategory) => {
        // console.log('SUB CATEGORY: ', editSubCategory);
        setSubCategoryDialog(true)


        // let _subCategory = { ...subCategory }
        // console.log(editSubCategory);
        // console.log('CATEGORY: ', categories);

        if (editSubCategory.active === 1) {
            console.log('entro');
            
            editSubCategory.active_text = 'Active'
            setChecked(true)            
        } else {
            editSubCategory.active_text = 'Not Active'
            setChecked(false)
        }

        let length = Object.keys(categories).length
        for(let i = 0; i < length; i++) {
            if (categories[i].id === editSubCategory.fk_id_category ) {
                console.log('CATEGORIES: ', categories[i]);
                let _categories = categories[i]
                setCategory(_categories)
                onEditCategoryChange(_categories)
                
            }
        }

        setSubCategory(editSubCategory)
        // console.log(subCategory);


    }

    const confirmDeleteSubCategory = (deleteSubCategory) => {
        console.log('Delete sub category: ', deleteSubCategory);
        setSubCategory(deleteSubCategory);
        setDeleteSubCategoryDialog(true)
    }

    const hideDialog = () => {
        setSubCategory(emptySubCategory)
        setCategory(emptyCategory)
        setChecked(false)
        setSubmitted(false)
        setSubCategoryDialog(false)
    }

    const deleteHideDialog = () => {
        setSubmitted(false)
        setSubCategory(emptySubCategory)
        setCategory(emptyCategory)
        setDeleteSubCategoryDialog(false)
    }

    const onInputChange = (e, name) => {
        const val = (e.target.value && e.target.value || '')
        let _subCategory = { ...subCategory }
        _subCategory[`${name}`] = val

        setSubCategory(_subCategory)
    }

    const onChecked = (e) => {
        let _subCategory = { ...subCategory }

        if (e.checked) {
            subCategory.active = 1
            subCategory.active_text = 'Active'
            setChecked(true)
        } else {
            subCategory.active = 0
            subCategory.active_text = 'Not active'
            setChecked(false)
        }

        console.log('CHECKED: ', subCategory);
    }

    const onCategoryChange = (e) => {
        // console.log('E: ', e);

        // console.log('E: ', e.value);
        subCategory.fk_id_category = e.value.id
        setCategory(e.value)
    }

    const onEditCategoryChange = (e) => {
        setCategory(e)
    }

    // Formato
    const formatActive = (value) => {
        if (value === 1) {
            value = 'active'
            return <span className={`sub-categories-badge active-${value}`}>Active</span>
        } else {
            value = 'not-active'
            return <span className={`sub-categories-badge active-${value}`}>Not active</span>
        }
    }



    // Templates
    const activeBodyTemplate = (rowData) => {
        return formatActive(rowData.active)
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label='New' icon='pi pi-plus' className='p-buton-rounded p-button-outlined mr-2' onClick={openNew} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon='pi pi-pencil' className='p-button-rounded p-button-outlined mr-2' onClick={() => editSubCategory(rowData)} />
                <Button icon='pi pi-trash' className='p-button-rounded p-button-danger p-button-outlined mr-2' onClick={() => confirmDeleteSubCategory(rowData)} />
            </React.Fragment>
        )
    }

    // Footers
    const subCategoryDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDialog} />
                <Button label='Save' icon='pi pi-check' className='p-button-text' onClick={saveSubCategory} />
            </React.Fragment>
        )
    }

    const deleteSubCategoryDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={deleteHideDialog} />
                <Button label='Confirm' icon='pi pi-check' className='p-button-text' onClick={deleteSubCategory} />
            </React.Fragment>
        )
    }

    return (
        <div>
            <Toolbar left={leftToolbarTemplate} />
            <DataTable value={subCategories} sortable dataKey='id' paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
                currentPageReportTemplate='Showing {first} to {last} of {totalRecords} sub categories'
                responsiveLayout='scroll'>
                <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} exportable={false} />
                <Column field='descriptionSub' header='Description' sortable style={{ minWidth: '16rem' }} />
                <Column field='active' header='Active' sortable style={{ minWidth: '12rem' }} body={activeBodyTemplate}/>
                <Column field='description' header='Category' sortable style={{ minWidth: '12rem' }} />
                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }} />
            </DataTable>

            <Dialog visible={subCategoryDialog} onHide={hideDialog} style={{ width: '750px' }}
                className='p-fluid' footer={subCategoryDialogFooter}>
                <div className='grid formgrid' style={{ marginTop: '15px' }}>
                    <div className='field col-4 md:col-3'>
                        <span className='p-float-label'>
                            <InputText id='descriptionSub' value={subCategory.descriptionSub} onChange={(e) => onInputChange(e, 'descriptionSub')}
                                required className={classNames({ 'p-invalid': submitted && !subCategory.descriptionSub })} />
                            {submitted && !subCategory.descriptionSub && <small className='p-error'>Description is required</small>}
                            <label>Input</label>
                        </span>
                    </div>

                    <div className='field col-4 md:col-3'>
                        <div className='p-inputgroup'>
                            <span className='p-inputgroup-addon'>
                                <Checkbox checked={checked} onChange={onChecked} value={subCategory.active} />
                            </span>
                            <InputText disabled placeholder={subCategory.active_text} />
                        </div>
                    </div>

                    <div className='field col-4'>
                        <span className='p-float-label'>
                            <Dropdown value={category} options={categories} onChange={onCategoryChange} optionLabel='description' />
                            <label>Select a category...</label>
                        </span>
                    </div>
                </div>
            </Dialog>

            <Dialog visible={deleteSubCategoryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteSubCategoryDialogFooter} onHide={deleteHideDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {subCategory && <span>Are you sure you want to delete <b>{subCategory.descriptionSub}</b>?</span>}
                </div>
            </Dialog>
        </div>
    )
}

export default SubCategoriesTable;