/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";

import {LayoutAdmin} from "../_metronic/layoutAdmin";
import BasePageAdmin from "./BasePageAdmin";

import {LayoutAccounting} from "../_metronic/layoutAccounting";
import BasePageAccounting from "./BasePageAccounting";

import {LayoutAgency} from "../_metronic/layoutAgency";
import BasePageAgency from "./BasePageAgency";

import {LayoutSchool} from "../_metronic/layoutSchool";
import BasePageSchool from "./BasePageSchool";

import {LayoutWiyu} from "../_metronic/layoutWiyu";
import BasePageWiyu from "./BasePageWiyu";

import { LayoutSettings } from "../_metronic/layoutWiyu copy";
import BasePageSettings from "./BasePageSettings";

import { LayoutExpenses } from "../_metronic/layoutExpenses"; 
// import BasePage


import { Logout, AuthPage } from "./modulesAdmin/Auth";
import ErrorsPage from "./modulesAdmin/ErrorsExamples/ErrorsPage";

const renderSwitch = (role_type) => {
    switch(role_type) {
      case 'Admin':
        return (
            <LayoutAdmin>
                <BasePageAdmin/>
            </LayoutAdmin>
        );
      case 'School':
        return (
            <LayoutSchool>
                <BasePageSchool/>
            </LayoutSchool>
        );
      case 'Agency':
        return (
            <LayoutAgency>
                <BasePageAgency/>
            </LayoutAgency>
        );
      case 'Accounting':
        return (
            <LayoutAccounting>
                <BasePageAccounting/>
            </LayoutAccounting>
        );
        case 'Wiyu':
            return (
                <LayoutWiyu>
                    <BasePageWiyu/>
                </LayoutWiyu>
            )
      default:
        return (
            <Layout>
                <BasePage/>
            </Layout>
        );
    }
  };

export function Routes() {
    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    const auth = useSelector(state => state.auth)
    let roleType = "Admin"
    if(isAuthorized){
        try {
            roleType = auth.user.role_type
        } catch (error) {
            console.log("Error en la petición del tipo de rol:",error)
            roleType = ""
        }
    }

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/auth" to="/"/>
            )}

            <Route path="/error" component={ErrorsPage}/>
            <Route path="/logout" component={Logout}/>


            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to="/auth/login"/>
            ) : (
                <>
                {renderSwitch(roleType)}  
                </>
            )}
        </Switch>
    );
}
