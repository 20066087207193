import React from "react";
import { useSubheader } from "../../_metronic/_core";
import CategoriesTable from "../moduleExpenses/Categories/CategoriesTable";

export const CategoriesPage = () => {
    const subheader = useSubheader();
    subheader.setTitle('Categories');

    return (
        <>
            <CategoriesTable />
        </>
    )
}